import React, { useEffect } from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Popover,
  Grid,
  IconButton,
  Box,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { GetApp as DownloadIcon } from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import fileDownload from 'js-file-download';
import ComputerIcon from '@material-ui/icons/Computer';
import AssignmentIcon from '@material-ui/icons/Assignment';
import {
  selectAllOrders,
  selectOrderFilter,
} from 'pages/OrdersPage/ordersSlice';
import { useSelector } from 'react-redux';
import ReactGA from 'react-ga4';
import { selectAllProducts } from '../../pages/shopfront/FetchStoreInfo/productsSlice';
import { type } from '../../constants/order';
import axios from '../../client/client';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(0, 2, 2),
  },
  header: {
    padding: theme.spacing(2, 2, 0),
  },
  textField: {
    width: '300px',
  },
  content: {
    paddingBottom: '0px !important',
    padding: theme.spacing(0, 2, 0),
  },
  actions: {
    alignSelf: 'flex-end',
    textAlign: 'end',
  },
  button: {
    margin: theme.spacing(2),
  },
  cancel: {
    color: 'white',
    backgroundColor: '#ff3c3c',
    '&:hover': {
      backgroundColor: '#dc3c3c',
    },
  },
  popover: {
    height: '200px',
  },

  hover: {},
}));

const OrderList = (props) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(3);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const { tab, customerInfo } = props;

  const orderFilters = useSelector(selectOrderFilter);
  const products = useSelector(selectAllProducts);

  const GAevent = (category, action, label) => {
    ReactGA.event({ category, action, label });
  };

  const orders = useSelector(selectAllOrders)
    .filter((order) => {
      const orderDate = new Date(order.createdAt);
      const fromDate = new Date(orderFilters.from);
      const toDate = new Date(orderFilters.to);

      if (tab === 0) {
        if (orderFilters.type === 2) {
          return true;
        }
        return (
          order.status === orderFilters.status &&
          orderDate >= fromDate &&
          orderDate <= toDate &&
          order.id.toString().includes(orderFilters.orderId) &&
          (order.id.toString().includes(orderFilters.query) ||
            order.customerId.toString().includes(orderFilters.query) ||
            order.shopId.toString().includes(orderFilters.query))
        );
      }
      if (tab === 1) {
        return order.status === 'submitted';
      }
      if (tab === 2) {
        return order.status === 'approved';
      }
      if (tab === 3) {
        return order.status === 'completed';
      }
      if (tab === 4) {
        return order.status === 'rejected';
      }
      if (tab === 5) {
        return order.type === type.subscription;
      }
      return true;
    })
    .map((order) => ({
      ...order,
      products: products.filter((curProduct) =>
        order.productIds.includes(curProduct.id),
      ),
    }));
  const handleClick = (event) => {
    GAevent('engagement', 'get_api_url', 'Get API url');

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const pages = Math.ceil(orders.length / rowsPerPage);

  const handleChange = (event, value) => {
    GAevent(
      'engagement',
      `order_set_tab_${value}`,
      'Change order tab pagination',
    );

    setPage(value);
  };

  const handleDownload = async (orderId) => {
    GAevent('engagement', 'order_download', {
      order: orderId,
    });

    const resourcePath =
      '/data?productId=1384B0D3-0079-41D9-8597-0DCE814074DA&startDate=2019-01-01&endDate=2019-01-15';
    axios.get(axios.defaults.baseURL + resourcePath).then((response) => {
      fileDownload(JSON.stringify(response.data, null, 2), `${orderId}.json`);
    });
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const formattedDate = (epochUnix) => {
    const date = new Date(epochUnix);
    return date.toString().substring(0, 15);
  };

  useEffect(() => {
    setPage(1);
  }, [tab]);

  return (
    <>
      {orders
        .slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)
        .map((order) => (
          <Card key={order.id} className={classes.card}>
            <CardHeader
              className={classes.header}
              title={`Order #${order.id.substring(0, 5)}`}
            />
            <CardContent className={classes.content}>
              <Grid
                container
                direction="row"
                alignItems="stretch"
                justify="flex-start"
              >
                <Grid item xs={4}>
                  <Typography variant="subtitle1">
                    Placed: {formattedDate(order.createdAt)}
                  </Typography>
                  <Typography variant="subtitle1">
                    Last Updated: {formattedDate(order.updatedAt)}
                  </Typography>
                  <Typography variant="subtitle1">
                    Client: {customerInfo?.firstName}
                  </Typography>
                  {order.status === 'rejected' ? (
                    <Typography variant="subtitle1">
                      Rejection Reason: {order.reason}
                    </Typography>
                  ) : null}
                  {/* <Typography variant="subtitle1">
                    {order.type === type.historical
                      ? 'Historical'
                      : 'Subscription'}{' '}
                    Order: {order.start} to {order.end}
                  </Typography> */}
                </Grid>
                <Grid item xs={6}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2">Includes</Typography>
                    </Grid>
                    {order.products?.map((curProduct) => (
                      <Grid item xs={12} key={curProduct.id} component="span">
                        <Typography variant="caption">
                          {curProduct.name}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <Grid className={classes.actions}>
                  <Button
                    className={classes.button}
                    component={Link}
                    to={`/order-detail?orderId=${order.id}`}
                    onClick={GAevent('engagement', 'order_view_details', {
                      order: order.id,
                    })}
                  >
                    View Details
                  </Button>
                  {order.status === 'approved' ? (
                    <>
                      <Button
                        className={classes.button}
                        onClick={async () => handleDownload(order.id)}
                      >
                        <DownloadIcon />
                        Download
                      </Button>
                      <Button className={classes.button} onClick={handleClick}>
                        <ComputerIcon />
                        API URL
                      </Button>
                      <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                        className={classes.popover}
                      >
                        <Box p={3}>
                          <TextField
                            className={classes.textField}
                            disabled
                            value="curl https://5u8m8qp2j8.execute-api.us-east-1.amazonaws.com/dev/data/\?productId\=1384B0D3-0079-41D9-8597-0D\[%E2%80%A6\]074DA\&startDate\=2019-01-01\&endDate\=2019-01-15"
                          ></TextField>
                          <IconButton aria-label="Clipboard">
                            <AssignmentIcon />
                          </IconButton>
                          <Typography variant="body2">
                            Manage your API settings in Delivery Preferences.
                          </Typography>
                        </Box>
                      </Popover>
                    </>
                  ) : null}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ))}
      {orders.length > 0 && (
        <Grid container spacing={2}>
          <Grid item xs={6} md={8}>
            <Pagination
              count={pages}
              page={page}
              onChange={handleChange}
              showFirstButton
              showLastButton
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <FormControl fullWidth>
              <InputLabel>Orders Per Page</InputLabel>
              <Select
                value={rowsPerPage}
                label="Orders Per Page"
                onChange={(e) => {
                  setRowsPerPage(e.target.value);

                  GAevent(
                    'engagement',
                    `filter_orders_by_${rowsPerPage}`,
                    'Change order rows per page',
                  );
                }}
              >
                <MenuItem value={3}>Three</MenuItem>
                <MenuItem value={5}>Five</MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default injectIntl(OrderList);
