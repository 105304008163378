/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import axios from 'client/client';
import { v4 as uuidv4 } from 'uuid';
import { shopfrontBaseUrl } from 'config';

const errorResponse = (state, action) => {
  state.status = 'failed';
  state.error = action.error.message;
};

const initialState = {
  status: 'idle',
  apiKey: null,
  assignedKey: false,
  displayKey: false,
  error: '',
};

export const fetchApiKey = createAsyncThunk(
  'apiKeys/fetchApiKey',
  async ({ entityId }) => {
    const route = `${shopfrontBaseUrl}/keys`;
    const { data, status } = await axios.get(route, {
      params: { entityId },
    });
    return { data, status };
  },
);

export const generateApiKey = createAsyncThunk(
  'apiKeys/generateKey',
  async ({ entityId, entityType }) => {
    const id = uuidv4().toUpperCase();
    const route = `${shopfrontBaseUrl}/keys/generate`;
    const { data } = await axios.post(route, {
      id,
      entityId,
      entityType,
    });
    return data;
  },
);

export const regenerateApiKey = createAsyncThunk(
  'apiKeys/regenerateKey',
  async ({ oldId, entityId, entityType }) => {
    const newId = uuidv4().toUpperCase();
    const route = `${shopfrontBaseUrl}/keys/regenerate`;
    const { data } = await axios.post(route, {
      oldId,
      newId,
      entityId,
      entityType,
    });
    return data;
  },
);

export const hideKey = createAction('apiKeys/hideKey');

const apiKeySlice = createSlice({
  name: 'apiKey',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchApiKey.pending]: (state) => {
      state.status = 'loading';
    },
    [generateApiKey.pending]: (state) => {
      state.status = 'loading';
    },
    [regenerateApiKey.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchApiKey.rejected]: (state, action) => {
      errorResponse(state, action);
    },
    [generateApiKey.rejected]: (state, action) => {
      errorResponse(state, action);
    },
    [regenerateApiKey.rejected]: (state, action) => {
      errorResponse(state, action);
    },
    [fetchApiKey.fulfilled]: (state, action) => {
      if (action.payload.data) {
        state.status = 'succeeded';
        state.assignedKey = true;
        state.apiKey = action.payload.data;
      } else if (action.payload.status !== 400) {
        state.status = 'succeeded';
        state.assignedKey = false;
      } else {
        state.status = 'failed';
        state.assignedKey = false;
        state.error = 'Error retrieving api key';
      }
      state.displayKey = false;
    },
    [generateApiKey.fulfilled]: (state, action) => {
      if (action.payload) {
        state.apiKey = action.payload;
        state.assignedKey = true;
        state.status = 'succeeded';
        state.displayKey = true;
      } else {
        state.status = 'failed';
        state.error = 'Error generating api key';
      }
    },
    [regenerateApiKey.fulfilled]: (state, action) => {
      if (action.payload) {
        state.apiKey = action.payload;
        state.assignedKey = true;
        state.status = 'succeeded';
        state.displayKey = true;
      } else {
        state.status = 'failed';
        state.error = 'Error regenerating api key';
      }
    },
    [hideKey]: (state) => {
      state.displayKey = false;
    },
  },
});

export const selectApiKey = (state) => state.apiKey;

export default apiKeySlice.reducer;
