import { configureStore } from '@reduxjs/toolkit';
import languageReducer from '../features/language/languageSlice';
import attributesReducer from '../pages/shopfront/FetchStoreInfo/attributesSlice';
import analyticsReducer from '../pages/shopfront/FetchStoreInfo/analyticsSlice';
import s3ShareReducer from '../pages/shopfront/FetchStoreInfo/s3ShareSlice';
import productsReducer from '../pages/shopfront/FetchStoreInfo/productsSlice';
import dataSetsReducer from '../pages/shopfront/FetchStoreInfo/dataSetsSlice';
import cartReducer from '../pages/shopfront/CartPage/cartSlice';
import exploreReducer from '../pages/shopfront/ExplorePage/exploreSlice';
import ordersReducer from '../pages/OrdersPage/ordersSlice';
import orderItemsReducer from '../pages/OrdersPage/orderItemsSlice';
import licensesReducer from '../pages/shopfront/FetchStoreInfo/licensesSlice';
import customerReducer from '../pages/shopfront/CustomerSignInPage/customerSignInSlice';
import libraryItemsReducer from '../pages/shopfront/LibraryPage/libraryItemsSlice';
import subscriptionsReducer from '../pages/shopfront/LibraryItemDetailPage/subscriptionsSlice';
import fileDeliveriesReducer from '../pages/shopfront/LibraryItemDetailPage/fileDeliveriesSlice';
import layoutFooterReducer from '../pages/shopfront/FetchStoreInfo/layoutFooterSlice';
import apiKeyReducer from '../pages/shopfront/FetchStoreInfo/apiKeysSlice';
import sftpReducer from '../pages/shopfront/FetchStoreInfo/sftpSlice';
import invoicesReducer from '../pages/shopfront/PaymentsPage/paymentsSlice';
import invoiceReducer from '../pages/InvoicePage/invoiceSlice';
// import awsIdReducer from '../pages/shopfront/ApiKeyManagementPage/awsIdSlice';
import themingReducer from '../pages/shopfront/FetchStoreInfo/themeSlice';

export default configureStore({
  reducer: {
    language: languageReducer,
    attributes: attributesReducer,
    analytics: analyticsReducer,
    products: productsReducer,
    dataSets: dataSetsReducer,
    cart: cartReducer,
    explore: exploreReducer,
    orders: ordersReducer,
    orderItems: orderItemsReducer,
    licenses: licensesReducer,
    customer: customerReducer,
    libraryItems: libraryItemsReducer,
    subscriptions: subscriptionsReducer,
    fileDeliveries: fileDeliveriesReducer,
    layoutFooter: layoutFooterReducer,
    apiKey: apiKeyReducer,
    invoices: invoicesReducer,
    invoice: invoiceReducer,
    sftp: sftpReducer,
    s3Share: s3ShareReducer,
    theming: themingReducer,
  },
});
