/* eslint-disable no-nested-ternary */

import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import messages from 'app/message';
import { makeStyles } from '@material-ui/core/styles';

import clsx from 'clsx';

import {
  Button,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  TextField,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Collapse,
  Grid,
  Tooltip,
} from '@material-ui/core';
import {
  HelpOutline as HelpOutlineIcon,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';

import { useSelector } from 'react-redux';
import { parse } from 'papaparse';
import { SAMPLE_DATA_DISPLAY_COUNT, shopfrontBaseUrl } from 'config';
import Tags from 'features/tags/Tags';
import ReactGA from 'react-ga4';
import { PreviewDataTable } from '../previewDataTable/PreviewDataTable';
import axios from '../../client/client';
import { selectAllLicenses } from '../../pages/shopfront/FetchStoreInfo/licensesSlice';
import { selectAllDataSets } from '../../pages/shopfront/FetchStoreInfo/dataSetsSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(0, 2, 2),
  },
  cardActions: {
    container: 'flex',
    justifyContent: 'flex-end',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  formControl: {
    margin: theme.spacing(0, 1, 0, 0),
    minWidth: 120,
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  sectionTitle: {
    margin: theme.spacing(1, 0),
  },
  license: () => ({
    margin: theme.spacing(1, 0, 1, 0),
  }),
  form: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  textField: {
    margin: theme.spacing(1, 0),
  },
  dateField: {
    margin: theme.spacing(0, 1),
    width: '140px',
  },
  cardHeader: {
    padding: theme.spacing(3, 1, 0, 2),
  },
  licenseLabel: {
    textTransform: 'uppercase',
    fontWeight: 800,
    color: '#888',
    fontSize: '12px',
  },
  licenseCard: {
    padding: theme.spacing(2),
  },
  filterField: {
    margin: theme.spacing(0, 1),
  },
}));

export const Product = injectIntl(
  ({
    cart,
    entity,
    pendingCart,
    orderItems,
    onAddToCart,
    onRemoveFromCart,
    added,
    addDisabled,
    addLicenseToCart,
    removeLicenseFromCart,
    onChangeDelivery,
    updateFilterDraft,
    currency = 'usd',
  }) => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    const unmounted = React.useRef(false);
    const [getSampleURL, setGetSampleURL] = React.useState(false);
    const [sampleData, setSampleData] = React.useState('');
    const [curTextDraft, setCurTextDraft] = React.useState('');
    const [curTextField, setCurTextField] = React.useState({
      id: null,
      name: null,
      attribute: null,
    });
    const [updatedFilters, setUpdatedFilters] = React.useState({});
    const [filterDraft, setFilterDraft] = React.useState(
      orderItems?.reduce((acc, curOrderItem) => {
        const curUpdatedValue = curOrderItem.filters
          ? {
              [curOrderItem.id]: curOrderItem.filters.reduce(
                (innerAcc, curFilter) => {
                  const { name, ...filterItems } = curFilter;
                  return { ...innerAcc, [name]: filterItems };
                },
                {},
              ),
            }
          : null;
        return {
          ...acc,
          ...curUpdatedValue,
        };
      }, {}) || {},
    );
    React.useEffect(
      () => () => {
        unmounted.current = true;
      },
      [],
    );
    React.useEffect(
      () => () => {
        if (unmounted.current) {
          if (updateFilterDraft) updateFilterDraft(filterDraft, updatedFilters);
        }
      },
      [unmounted, filterDraft, updatedFilters, updateFilterDraft],
    );

    const [draft, setDraft] = React.useState(entity);

    const GAevent = (category, action, label) => {
      ReactGA.event({ category, action, label });
    };

    const sortAlphanumeric = (a, b) =>
      a.name.localeCompare(b.name, 'en', { numeric: true });

    const licenses = useSelector(selectAllLicenses)
      .filter((license) => license.productId === entity.id)
      .filter((license) => license.draftStatus !== 'draft')
      .sort(sortAlphanumeric);

    const dataSets = useSelector(selectAllDataSets).filter(
      (curDS) => curDS.productId === entity.id,
    );

    const licenseData = licenses.map((license) => {
      const curOrderItem =
        orderItems?.find((orderItem) => orderItem.licenseId === license.id) ||
        null;
      const selected = !!curOrderItem;
      const curDataSet = selected
        ? dataSets.find((DS) => DS.productId === entity.id)
        : null;
      const columnInfo =
        (selected &&
          curDataSet?.columns?.map((curColName) => ({
            name: curColName,
            type: (curDataSet.types && curDataSet.types[curColName]) || 'str',
            filterable:
              (license.filterable && license.filterable[curColName]) || false,
          }))) ||
        null;
      return { license, curOrderItem, selected, columnInfo };
    });

    const handlePreview = async () => {
      if (!getSampleURL) {
        const resourcePath = `${shopfrontBaseUrl}/products/get-sample-url`;
        const resp = await axios.get(resourcePath, {
          params: {
            fileName: `${entity.id}.csv`, // change to productId
          },
        });
        setGetSampleURL(resp.data);
        if (resp.data) {
          const response = await axios.get(resp.data);
          handleDrop(response.data);
          setSampleData(response.data);
        } else {
          setSampleData(false);
        }
      } else {
        const response = await axios.get(getSampleURL);
        handleDrop(response.data);

        if (!getSampleURL) setSampleData(false);
        else setSampleData(response.data);
      }
    };

    const handleExpandClick = () => {
      if (expanded)
        GAevent(
          'engagement',
          `close_preview_${entity.name}`,
          'Close data preview',
        );
      else
        GAevent(
          'engagement',
          `open_preview_${entity.name}`,
          'Open data preview',
        );

      setExpanded(!expanded);
      handlePreview();
    };

    const manageLicenseInCart = (
      adding,
      licenseId,
      licenseDelivery,
      licensePrice,
    ) => {
      if (licenses.length !== 1) {
        if (adding)
          addLicenseToCart(
            licenseId,
            licenseDelivery.length === 1 ? licenseDelivery : [],
            licensePrice,
          );
        else removeLicenseFromCart(licenseId, false);
      }
    };
    const handleOnTextChange = (e) => {
      setCurTextDraft(e.target.value);
      if (window.onbeforeunload === null) window.onbeforeunload = () => true;
    };
    const LicenseList = () =>
      !cart ? (
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
        >
          {licenseData.map((curLicense, index) => {
            const { license } = curLicense;
            return (
              <Grid key={license.id} item container xs={12}>
                <Grid item xs={3} className={classes.license}>
                  <Typography component="span" variant="body2">
                    {index === 0 && (
                      <div className={classes.licenseLabel}>License Name</div>
                    )}
                    <div className={classes.licensePrice}>{license.name}</div>
                  </Typography>
                </Grid>
                <Grid item xs={3} className={classes.license}>
                  <Typography component="span" variant="body2">
                    {index === 0 && (
                      <div className={classes.licenseLabel}>
                        <FormattedMessage {...messages.LABEL_PRICING} />
                      </div>
                    )}
                    <div className={classes.licensePrice}>
                      {license.price.toString()}{' '}
                      <FormattedMessage
                        {...messages[`CURRENCY_${currency.toUpperCase()}`]}
                      />{' '}
                      (
                      <FormattedMessage
                        {...messages[
                          `BILLING_FREQUENCY_${license.billingFrequency}`
                        ]}
                      />
                      )
                    </div>
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        licenseData.map((curLicense) => {
          const { license, curOrderItem, selected, columnInfo } = curLicense;
          return (
            <Card className={classes.licenseCard} key={license.id}>
              <Grid container alignItems="stretch">
                {cart && (
                  <Grid item xs={1}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selected}
                          disabled={licenses.length === 1 || !pendingCart}
                          onChange={(e) =>
                            manageLicenseInCart(
                              e.target.checked,
                              license.id,
                              license.delivery,
                              license.price,
                            )
                          }
                        />
                      }
                    />
                  </Grid>
                )}
                <Grid container item xs={11}>
                  <Grid item xs={12}>
                    <Typography
                      component="span"
                      variant={cart ? 'h6' : 'subtitle2'}
                    >
                      {`${license.name} - `}
                      {license.price.toString()}{' '}
                      <FormattedMessage
                        {...messages[`CURRENCY_${currency.toUpperCase()}`]}
                      />{' '}
                      (
                      <FormattedMessage
                        {...messages[
                          `BILLING_FREQUENCY_${license.billingFrequency}`
                        ]}
                      />
                      )
                    </Typography>
                  </Grid>
                  {selected && (
                    <>
                      {columnInfo !== null &&
                        columnInfo.findIndex((col) => col.filterable) !==
                          -1 && (
                          <>
                            <Grid item container xs={12} alignItems="center">
                              <Typography className={classes.sectionTitle}>
                                Parameters{' '}
                              </Typography>
                              <Tooltip
                                title={`Filterable parameters.  If you would like a parameter to remain unfiltered, leave the box empty.  If you wish to filter on multiple values for a given parameter, include all strings in the textbox seperated by a comma and no spaces (i.e. "a,b,c", excluding the quotes).  For non-string types, a range may be specified with starting and ending values.  If no values is provided for either of these ends, the specified range will be unbounded on that end.`}
                                placement="right"
                              >
                                <HelpOutlineIcon />
                              </Tooltip>
                            </Grid>
                            <Grid item container xs={12}>
                              {columnInfo.map(
                                (curCol) =>
                                  curCol.filterable && (
                                    <Grid key={curCol.name} item>
                                      {curCol.type === 'str' && (
                                        <TextField
                                          disabled={!pendingCart}
                                          className={classes.filterField}
                                          label={curCol.name}
                                          value={
                                            curTextField.id ===
                                              curOrderItem.id &&
                                            curTextField.name === curCol.name &&
                                            curTextField.attribute === 'string'
                                              ? curTextDraft
                                              : filterDraft[curOrderItem.id]?.[
                                                  curCol.name
                                                ]?.string || ''
                                          }
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          onFocus={() => {
                                            setCurTextField({
                                              id: curOrderItem.id,
                                              name: curCol.name,
                                              attribute: 'string',
                                            });
                                            setCurTextDraft(
                                              filterDraft[curOrderItem.id]?.[
                                                curCol.name
                                              ]?.string || '',
                                            );
                                          }}
                                          onBlur={() => {
                                            handleFilterChange(
                                              curOrderItem.id,
                                              curCol.name,
                                              curCol.type,
                                              'string',
                                            );
                                          }}
                                          onChange={handleOnTextChange}
                                        />
                                      )}
                                      {curCol.type === 'timestamp' && (
                                        <>
                                          <TextField
                                            disabled={!pendingCart}
                                            className={classes.filterField}
                                            label={`${curCol.name} - start date`}
                                            type="date"
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            value={
                                              curTextField.id ===
                                                curOrderItem.id &&
                                              curTextField.name ===
                                                curCol.name &&
                                              curTextField.attribute === 'from'
                                                ? curTextDraft
                                                : filterDraft[
                                                    curOrderItem.id
                                                  ]?.[curCol.name]?.from || ''
                                            }
                                            onFocus={() => {
                                              setCurTextField({
                                                id: curOrderItem.id,
                                                name: curCol.name,
                                                attribute: 'from',
                                              });
                                              setCurTextDraft(
                                                filterDraft[curOrderItem.id]?.[
                                                  curCol.name
                                                ]?.from || '',
                                              );
                                            }}
                                            onBlur={() => {
                                              handleFilterChange(
                                                curOrderItem.id,
                                                curCol.name,
                                                curCol.type,
                                                'from',
                                              );
                                            }}
                                            onChange={handleOnTextChange}
                                          />
                                          <TextField
                                            disabled={!pendingCart}
                                            className={classes.filterField}
                                            label={`${curCol.name} - end date`}
                                            type="date"
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            value={
                                              curTextField.id ===
                                                curOrderItem.id &&
                                              curTextField.name ===
                                                curCol.name &&
                                              curTextField.attribute === 'to'
                                                ? curTextDraft
                                                : filterDraft[
                                                    curOrderItem.id
                                                  ]?.[curCol.name]?.to || ''
                                            }
                                            onFocus={() => {
                                              setCurTextField({
                                                id: curOrderItem.id,
                                                name: curCol.name,
                                                attribute: 'to',
                                              });
                                              setCurTextDraft(
                                                filterDraft[curOrderItem.id]?.[
                                                  curCol.name
                                                ]?.to || '',
                                              );
                                            }}
                                            onBlur={() => {
                                              handleFilterChange(
                                                curOrderItem.id,
                                                curCol.name,
                                                curCol.type,
                                                'to',
                                              );
                                            }}
                                            onChange={handleOnTextChange}
                                          />
                                        </>
                                      )}
                                      {curCol.type === 'float64' && (
                                        <>
                                          <TextField
                                            disabled={!pendingCart}
                                            className={classes.filterField}
                                            label={`${curCol.name} - min value`}
                                            value={
                                              curTextField.id ===
                                                curOrderItem.id &&
                                              curTextField.name ===
                                                curCol.name &&
                                              curTextField.attribute === 'from'
                                                ? curTextDraft
                                                : filterDraft[
                                                    curOrderItem.id
                                                  ]?.[curCol.name]?.from || ''
                                            }
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            onFocus={() => {
                                              setCurTextField({
                                                id: curOrderItem.id,
                                                name: curCol.name,
                                                attribute: 'from',
                                              });
                                              setCurTextDraft(
                                                filterDraft[curOrderItem.id]?.[
                                                  curCol.name
                                                ]?.from || '',
                                              );
                                            }}
                                            onBlur={() => {
                                              handleFilterChange(
                                                curOrderItem.id,
                                                curCol.name,
                                                curCol.type,
                                                'from',
                                              );
                                            }}
                                            onChange={handleOnTextChange}
                                          />
                                          <TextField
                                            disabled={!pendingCart}
                                            className={classes.filterField}
                                            label={`${curCol.name} - max value`}
                                            value={
                                              curTextField.id ===
                                                curOrderItem.id &&
                                              curTextField.name ===
                                                curCol.name &&
                                              curTextField.attribute === 'to'
                                                ? curTextDraft
                                                : filterDraft[
                                                    curOrderItem.id
                                                  ]?.[curCol.name]?.to || ''
                                            }
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            onFocus={() => {
                                              setCurTextField({
                                                id: curOrderItem.id,
                                                name: curCol.name,
                                                attribute: 'to',
                                              });
                                              setCurTextDraft(
                                                filterDraft[curOrderItem.id]?.[
                                                  curCol.name
                                                ]?.to || '',
                                              );
                                            }}
                                            onBlur={() => {
                                              handleFilterChange(
                                                curOrderItem.id,
                                                curCol.name,
                                                curCol.type,
                                                'to',
                                              );
                                            }}
                                            onChange={handleOnTextChange}
                                          />
                                        </>
                                      )}
                                      {curCol.type === 'int64' && (
                                        <>
                                          <TextField
                                            disabled={!pendingCart}
                                            className={classes.filterField}
                                            label={`${curCol.name} - min range`}
                                            value={
                                              curTextField.id ===
                                                curOrderItem.id &&
                                              curTextField.name ===
                                                curCol.name &&
                                              curTextField.attribute === 'from'
                                                ? curTextDraft
                                                : filterDraft[
                                                    curOrderItem.id
                                                  ]?.[curCol.name]?.from || ''
                                            }
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            onFocus={() => {
                                              setCurTextField({
                                                id: curOrderItem.id,
                                                name: curCol.name,
                                                attribute: 'from',
                                              });
                                              setCurTextDraft(
                                                filterDraft[curOrderItem.id]?.[
                                                  curCol.name
                                                ]?.from || '',
                                              );
                                            }}
                                            onBlur={() => {
                                              handleFilterChange(
                                                curOrderItem.id,
                                                curCol.name,
                                                curCol.type,
                                                'from',
                                              );
                                            }}
                                            onChange={handleOnTextChange}
                                          />
                                          <TextField
                                            disabled={!pendingCart}
                                            className={classes.filterField}
                                            label={`${curCol.name} - max range`}
                                            value={
                                              curTextField.id ===
                                                curOrderItem.id &&
                                              curTextField.name ===
                                                curCol.name &&
                                              curTextField.attribute === 'to'
                                                ? curTextDraft
                                                : filterDraft[
                                                    curOrderItem.id
                                                  ]?.[curCol.name]?.to || ''
                                            }
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            onFocus={() => {
                                              setCurTextField({
                                                id: curOrderItem.id,
                                                name: curCol.name,
                                                attribute: 'to',
                                              });
                                              setCurTextDraft(
                                                filterDraft[curOrderItem.id]?.[
                                                  curCol.name
                                                ]?.to || '',
                                              );
                                            }}
                                            onBlur={() => {
                                              handleFilterChange(
                                                curOrderItem.id,
                                                curCol.name,
                                                curCol.type,
                                                'to',
                                              );
                                            }}
                                            onChange={handleOnTextChange}
                                          />
                                        </>
                                      )}
                                    </Grid>
                                  ),
                              )}
                            </Grid>
                          </>
                        )}
                      <Grid item xs={12}>
                        <Typography className={classes.sectionTitle}>
                          Delivery:
                        </Typography>
                        {license.delivery.includes('api') && (
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled={
                                  license.delivery.length === 1 || !pendingCart
                                }
                                checked={curOrderItem.delivery.includes('api')}
                                onChange={() =>
                                  onChangeDelivery('api', curOrderItem)
                                }
                              />
                            }
                            label="API"
                          />
                        )}
                        {license.delivery.includes('aws') && (
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled={
                                  license.delivery.length === 1 || !pendingCart
                                }
                                checked={curOrderItem.delivery.includes('aws')}
                                onChange={() =>
                                  onChangeDelivery('aws', curOrderItem)
                                }
                              />
                            }
                            label="Cloud Transfer to AWS S3"
                          />
                        )}
                        {license.delivery.includes('download') && (
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled={
                                  license.delivery.length === 1 || !pendingCart
                                }
                                checked={curOrderItem.delivery.includes(
                                  'download',
                                )}
                                onChange={() =>
                                  onChangeDelivery('download', curOrderItem)
                                }
                              />
                            }
                            label="Download Link"
                          />
                        )}
                        {license.delivery.includes('SFTP-buyer-hosted') && (
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled={
                                  license.delivery.length === 1 || !pendingCart
                                }
                                checked={curOrderItem.delivery.includes(
                                  'SFTP-buyer-hosted',
                                )}
                                onChange={() =>
                                  onChangeDelivery(
                                    'SFTP-buyer-hosted',
                                    curOrderItem,
                                  )
                                }
                              />
                            }
                            label="SFTP (Buyer hosted server)"
                          />
                        )}
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Card>
          );
        })
      );

    const formatInputByType = (filterType, text) => {
      switch (filterType) {
        case 'str':
          return text;
        case 'int64':
          return text === '' ? '' : parseInt(text, 10);
        case 'float64':
          return text === '' ? '' : parseFloat(text);
        case 'timestamp':
          return text; // will be stored as a string, and converted when it needs to be used
        default:
          return text;
      }
    };

    const handleFilterChange = (
      orderItemId,
      filterName,
      filterType,
      attribute,
    ) => {
      setFilterDraft({
        ...filterDraft,
        [orderItemId]: {
          ...filterDraft[orderItemId],
          [filterName]: {
            ...filterDraft[orderItemId]?.[filterName],
            [attribute]: formatInputByType(filterType, curTextDraft),
          },
        },
      });
      setUpdatedFilters({
        ...updatedFilters,
        [orderItemId]: { ...updatedFilters[orderItemId], [filterName]: true },
      });
    };

    const handleDrop = (sampleDataSet) => {
      const result = parse(sampleDataSet, { header: true });
      const dataColumns = Object.keys(result.data[0]).map((column) => ({
        field: column,
        flex: 1,
      }));
      const dataRows = result.data
        .splice(0, SAMPLE_DATA_DISPLAY_COUNT)
        .map((row, index) => ({ ...row, id: index + 1 }));
      setDraft({
        ...draft,
        columns: dataColumns,
        rows: dataRows,
      });
    };

    const handleRemoveFromCart = () => {
      licenses.forEach((license) => {
        removeLicenseFromCart(license.id, true);
      });
      onRemoveFromCart();
    };

    const handleAddToCart = async () => {
      GAevent('engagement', `add_to_cart`, {
        currency: 'CAD',
        items: licenses.map((product) => ({
          item_id: product.id,
          item_name: product.name,
        })),
      });
      const cartOrderId = await onAddToCart();
      if (licenses.length === 1)
        addLicenseToCart(
          licenses[0].id,
          licenses[0].delivery.length === 1 ? licenses[0].delivery : [],
          licenses[0].price,
          cartOrderId,
        );
    };

    return (
      <Card className={classes.root}>
        <CardHeader
          className={classes.cardHeader}
          title={entity.name}
          subheader={entity.tagline}
          test-attribute="product-name"
        />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {entity.description}
          </Typography>
          <Box pt={2.5}>
            <Tags
              className={classes.tagClass}
              editing={false}
              tagData={draft.tags}
            />
          </Box>
          <Typography className={classes.sectionTitle} variant="h6">
            {' '}
            Licensing{' '}
          </Typography>
          {LicenseList()}
        </CardContent>
        <CardActions disableSpacing className={classes.cardActions}>
          {cart && pendingCart ? (
            <Button className={classes.expand} onClick={handleRemoveFromCart}>
              Remove
            </Button>
          ) : null}
          <Button
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            Preview
            <ExpandMoreIcon
              className={clsx('', {
                [classes.expandOpen]: expanded,
              })}
            />
          </Button>
          {!cart ? (
            <Button
              className={classes.shopPrimary}
              disabled={added || addDisabled}
              onClick={handleAddToCart}
              variant="contained"
              color="secondary"
            >
              {added ? 'Added' : 'Add to Cart'}
            </Button>
          ) : null}
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            {draft.columns && draft.rows && sampleData ? (
              <PreviewDataTable
                columns={draft.columns}
                rows={draft.rows}
                // editing={editing}
                onDropEvent={handleDrop}
              />
            ) : !draft.columns.length &&
              !draft.rows.length &&
              !sampleData &&
              !getSampleURL ? (
              <Typography> No Preview Available.</Typography>
            ) : (
              <Typography> Fetching data preview... </Typography>
            )}
          </CardContent>
        </Collapse>
      </Card>
    );
  },
);
