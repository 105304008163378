import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBarUI from '@material-ui/core/AppBar';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
// import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
// import SearchIcon from '@material-ui/icons/Search';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import MoreIcon from '@material-ui/icons/MoreVert';
import {
  Assignment as AssignmentIcon,
  LibraryBooks as LibraryBooksIcon,
  Storage as StorageIcon,
  MeetingRoom as MeetingRoomIcon,
} from '@material-ui/icons';
import { Link, useHistory } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { shopfrontBaseUrl } from 'config';
import {
  fetchCustomer,
  selectCustomer,
  signOutCusomter,
} from 'pages/shopfront/CustomerSignInPage/customerSignInSlice';
import { useAuth as useCustomerAuth } from 'auth/customer/customerAuth';
import { selectAllOrders } from 'pages/OrdersPage/ordersSlice';
import ReactGA from 'react-ga4';
import { DarkThemeToggle } from './DarkThemeToggle';
import { selectAllProducts } from '../../pages/shopfront/FetchStoreInfo/productsSlice';
import {
  selectCart,
  populateCart,
} from '../../pages/shopfront/CartPage/cartSlice';

import axios from '../../client/client';

const useStyles = (drawerWidth) =>
  makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    menuButton: {
      marginRight: theme.spacing(2),
      width: '48px',
      transition: theme.transitions.create(['marginLeft', 'opacity'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    hide: {
      opacity: 0,
      marginLeft: '-48px',
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      // ...{ backgroundColor: '#036' },
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    grow: {
      flexGrow: 1,
    },
    title: {
      display: 'none',
      padding: theme.spacing(0, 2),
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
      color: theme.palette.fontColor,
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    logo: {
      height: '38px',
    },
    avatar: {
      color: theme.palette.primary.main,
      backgroundColor: '#FFFFFF',
    },
    vCenter: {
      display: 'flex',
      alignItems: 'center',
    },
  }));

export const AppBar = injectIntl(
  ({ onMenuClick, open, drawerWidth, signedOut }) => {
    const customerAuth = useCustomerAuth();
    const classes = useStyles(drawerWidth)();
    const cart = useSelector(selectCart);
    const shopName = useSelector((state) => state.theming.name);
    const user = useSelector(selectCustomer);
    const logo = useSelector((state) => state.theming.appbarImageURL);
    const customer = useSelector(selectCustomer);
    const orders = useSelector(selectAllOrders);
    const cartStatus = useSelector((state) => state.cart.status);
    const productsStatus = useSelector((state) => state.products.status);
    const orderStatus = useSelector((state) => state.orders.status);
    const allProducts = useSelector(selectAllProducts);

    let homeLink = '/welcome';
    if (!signedOut) {
      homeLink = '/explore';
    }

    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
    const history = useHistory();

    const dispatch = useDispatch();

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const GAevent = (category, action, label) => {
      ReactGA.event({ category, action, label });
    };

    const handleProfileMenuOpen = (event) => {
      GAevent('engagement', 'open_profile_menu', 'Open profile menu');

      setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
      GAevent('engagement', 'open_mobile_menu', 'Open mobile menu');

      setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
      GAevent('engagement', 'close_profile_menu', 'Close profile menu');

      setAnchorEl(null);
      handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
      GAevent('engagement', 'close_mobile_menu', 'Close mobile menu');

      setMobileMoreAnchorEl(event.currentTarget);
    };

    const handleSignInSignOut = () => {
      if (!user) {
        history.push('/welcome');
      } else {
        axios.get(`${shopfrontBaseUrl}/users/sign-out`).then((response) => {
          if (response.data.successSignOut) {
            GAevent('authentication', 'signout', 'User signout');

            dispatch(signOutCusomter());
            customerAuth.signOut();
            history.push('/users/sign-in');
          }
        });
      }
    };

    React.useEffect(() => {
      if (!user) {
        dispatch(fetchCustomer());
      }
    }, [user, dispatch]);

    React.useEffect(() => {
      if (!customer) return;
      const cartOrder = orders.find((order) => order.id === customer.cart);
      if (
        cartStatus === 'idle' &&
        productsStatus === 'succeeded' &&
        orderStatus === 'succeeded' &&
        customer &&
        cartOrder
      ) {
        dispatch(
          populateCart({
            customer,
            products: allProducts,
            order: cartOrder,
          }),
        );
      }
    }, [
      cartStatus,
      productsStatus,
      orderStatus,
      customer,
      orders,
      allProducts,
      dispatch,
    ]);

    const handleViewCart = () => {
      GAevent('engagement', 'view_cart', {
        currency: 'CAD',
        items: cart.map((product) => ({
          item_id: product.id,
          item_name: product.name,
        })),
      });

      history.push('/cart');
    };
    const getMenuItems = () => {
      if (user) {
        return [
          <MenuItem component={Link} to="/order-history" key="orders">
            My Orders
          </MenuItem>,
          <MenuItem component={Link} to="/library" key="library">
            My Library
          </MenuItem>,
          <MenuItem
            component={Link}
            to="/subscription-management"
            key="subscription"
          >
            My Subscriptions
          </MenuItem>,
          <MenuItem component={Link} to="/api-keys" key="api-keys">
            Delivery Options
          </MenuItem>,
          <MenuItem key="sign-out" onClick={handleSignInSignOut}>
            Sign Out
          </MenuItem>,
        ];
      }
      // Other options
      return null;
    };

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        {getMenuItems()}
      </Menu>
    );
    const getMobileMenuItems = () => {
      // Signed out user
      if (signedOut)
        return [
          <MenuItem key="sign-in" onClick={handleSignInSignOut}>
            <IconButton aria-label="sign in" color="inherit">
              <MeetingRoomIcon />
            </IconButton>
            <p>Sign In</p>
          </MenuItem>,
        ];
      // Signed in data buyer
      if (user) {
        return [
          <MenuItem key="shopping-cart" onClick={() => handleViewCart()}>
            <IconButton aria-label="shopping cart" color="inherit">
              <Badge badgeContent={cart.length} color="secondary">
                <ShoppingCartIcon />
              </Badge>
            </IconButton>
            <p>My Cart</p>
          </MenuItem>,
          <MenuItem key="orders" onClick={() => history.push('/order-history')}>
            <IconButton aria-label="my orders" color="inherit">
              <AssignmentIcon />
            </IconButton>
            <p>My Orders</p>
          </MenuItem>,
          <MenuItem key="library" onClick={() => history.push('/library')}>
            <IconButton aria-label="my library" color="inherit">
              <LibraryBooksIcon />
            </IconButton>
            <p>My Library</p>
          </MenuItem>,
          <MenuItem key="api-keys" onClick={() => history.push('/api-keys')}>
            <IconButton aria-label="delivery options" color="inherit">
              <StorageIcon />
            </IconButton>
            <p>Delivery Options</p>
          </MenuItem>,
          <MenuItem key="sign-out" onClick={handleSignInSignOut}>
            <IconButton aria-label="sign out" color="inherit">
              <MeetingRoomIcon />
            </IconButton>
            <p>Sign Out</p>
          </MenuItem>,
        ];
      }
      // Other options
      return null;
    };
    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        {getMobileMenuItems()}
      </Menu>
    );

    return (
      <div className={classes.root}>
        <AppBarUI
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
          data-testid="AppBar"
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={onMenuClick}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            {/* {false ? (
            <img
              className={classes.logo}
              src={logo}
              alt="JamLabs Data Shop logo"
            />
          ) : null} */}
            <Button component={Link} to={homeLink}>
              {logo ? (
              <img
                data-testid="IndexLogoBtn"
                className={classes.logo}
                src={logo || null}
                alt="logo"
              />)
:null}
            </Button>
            <Typography
              data-testid="AppBarShopName"
              className={classes.title}
              variant="h4"
              noWrap
            >
              {shopName || null}
            </Typography>
            <div className={classes.grow} />
            {/* <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
              />
            </div> */}
            <div className={classes.sectionDesktop}>
              <div className={classes.vCenter}>
                <DarkThemeToggle />
              </div>
              {user ? (
                <IconButton
                  aria-label="shopping cart"
                  test-attribute="shopping-cart-button"
                  color="inherit"
                  component={Link}
                  to="/cart"
                >
                  <Badge
                    style={{ padding: '0 7px' }}
                    badgeContent={cart.length}
                    color="secondary"
                  >
                    <ShoppingCartIcon />
                  </Badge>
                </IconButton>
              ) : null}
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <Avatar className={classes.avatar}>
                  {user &&
                    user.firstName[0].toUpperCase() +
                      user.lastName[0].toUpperCase()}
                </Avatar>
              </IconButton>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBarUI>
        {renderMobileMenu}
        {renderMenu}
      </div>
    );
  },
);
