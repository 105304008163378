/* eslint-disable default-case, no-param-reassign */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  deleteOrderItem,
  createOrderItem,
  updateOrderItem,
} from 'pages/OrdersPage/orderItemsSlice';
import { v4 as uuidv4 } from 'uuid';
import { shopfrontBaseUrl } from 'config';
import axios from '../../../client/client';

const errorResponse = (state, action) => {
  state.status = 'failed';
  state.error = action.error.message;
};

const initialState = {
  products: [],
  status: 'idle',
  orderItemsStatus: 'idle',
  cartOrderItems: [],
  updateBackendStatus: 'idle',
};

export const addProductToCart = createAsyncThunk(
  'cart/addProductToCart',
  async ({ product, cartOrderId }) => {
    await axios.post(`${shopfrontBaseUrl}/orders/add-product`, {
      id: cartOrderId,
      productId: product.id,
    });
    return product;
  },
);

export const removeProductFromCart = createAsyncThunk(
  'cart/removeProductFromCart',
  async ({ cartOrderId, productId }) => {
    await axios.post(`${shopfrontBaseUrl}/orders/remove-product`, {
      id: cartOrderId,
      productId,
    });
    return productId;
  },
);

const orderDraft = {
  id: '',
  customerId: '',
  shopId: '',
  total: '',
  status: 'cart',
  startDate: '',
  endDate: '',
};

export const makeNewCart = createAsyncThunk(
  'cart/makeNewCart',
  async (customer) => {
    // make new cart
    const order = { ...orderDraft };
    order.id = uuidv4().toUpperCase();
    order.customerId = customer.id;
    order.shopId = customer.shopId;
    order.paidStatus = 'unpaid';
    await Promise.all([
      axios.post(`${shopfrontBaseUrl}/users/cart`, {
        id: customer.id,
        cart: order.id,
      }),
      axios.post(`${shopfrontBaseUrl}/orders`, order),
    ]);
    return order.id;
  },
);

export const populateCart = createAsyncThunk(
  'cart/populateCart',
  async ({ customer, order, products }) => {
    if (customer.cart) {
      const addedProducts = products.filter((p) =>
        order.productIds.includes(p.id),
      );
      return addedProducts;
    }
    return [];
  },
);

export const fetchCartOrderItems = createAsyncThunk(
  'cart/fetchCartOrderItems',
  async ({ cartId }, thunkAPI) => {
    const { orderItems } = thunkAPI.getState();
    if (orderItems.status === 'succeeded') {
      const cartOrderItems = Object.values(orderItems.entities).filter(
        (curOrderItem) => curOrderItem.orderId === cartId,
      );
      return cartOrderItems;
    }
    return [];
  },
);

export const syncDraftToBackend = createAsyncThunk(
  'cart/syncDraftToBackend',
  async (_info, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const draftItems = thunkAPI
      .getState()
      .cart.cartOrderItems.filter((curItem) => curItem.edited);
    if (draftItems.length > 0)
      await Promise.all(
        draftItems.map((curItem) => {
          if (curItem.created && !curItem.deleted) {
            const curCustomer = {
              cart: curItem.orderId,
              id: curItem.customerId,
            };
            return dispatch(
              createOrderItem({
                id: curItem.id,
                customer: curCustomer,
                licenseId: curItem.licenseId,
                licenseDelivery: curItem.delivery,
                licensePrice: curItem.price,
                filters: curItem.filters || null,
              }),
            );
          }
          if (curItem.deleted) {
            return dispatch(deleteOrderItem(curItem.id));
          }
          return dispatch(updateOrderItem(curItem));
        }),
      );
    return draftItems.map((curItem) => curItem.id);
  },
);

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    clearCart(state) {
      state.products = [];
      state.status = 'idle';
      state.cartOrderItems = [];
      state.orderItemsStatus = 'idle';
    },
    updateOrderItemStatus(state, action) {
      state.orderItemsStatus = action.payload;
    },
    addItemToCart(state, action) {
      const {
        customer,
        licenseId,
        licenseDelivery,
        licensePrice,
      } = action.payload;
      const existingItemIndex = state.cartOrderItems.findIndex(
        (curItem) =>
          curItem.orderId === customer.cart && curItem.licenseId === licenseId,
      );
      if (existingItemIndex !== -1) {
        state.cartOrderItems = state.cartOrderItems.map((item, index) => {
          if (index !== existingItemIndex) return item;
          return {
            ...item,
            deleted: false,
            edited: true,
            delivery: licenseDelivery,
            price: licensePrice,
            customerId: customer.id,
          };
        });
      } else {
        const orderItem = {
          id: uuidv4().toUpperCase(),
          orderId: customer.cart,
          customerId: customer.id,
          licenseId,
          delivery: licenseDelivery,
          price: licensePrice,
          from: '',
          to: '',
          edited: true,
          created: true,
        };
        state.cartOrderItems = [...state.cartOrderItems, orderItem];
      }
    },
    removeItemFromCart(state, action) {
      const orderItemId = action.payload;
      const deletedItemIndex = state.cartOrderItems.findIndex(
        (curItem) => curItem.id === orderItemId,
      );
      if (deletedItemIndex !== -1) {
        if (state.cartOrderItems[deletedItemIndex].created)
          state.cartOrderItems = [
            ...state.cartOrderItems.slice(0, deletedItemIndex),
            ...state.cartOrderItems.slice(deletedItemIndex + 1),
          ];
        else
          state.cartOrderItems = state.cartOrderItems.map((item, index) => {
            if (index !== deletedItemIndex) return item;
            return { ...item, deleted: true, edited: true };
          });
      }
    },
    updateItemInCart(state, action) {
      const { id } = action.payload;
      state.cartOrderItems = state.cartOrderItems.map((curItem) => {
        if (curItem.id === id) {
          const updates = action.payload;
          // if the update is deliveryMethod, then check curItem.delivery and either adds or removes it from curItem.delivery
          if (updates.deliveryMethod) {
            if (curItem.delivery.includes(updates.deliveryMethod)) {
              curItem.delivery = curItem.delivery.filter(
                (curDelivery) => curDelivery !== updates.deliveryMethod,
              );
            } else {
              curItem.delivery = [...curItem.delivery, updates.deliveryMethod];
            }
            delete updates.deliveryMethod;
          }
          return { ...curItem, ...action.payload, edited: true };
        }
        return curItem;
      });
    },
  },
  extraReducers: {
    [addProductToCart.pending]: (state) => {
      state.status = 'loading';
    },
    [removeProductFromCart.pending]: (state) => {
      state.status = 'loading';
    },
    [populateCart.pending]: (state) => {
      state.status = 'loading';
    },
    [makeNewCart.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchCartOrderItems.pending]: (state) => {
      state.orderItemsStatus = 'loading';
    },
    [syncDraftToBackend.pending]: (state) => {
      state.updateBackendStatus = 'loading';
    },
    [addProductToCart.rejected]: (state, action) =>
      errorResponse(state, action),
    [removeProductFromCart.rejected]: (state, action) =>
      errorResponse(state, action),
    [populateCart.rejected]: (state, action) => errorResponse(state, action),
    [makeNewCart.rejected]: (state, action) => errorResponse(state, action),
    [fetchCartOrderItems.rejected]: (state, action) => {
      state.orderItemsStatus = 'failed';
      state.error = action.error.message;
    },
    [syncDraftToBackend.rejected]: (state, action) => {
      state.updateBackendStatus = 'failed';
      state.error = action.error.message;
    },
    [addProductToCart.fulfilled]: (state, action) => {
      const product = action.payload;
      state.products.push(product);
      state.status = 'succeeded';
    },
    [removeProductFromCart.fulfilled]: (state, action) => {
      const productId = action.payload;
      state.products = state.products.filter((p) => p.id !== productId);
      state.status = 'succeeded';
    },
    [populateCart.fulfilled]: (state, action) => {
      state.products = action.payload;
      state.status = 'succeeded';
    },
    [makeNewCart.fulfilled]: (state) => {
      state.products = [];
      state.status = 'succeeded';
    },
    [fetchCartOrderItems.fulfilled]: (state, action) => {
      state.cartOrderItems = action.payload;
      state.orderItemsStatus = 'succeeded';
    },
    [syncDraftToBackend.fulfilled]: (state, action) => {
      const updatedIds = action.payload;
      if (updatedIds.length > 0) {
        state.cartOrderItems = state.cartOrderItems
          .filter((curItem) => !curItem.deleted)
          .map((curItem) => {
            if (updatedIds.includes(curItem.id))
              return {
                ...curItem,
                edited: false,
                created: false,
              };
            return curItem;
          });
      }
      state.updateBackendStatus = 'succeeded';
    },
  },
});

export const {
  clearCart,
  addItemToCart,
  removeItemFromCart,
  updateItemInCart,
} = cartSlice.actions;

export const selectCart = (state) => state.cart.products;
export const selectCartOrderItems = (state) => state.cart.cartOrderItems;
export default cartSlice.reducer;
