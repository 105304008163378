/* eslint-disable no-nested-ternary */

import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import messages from 'app/message';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import fileDownload from 'js-file-download';

import clsx from 'clsx';

import {
  Button,
  Box,
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Collapse,
  Grid,
  Snackbar,
  Select,
  MenuItem,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import {
  ExpandMore as ExpandMoreIcon,
  GetApp as DownloadIcon,
} from '@material-ui/icons';

import StorageIcon from '@material-ui/icons/Storage';

import { useSelector } from 'react-redux';
import { parse } from 'papaparse';
import { SAMPLE_DATA_DISPLAY_COUNT, shopfrontBaseUrl } from 'config';
import Tags from 'features/tags/Tags';
import { selectAllSubscriptions } from 'pages/shopfront/LibraryItemDetailPage/subscriptionsSlice';
import { selectAllFileDeliveries } from 'pages/shopfront/LibraryItemDetailPage/fileDeliveriesSlice';
import ReactGA from 'react-ga4';
import { PreviewDataTable } from '../previewDataTable/PreviewDataTable';
import axios from '../../client/client';
import { selectAllLicenses } from '../../pages/shopfront/FetchStoreInfo/licensesSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(0, 2, 2),
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    backdropFilter: 'blur(10px)',
  },
  cardActions: {
    container: 'flex',
    justifyContent: 'flex-end',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  sectionTitle: {
    margin: theme.spacing(1, 0),
  },
  license: () => ({
    margin: theme.spacing(1, 0, 1, 0),
  }),
  form: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  cardHeader: {
    padding: theme.spacing(3, 1, 0, 2),
  },
  licenseLabel: {
    textTransform: 'uppercase',
    fontWeight: 800,
    color: '#888',
    fontSize: '12px',
  },
  deliveryButton: {
    margin: theme.spacing(2),
    padding: theme.spacing(2, 0),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export const LibraryItem = injectIntl(
  ({
    entity,
    libraryItem,
    restApi,
    fileDelivery,
    s3Share,
    sftp,
    customerId,
    currency = 'usd',
  }) => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    const [getSampleURL, setGetSampleURL] = React.useState(false);
    const [sampleData, setSampleData] = React.useState('');
    const [draft, setDraft] = React.useState(entity);
    const [openErrorBanner, setOpenErrorBanner] = React.useState(false);
    const [
      startSftpDeliveryBanner,
      setStartSftpDeliveryBanner,
    ] = React.useState(false);
    const [
      subscriptionSequenceNumbers,
      setSubscriptionSequenceNumbers,
    ] = React.useState(null);
    const [selectedSequenceNumber, setSelectedSequenceNumber] = React.useState(
      null,
    );

    const [subscriptions] = React.useState(
      useSelector(selectAllSubscriptions).filter(
        (s) => s.libraryItemId === libraryItem.id,
      ),
    );
    const fileDeliveries = useSelector(selectAllFileDeliveries).filter(
      (fd) => fd.libraryItemId === libraryItem.id,
    );

    React.useEffect(() => {
      const subSeqNumbers = subscriptions.reduce(
        (acc, curSub) => ({
          ...acc,
          [curSub.licenseId]: curSub.sequenceNumber
            ? [...curSub.sequenceNumber].sort((a, b) => b - a)
            : [],
        }),
        {},
      );
      setSubscriptionSequenceNumbers(subSeqNumbers);
      setSelectedSequenceNumber(
        Object.entries(subSeqNumbers).reduce(
          (acc, [licenseId, curSeqNumbers]) => ({
            ...acc,
            [licenseId]: curSeqNumbers[0] || -1,
          }),
          {},
        ),
      );
    }, [subscriptions]);

    const associatedLicenseIds = subscriptions
      .map((s) => s.licenseId)
      .concat(fileDeliveries.map((fd) => fd.licenseId));

    const sortAlphanumeric = (a, b) =>
      a.name.localeCompare(b.name, 'en', { numeric: true });

    const licenses = useSelector(selectAllLicenses)
      .filter((license) => associatedLicenseIds.includes(license.id))
      .sort(sortAlphanumeric);

    // Delivery option selection
    const selectedDeliveryOptions = [];
    if (restApi) selectedDeliveryOptions.push('api');
    if (fileDelivery) selectedDeliveryOptions.push('download');
    if (s3Share) selectedDeliveryOptions.push('aws');
    if (sftp) selectedDeliveryOptions.push('SFTP-buyer-hosted');

    // Get available delivery options for the licenses in this product
    const availableDeliveryOptions = licenses
      .map((license) => license.delivery)
      .flat();

    // Test if any of the available options are selected
    const selected = availableDeliveryOptions.some((option) =>
      selectedDeliveryOptions.includes(option),
    );

    if (!selected) return <></>;

    const GAevent = (category, action, label) => {
      ReactGA.event({ category, action, label });
    };

    const handlePreview = async () => {
      if (!getSampleURL) {
        const resourcePath = `${shopfrontBaseUrl}/products/get-sample-url`;
        const resp = await axios.get(resourcePath, {
          params: {
            fileName: `${entity.id}.csv`, //
          },
        });
        setGetSampleURL(resp.data);
        if (resp.data) {
          const response = await axios.get(resp.data);
          handleDrop(response.data);
          setSampleData(response.data);
        } else {
          setSampleData(false);
        }
      } else {
        const response = await axios.get(getSampleURL);
        handleDrop(response.data);

        if (!getSampleURL) setSampleData(false);
        else setSampleData(response.data);
      }
    };

    const handleExpandClick = () => {
      if (expanded)
        GAevent('engagement', 'library_close_preview', {
          item: libraryItem.id,
        });
      else
        GAevent('engagement', 'library_open_preview', { item: libraryItem.id });

      setExpanded(!expanded);
      handlePreview();
    };

    const handleDownload = async (licenseId, licenseName) => {
      GAevent('engagement', 'library_download_latest', {
        item: libraryItem.id,
      });

      const data = await axios
        .put(`${shopfrontBaseUrl}/library-items/download`, {
          params: {
            customerId,
            licenseId,
            sequenceNumber: selectedSequenceNumber[licenseId] || -1,
          },
        })
        .catch((error) => {
          if (error.response.status === 500) setOpenErrorBanner(true);
        });
      if (data === undefined) setOpenErrorBanner(true);
      else
        fileDownload(
          JSON.stringify(data.data.downloadResult, null),
          `${licenseName}.json`,
        );
    };

    const handleSftpPush = async (licenseId) => {
      const libraryItemId = libraryItem.id;
      const data = await axios
        .put(`${shopfrontBaseUrl}/library-items/sftp-push`, {
          params: {
            customerId,
            licenseId,
            libraryItemId,
            sequenceNumber: selectedSequenceNumber[licenseId] || -1,
          },
        })
        .catch((error) => {
          if (error.response.status === 500) setOpenErrorBanner(true);
        });
      if (data === undefined) setOpenErrorBanner(true);
      else setStartSftpDeliveryBanner(true);
    };

    const handleClose = () => {
      setOpenErrorBanner(false);
    };

    const LicenseList = () => (
      <Grid container direction="row" justify="center" alignItems="flex-start">
        {licenses.map((license) => (
          <Grid key={license.id} item container xs={12}>
            <Grid item xs={3} className={classes.license}>
              <Typography component="span" variant="body2">
                <div className={classes.licenseLabel}>License Name</div>
                <div
                  className={classes.licensePrice}
                  test-attribute="license-name"
                >
                  {license.name}
                </div>
              </Typography>
            </Grid>
            <Grid item xs={3} className={classes.license}>
              <Typography component="span" variant="body2">
                <div className={classes.licenseLabel}>
                  <FormattedMessage {...messages.LABEL_PRICING} />
                </div>
                <div
                  className={classes.licensePrice}
                  test-attribute="license-price"
                >
                  {license.price.toString()}{' '}
                  <FormattedMessage
                    {...messages[`CURRENCY_${currency.toUpperCase()}`]}
                  />{' '}
                  (
                  <FormattedMessage
                    {...messages[
                      `BILLING_FREQUENCY_${license.billingFrequency}`
                    ]}
                  />
                  )
                </div>
              </Typography>
            </Grid>
            {license.billingFrequency !== 'once' &&
              (license.delivery.includes('download') ||
                license.delivery.includes('SFTP-buyer-hosted')) && (
                <Grid>
                  {/* <FormControl className={classes.formControl}> */}
                  <div className={classes.licenseLabel}>Sequence Number</div>
                  <div>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedSequenceNumber?.[license.id] || -1}
                      disabled={
                        subscriptionSequenceNumbers?.[license.id]?.length === 0
                      }
                      onChange={(e) => {
                        setSelectedSequenceNumber({
                          ...selectedSequenceNumber,
                          [license.id]: e.target.value,
                        });
                      }}
                    >
                      {subscriptionSequenceNumbers?.[license.id]?.length > 0 ? (
                        subscriptionSequenceNumbers[license.id].map(
                          (curSeqNumber, index) => (
                            <MenuItem key={curSeqNumber} value={curSeqNumber}>
                              {`${
                                subscriptionSequenceNumbers[license.id].length -
                                index
                              }${index === 0 ? ' (most recent)' : ''}`}
                            </MenuItem>
                          ),
                        )
                      ) : (
                        <MenuItem value={-1}>No Data Found</MenuItem>
                      )}
                    </Select>
                  </div>
                  {/* </FormControl> */}
                </Grid>
              )}

            <Grid>
              {license.delivery.includes('download') ? (
                <Button
                  className={classes.deliveryButton}
                  test-attribute="download-button"
                  onClick={() => handleDownload(license.id, license.name)}
                >
                  <DownloadIcon />
                  Download Latest
                </Button>
              ) : null}
              {license.delivery.includes('SFTP-buyer-hosted') ? (
                <Button
                  className={classes.deliveryButton}
                  test-attribute="SFTP-delivery-button"
                  onClick={() => handleSftpPush(license.id)}
                >
                  <StorageIcon />
                  SFTP Push
                </Button>
              ) : null}
              <Snackbar
                open={openErrorBanner}
                autoHideDuration={5000}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity="error">
                  An error occurred while trying to deliver your data. Please
                  contact the seller for assistance.
                </Alert>
              </Snackbar>
              <Snackbar
                open={startSftpDeliveryBanner}
                autoHideDuration={5000}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity="success">
                  The process to deliver data via SFTP has started.
                </Alert>
              </Snackbar>
            </Grid>
          </Grid>
        ))}
      </Grid>
    );

    const handleDrop = (sampleDataSet) => {
      const result = parse(sampleDataSet, { header: true });
      const dataColumns = Object.keys(result.data[0]).map((column) => ({
        field: column,
        flex: 1,
      }));
      const dataRows = result.data
        .splice(0, SAMPLE_DATA_DISPLAY_COUNT)
        .map((row, index) => ({ ...row, id: index + 1 }));
      setDraft({
        ...draft,
        columns: dataColumns,
        rows: dataRows,
      });
    };

    return (
      <Card className={classes.root}>
        <CardHeader
          className={classes.cardHeader}
          title={entity.name}
          subheader={entity.tagline}
          test-attribute="product-name"
        />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {entity.description}
          </Typography>
          <Box pt={2.5}>
            <Tags
              className={classes.tagClass}
              editing={false}
              tagData={draft.tags}
            />
          </Box>
          <Typography className={classes.sectionTitle} variant="h6">
            Licensing
          </Typography>
          <LicenseList />
        </CardContent>
        <CardActions disableSpacing className={classes.cardActions}>
          <Button
            className={classes.button}
            component={Link}
            to={`/library-item-detail?id=${libraryItem.id}`}
            onClick={GAevent('engagement', 'library_view_details', {
              item: libraryItem.id,
            })}
          >
            View Details
          </Button>
          <Button
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            Preview
            <ExpandMoreIcon
              className={clsx('', {
                [classes.expandOpen]: expanded,
              })}
            />
          </Button>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            {draft.columns && draft.rows && sampleData ? (
              <PreviewDataTable
                columns={draft.columns}
                rows={draft.rows}
                onDropEvent={handleDrop}
              />
            ) : !sampleData &&
              !getSampleURL &&
              !draft.columns &&
              !draft.rows ? (
              <Typography> No Preview Available.</Typography>
            ) : (
              <Typography> Fetching data preview... </Typography>
            )}
          </CardContent>
        </Collapse>
      </Card>
    );
  },
);
