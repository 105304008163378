/* eslint-disable default-case, no-param-reassign */

import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { shopfrontBaseUrl } from 'config';
import axios from '../../../client/client';

const errorResponse = (state, action) => {
  state.status = 'failed';
  state.error = action.error.message;
};

const attributesAdapter = createEntityAdapter({
  selectId: (attribute) => attribute.id,
  sortComparer: null,
});

const initialState = attributesAdapter.getInitialState({
  status: 'idle',
  error: null,
});

export const fetchAttributes = createAsyncThunk(
  'attributes/fetchAttributes',
  async ({ shopId }) => {
    const resp = await axios.get(`${shopfrontBaseUrl}/attributes`, {
      params: { shopId },
    });
    return resp.data;
  },
);

const attributesSlice = createSlice({
  name: 'attributes',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAttributes.pending]: (state) => {
      state.status = 'loading';
    },

    [fetchAttributes.rejected]: (state, action) => errorResponse(state, action),
    [fetchAttributes.fulfilled]: (state, action) => {
      if (action.payload) {
        const attribute = action.payload;
        state.status = 'succeeded';
        attributesAdapter.setAll(state, attribute);
      } else {
        state.status = 'failed';
        state.error = 'Error retrieving attributes: received null value';
      }
    },
  },
});

export const {
  selectAll: selectAllAttributes,
  selectById: selectAttributeById,
  selectIds: selectAttributeIds,
} = attributesAdapter.getSelectors((state) => state.attributes);

export default attributesSlice.reducer;
