import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { selectOrderById } from 'pages/OrdersPage/ordersSlice';
import { selectAllOrderItems } from 'pages/OrdersPage/orderItemsSlice';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { selectAllProducts } from 'pages/shopfront/FetchStoreInfo/productsSlice';
import { selectCustomer } from '../shopfront/CustomerSignInPage/customerSignInSlice';
import { Product } from '../../features/product/customerProduct';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  actionButton: {
    margin: theme.spacing(2, 1),
  },
  text: {
    color: theme.palette.text.primary,
  },
}));

function OrderDetailPage() {
  const orderId = new URLSearchParams(window.location.search).get('orderId');
  const classes = useStyles();
  const orderItems = useSelector(selectAllOrderItems).filter(
    (orderItem) => orderItem.orderId === orderId,
  );

  const customer = useSelector(selectCustomer);
  const order = useSelector((state) => selectOrderById(state, orderId));

  const customerInfo = customer;

  const products = useSelector(selectAllProducts).filter((product) =>
    order.productIds.includes(product.id),
  );

  const numProducts = orderItems.length;
  const ProductList = () =>
    products.map((entity) => (
      <Product
        key={entity.id}
        cart
        orderItems={orderItems}
        entity={entity}
        currency={order.currency || 'usd'}
      />
    ));
  const formattedDate = (epochUnix) => {
    const date = new Date(epochUnix);
    return date.toString().substring(0, 15);
  };

  return (
    <>
      <Typography className={classes.text} variant="h3">
        Order #{orderId.substring(0, 5)}
      </Typography>
      <Grid container spacing={3}>
        {orderItems && order ? (
          <Grid item xs={8}>
            <ProductList />
          </Grid>
        ) : null}
        <Grid item xs>
          <Typography className={classes.text} variant="h6">
            Order Summary
          </Typography>
          {orderItems && order ? (
            <Grid item xs>
              <Grid>
                <Typography className={classes.text} variant="body1">
                  {numProducts} product{numProducts !== 1 && 's'}
                </Typography>
                <Typography className={classes.text} variant="body1">
                  Client: {customerInfo.firstName} {customerInfo.lastName}
                </Typography>
                <Typography className={classes.text} variant="body1">
                  Company: {customerInfo.companyName}
                </Typography>
              </Grid>
              <Typography className={classes.text} variant="body1">
                Placed: {formattedDate(order.createdAt)}
              </Typography>
              <Typography className={classes.text} variant="body1">
                Last Updated: {formattedDate(order.updatedAt)}
              </Typography>
              <Box>
                <Button
                  className={classes.actionButton}
                  color="primary"
                  variant="contained"
                  component={Link}
                  to="/explore"
                >
                  Explore Data Sets
                </Button>
                <Button
                  className={classes.actionButton}
                  color="default"
                  variant="contained"
                  component={Link}
                  to="/order-history"
                >
                  View Orders
                </Button>
              </Box>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
}

// const mapStateToProps = () => ({});

// const mapDispatchToProps = {};

// export default connect(mapStateToProps, mapDispatchToProps)(OrderDetailPage);

export default OrderDetailPage;
