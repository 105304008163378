/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import ReactGA from 'react-ga4';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { ProvideAuth as ProvideCustomerAuth } from 'auth/customer/customerAuth';
import PrivateCustomerRoute from 'auth/customer/PrivateRoute';
import { PageWrapper } from 'features/pageWrapper/PageWrapper';
// import theme from './app/theme';

import OrderDetailPage from './pages/OrderDetailPage';
import OrdersPage from './pages/OrdersPage';
import CartPage from './pages/shopfront/CartPage';
import ConfirmOrderPage from './pages/shopfront/ConfirmOrderPage';
import ExplorePage from './pages/shopfront/ExplorePage';
import CustomerSignInPage from './pages/shopfront/CustomerSignInPage';
import SignUpPage from './pages/shopfront/SignUpPage';
import NotFoundPage from './pages/NotFoundPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import OrderSuccessfulPage from './pages/shopfront/OrderSuccessfulPage';
import LibraryPage from './pages/shopfront/LibraryPage';
import LibraryItemDetailPage from './pages/shopfront/LibraryItemDetailPage';
import InvoicePage from './pages/InvoicePage';
import ApiKeyManagementPage from './pages/shopfront/ApiKeyManagementPage';
import InvoicesPage from './pages/shopfront/PaymentsPage';
import SubscriptionsPage from './pages/shopfront/SubscriptionMangementPage';

function getFaviconEl() {
  return document.getElementById('favicon');
}

/* eslint-disable */
function LightenDarkenColor(color, percent) {
  var num = parseInt(color.replace('#', ''), 16),
    amt = Math.round(2.55 * percent),
    R = (num >> 16) + amt,
    B = ((num >> 8) & 0x00ff) + amt,
    G = (num & 0x0000ff) + amt;
  return (
    '#' +
    (
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
      (G < 255 ? (G < 1 ? 0 : G) : 255)
    )
      .toString(16)
      .slice(1)
  );
}
/* eslint-enable */

export default function App() {
  // GID(s) initialized in analyticsSlice.js
  ReactGA.send('pageview');

  const iconLogo = useSelector((state) => state.theming.iconImageURL);
  const shopName = useSelector((state) => state.theming.name);
  document.title = shopName || '';

  const favicon = getFaviconEl(); // Accessing favicon element
  if (favicon) {
    favicon.href = iconLogo;
  }

  const darkThemeEnabled = useSelector(
    (state) => state.theming.darkThemeEnabled,
  );

  const paletteType = darkThemeEnabled ? 'dark' : 'light';
  const primaryLightColor = useSelector(
    (state) => state.theming.primaryLightColor,
  );
  //secondary color for light mode
  const secondaryLightColor = useSelector(
    (state) => state.theming.secondaryLightColor,
  );
  const lightFontColor = useSelector((state) => state.theming.lightFontColor);
  const primaryDarkColor = useSelector(
    (state) => state.theming.primaryDarkColor,
  );
  const secondaryDarkColor = useSelector(
    (state) => state.theming.secondaryDarkColor,
  );
  const darkFontColor = useSelector((state) => state.theming.darkFontColor);
  const theme = createMuiTheme({
    palette: {
      type: paletteType,
      primary: {
        main: paletteType === 'dark' ? primaryDarkColor : primaryLightColor,
        light:
          paletteType === 'dark'
            ? LightenDarkenColor(primaryDarkColor, 20)
            : LightenDarkenColor(primaryLightColor, 20),
        dark:
          paletteType === 'dark'
            ? LightenDarkenColor(primaryDarkColor, -20)
            : LightenDarkenColor(primaryLightColor, -20),
        contrastText: '#fff',
      },
      secondary: {
        main: paletteType === 'dark' ? secondaryDarkColor : secondaryLightColor,
        light:
          paletteType === 'dark'
            ? LightenDarkenColor(secondaryDarkColor, 20)
            : LightenDarkenColor(secondaryLightColor, 20),
        dark:
          paletteType === 'dark'
            ? LightenDarkenColor(secondaryDarkColor, -20)
            : LightenDarkenColor(secondaryLightColor, -20),
        contrastText: '#fff',
      },
      fontColor: paletteType === 'dark' ? darkFontColor : lightFontColor,
    },
  });

  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <ProvideCustomerAuth>
          <Router>
            <Switch>
              <Route exact path="/">
                <Redirect to="/welcome" />
              </Route>
              {/* if the URLs for all the shop pages are preceded with '/shopfront' or something like that, 
                then the following Route component will be able to have a path of just '/shopfront' instead of 
                a long array */}
              <Route
                path={[
                  '/order-detail',
                  '/cart',
                  '/confirm-order',
                  '/explore',
                  '/order-history',
                  '/order-successful',
                  '/library',
                  '/library-item-detail',
                  '/invoice',
                  '/api-keys',
                  '/invoices',
                  '/subscription-management',
                ]}
              >
                <PageWrapper shop>
                  {/* Customer Routes */}
                  <PrivateCustomerRoute exact path="/order-detail">
                    <OrderDetailPage />
                  </PrivateCustomerRoute>
                  <PrivateCustomerRoute exact path="/cart">
                    <CartPage />
                  </PrivateCustomerRoute>
                  <PrivateCustomerRoute exact path="/confirm-order">
                    <ConfirmOrderPage />
                  </PrivateCustomerRoute>
                  <PrivateCustomerRoute exact path="/explore">
                    <ExplorePage />
                  </PrivateCustomerRoute>
                  <PrivateCustomerRoute exact path="/order-history">
                    <OrdersPage />
                  </PrivateCustomerRoute>
                  <PrivateCustomerRoute exact path="/order-successful">
                    <OrderSuccessfulPage />
                  </PrivateCustomerRoute>
                  <PrivateCustomerRoute exact path="/library">
                    <LibraryPage />
                  </PrivateCustomerRoute>
                  <PrivateCustomerRoute exact path="/library-item-detail">
                    <LibraryItemDetailPage />
                  </PrivateCustomerRoute>
                  <PrivateCustomerRoute exact path="/invoice">
                    <InvoicePage />
                  </PrivateCustomerRoute>
                  <PrivateCustomerRoute exact path="/api-keys">
                    <ApiKeyManagementPage />
                  </PrivateCustomerRoute>
                  <PrivateCustomerRoute exact path="/invoices">
                    <InvoicesPage />
                  </PrivateCustomerRoute>
                  <PrivateCustomerRoute exact path="/subscription-management">
                    <SubscriptionsPage />
                  </PrivateCustomerRoute>
                  {/* End of Customer routes */}
                </PageWrapper>
              </Route>

              <Route path="/">
                <PageWrapper signedOut>
                  <Switch>
                    {/* Public routes */}
                    <Route exact path="/users/sign-in">
                      <CustomerSignInPage />
                    </Route>
                    <Route exact path="/users/sign-up">
                      <SignUpPage />
                    </Route>
                    <Route exact path="/users/reset">
                      <Route
                        render={(props) => <ResetPasswordPage {...props} />}
                      />
                    </Route>
                    <Route exact path="/welcome">
                      <Redirect to="/users/sign-in" />
                    </Route>
                    <Route render={NotFoundPage} />
                  </Switch>
                </PageWrapper>
              </Route>
            </Switch>
          </Router>
        </ProvideCustomerAuth>
      </ThemeProvider>
    </>
  );
}
