/* eslint-disable no-nested-ternary */
import React from 'react';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Card,
  CardHeader,
  IconButton,
  Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import { grantAccessToBucket } from '../../pages/shopfront/FetchStoreInfo/s3ShareSlice';
import { selectCustomer } from '../../pages/shopfront/CustomerSignInPage/customerSignInSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(0, 2, 2),
  },
}));

export const S3ShareModal = ({ s3Modal, setS3Modal, license, datasetId }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const customer = useSelector(selectCustomer);
  const accessState = useSelector((state) => state.s3Share.grantAccess);
  const accessPointName = useSelector((state) => state.s3Share.accessPointName);
  const accessPointARN = useSelector((state) => state.s3Share.accessPointARN);
  const accessPointPrefix = useSelector(
    (state) => state.s3Share.accessPointPrefix,
  );

  const grantAccess = async () => {
    dispatch(
      grantAccessToBucket({
        customerId: customer.id,
        awsUserId: customer.awsUserId,
        datasetId,
        licenseId: license.id,
      }),
    );
  };

  return (
    <Modal
      open={s3Modal}
      onClose={() => setS3Modal(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Card
        className={classes.root}
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <IconButton color="primary" onClick={() => setS3Modal(false)}>
          <CloseIcon size="small" />
        </IconButton>
        <CardHeader className={classes.cardHeader} title="S3 Access" />
        {accessPointName !== null ? (
          <>
            <Typography variant="body1">
              You have been granted access to the S3 bucket that contains your
              results with list object permissions and read permissions! In
              order to access the files through the CLI, ensure you have set up
              your AWS credentials properly.
            </Typography>
            <Typography variant="body2">
              Name of your access point: {accessPointName}
            </Typography>
            <Typography variant="body2">
              ARN of your access point: {accessPointARN}
            </Typography>
            <Typography variant="body2">
              Prefix that you have been granted access to: {accessPointPrefix}
            </Typography>
            <Typography variant="body1">
              Here is an example of how you can use the CLI to list your files.
            </Typography>
            <Typography variant="body2">
              aws s3api list-objects-v2 --bucket {accessPointARN} --prefix{' '}
              {accessPointPrefix}
            </Typography>
            <Typography variant="body1">
              Here is an example of how you can use the CLI to download a file
              onto your desktop.
            </Typography>
            <Typography variant="body1">
              aws s3api get-object --key PREFIX/FILE_NAME.csv --bucket{' '}
              {accessPointARN} Desktop/download.csv
            </Typography>
            <Typography variant="body1">
              For more information, refer to the{' '}
              <a href="https://docs.aws.amazon.com/AmazonS3/latest/userguide/access-points-usage-examples.html">
                AWS Documentation.
              </a>
            </Typography>
          </>
        ) : // eslint-disable-next-line no-nested-ternary
        accessState === 'failed' ? (
          <Typography variant="body1">
            There was an error granting access. Try again later! If this error
            persists, please contact JamLabs Data Science.
          </Typography>
        ) : !customer.awsUserId ? (
          <Typography variant="body1">
            Before gaining access to the S3 bucket with your data, you must
            submit your AWS Account Id on the{' '}
            <a href="/api-keys">Delivery Options</a> page.
          </Typography>
        ) : (
          <>
            <Typography variant="body1">
              No existing access points for the specified license. Would you
              like to be granted access?
            </Typography>
            <Button
              className={classes.actionButton}
              onClick={() => grantAccess()}
              variant="contained"
            >
              Grant Access
            </Button>
          </>
        )}
      </Card>
    </Modal>
  );
};
