import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Snackbar,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { stripeKey } from 'config';
import { useSelector, useDispatch } from 'react-redux';
import {
  createPayment,
  getPaymentMethod,
} from '../../pages/shopfront/ConfirmOrderPage/paymentSlice';
import { selectCustomer } from '../../pages/shopfront/CustomerSignInPage/customerSignInSlice';

const useStyles = makeStyles(() => ({
  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '80vh',
  },
  inputField: {
    width: '100%',
    marginBottom: '10px',
  },
}));

const stripeLightCardOptions = {
  style: {
    base: {
      color: '#000000',
      fontFamily: '-apple-system, BlinkMacSystemFont',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
  hidePostalCode: true,
};

const stripeDarkCardOptions = {
  style: {
    base: {
      color: '#fff',
      fontFamily: '-apple-system, BlinkMacSystemFont',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
  hidePostalCode: true,
};

export const PaymentDialog = ({
  paymentDialog = false,
  handlePaymentCancel,
  subscription,
  license,
}) => {
  const [paymentField, setPaymentField] = React.useState({});
  const [errorMessage, setErrorMessage] = React.useState('');
  const [emailErrorMessage, setEmailErrorMessage] = React.useState('');
  const [phoneErrorMessage, setPhoneErrorMessage] = React.useState('');
  const [orderConfirming, setIsOrderConfirming] = React.useState(false);
  const customer = useSelector(selectCustomer);
  const darkThemeEnabled = useSelector(
    (state) => state.theming.darkThemeEnabled,
  );
  const currency = useSelector((state) => state.licenses.currency);
  const stripe = useStripe();
  const elements = useElements();
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClose = async () => {
    handlePaymentCancel();
  };

  const handleAlertClose = async () => {
    setErrorMessage('');
  };

  const getPaymentTotal = (total) => {
    const orderTotal = parseFloat(total, 2);
    return orderTotal * 100;
  };

  const handleSubscription = async (paymentMethod) => {
    if (!stripe || !elements) return [false, 'loading Error'];
    const orderTotal = getPaymentTotal(subscription.price);
    const applicationFeePercentage = 0.25;
    const feeAmount = applicationFeePercentage * orderTotal;
    const amount = orderTotal - feeAmount;
    try {
      await stripe.subscriptions.del(subscription.stripeId);
    } catch (err) {
      if (!err.raw?.message.includes('No such')) {
        return [false, err.raw.message];
      }
    }
    await dispatch(
      createPayment({
        key: stripeKey,
        amount,
        currency,
        feeAmount,
        shopId: customer.shopId,
        paymentMethod: [paymentMethod],
        stripe,
        customer,
        type: 'subscription',
        price: license.stripePriceId,
        id: subscription.licenseId,
        subscriptionId: subscription.id,
        orderId: subscription.orderId,
      }),
    );
    return [true, null];
  };

  const handlePaymentUpdate = async () => {
    setIsOrderConfirming(true);
    setEmailErrorMessage('');
    setPhoneErrorMessage('');
    if (!paymentField.phone.match(/^\d{10}$/)) {
      setPhoneErrorMessage('Phone Number must be in form xxxxxxxxxx');
      setIsOrderConfirming(false);
      return;
    }
    const paymentMethod = await getPaymentMethod(
      stripe,
      elements,
      paymentField,
      stripeKey,
      customer,
    );
    if (paymentMethod.error) {
      const paymentMessage = paymentMethod.error.message;
      setErrorMessage(paymentMessage);
      setEmailErrorMessage(
        paymentMessage.includes('email') ? 'Email is incorrect' : '',
      );
      return;
    }
    const [success, message] = await handleSubscription(paymentMethod);
    if (!success) {
      setErrorMessage(message);
      return;
    }
    setIsOrderConfirming(false);
    handleClose();
  };

  return (
    <Dialog
      open={paymentDialog}
      onClose={handleClose}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle>Update Payment Information</DialogTitle>
      <DialogContent>
        <TextField
          required
          className={classes.inputField}
          label="Name"
          aria-label="Name"
          defaultValue={paymentField.name}
          onChange={(e) => {
            setPaymentField({ ...paymentField, name: e.target.value });
          }}
        />
        <TextField
          required
          className={classes.inputField}
          label="Email"
          aria-label="Email"
          type="email"
          defaultValue={paymentField.email}
          error={emailErrorMessage !== ''}
          helperText={emailErrorMessage}
          onChange={(e) => {
            setPaymentField({ ...paymentField, email: e.target.value });
          }}
        />
        <TextField
          required
          className={classes.inputField}
          label="Phone"
          aria-label="Phone"
          defaultValue={paymentField.phone}
          error={phoneErrorMessage !== ''}
          helperText={phoneErrorMessage}
          onChange={(e) => {
            setPaymentField({ ...paymentField, phone: e.target.value });
          }}
        />
        <TextField
          required
          className={classes.inputField}
          label="Address"
          aria-label="Address"
          defaultValue={paymentField.address}
          onChange={(e) => {
            setPaymentField({ ...paymentField, address: e.target.value });
          }}
        />
        <TextField
          className={classes.inputField}
          label="Unit"
          aria-label="Unit"
          defaultValue={paymentField.unit}
          onChange={(e) => {
            setPaymentField({ ...paymentField, unit: e.target.value });
          }}
        />
        <TextField
          required
          className={classes.inputField}
          label="City"
          aria-label="City"
          defaultValue={paymentField.city}
          onChange={(e) => {
            setPaymentField({ ...paymentField, city: e.target.value });
          }}
        />
        <TextField
          required
          className={classes.inputField}
          label="State"
          aria-label="State"
          defaultValue={paymentField.state}
          onChange={(e) => {
            setPaymentField({ ...paymentField, state: e.target.value });
          }}
        />
        <TextField
          required
          className={classes.inputField}
          label="Postal Code"
          aria-label="Postal Code"
          defaultValue={paymentField.postal}
          onChange={(e) => {
            setPaymentField({
              ...paymentField,
              postal_code: e.target.value,
            });
          }}
        />
        {darkThemeEnabled ? (
          <CardElement options={stripeDarkCardOptions} />
        ) : (
          <CardElement options={stripeLightCardOptions} />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handlePaymentUpdate}
          disabled={orderConfirming}
        >
          Update
        </Button>
        <Button variant="contained" onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
      <Snackbar
        open={errorMessage !== ''}
        autoHideDuration={5000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity="error">
          Rejection Reason:<br></br>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};
