/* eslint-disable default-case, no-param-reassign */

import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { shopfrontBaseUrl } from 'config';
import axios from 'client/client';

const errorResponse = (state, action) => {
  state.status = 'failed';
  state.error = action.error.message;
};

const dataSetsAdapter = createEntityAdapter({
  selectId: (dataSet) => dataSet.id,
  sortComparer: null,
});

const initialState = dataSetsAdapter.getInitialState({
  status: 'idle',
  error: null,
});

export const fetchDataSets = createAsyncThunk(
  'dataSets/fetchDataSets',
  async (shopId) => {
    const resp = await axios.get(`${shopfrontBaseUrl}/data-sets`, {
      params: { shopId },
    });
    return resp.data;
  },
);

const dataSetsSlice = createSlice({
  name: 'dataSets',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchDataSets.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchDataSets.rejected]: (state, action) => errorResponse(state, action),
    [fetchDataSets.fulfilled]: (state, action) => {
      if (action.payload) {
        const dataSets = action.payload;
        dataSetsAdapter.setAll(
          state,
          dataSets
            .sort((a, b) => b.createdAt - a.createdAt)
            .map((ds) => ({
              ...ds,
              columns: JSON.parse(ds.columns),
              partitionColumns: JSON.parse(ds.partitionColumns),
              types: JSON.parse(ds.types),
            })),
        );
        state.status = 'succeeded';
      } else {
        state.error = 'Error retrieving data sets: received null value';
        state.status = 'failed';
      }
    },
  },
});

export const {
  selectAll: selectAllDataSets,
  selectById: selectDataSetById,
  selectIds: selectDataSetIds,
} = dataSetsAdapter.getSelectors((state) => state.dataSets);

export default dataSetsSlice.reducer;
