/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'client/client';
import { shopfrontBaseUrl } from 'config';

const errorResponse = (state, action) => {
  state.status = 'failed';
  state.error = action.error.message;
};

const initialState = {
  status: 'idle',
  invoices: null,
};

export const fetchInvoices = createAsyncThunk(
  'invoices/fetchInvoices',
  async ({ customerId }) => {
    const route = `${shopfrontBaseUrl}/invoices/active-invoices`;
    const { data } = await axios.get(route, {
      params: { customerId },
    });
    return { data };
  },
);

const invoicesSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchInvoices.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchInvoices.rejected]: (state, action) => {
      errorResponse(state, action);
    },
    [fetchInvoices.fulfilled]: (state, action) => {
      if (action.payload.data) {
        state.status = 'succeeded';
        state.invoices = action.payload.data;
      } else {
        state.status = 'failed';
      }
    },
  },
});

export const selectInvoices = (state) => state.invoices.invoices;

export default invoicesSlice.reducer;
