import React, { useEffect } from 'react';
import {
  IconButton,
  Button,
  Card,
  Box,
  CardContent,
  CardActions,
  Snackbar,
  CardHeader,
  Typography,
  TextField,
  Grid,
  InputAdornment,
  Tooltip,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import {
  HelpOutline as HelpOutlineIcon,
  FileCopy as CopyIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons';

import { shopfrontBaseUrl } from 'config';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import awsLogo from '../../../assets/images/aws.png';

import axios from '../../../client/client';
import {
  fetchApiKey,
  selectApiKey,
  generateApiKey,
  regenerateApiKey,
  hideKey,
} from '../FetchStoreInfo/apiKeysSlice';
import {
  selectSftpHostName,
  selectSftpUserName,
  selectSftpPublicKey,
  selectAssignedPublicKey,
  generateSftpPublicKey,
} from '../FetchStoreInfo/sftpSlice';
import {
  selectCustomer,
  saveAwsId,
  changeAwsIdStatus,
} from '../CustomerSignInPage/customerSignInSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  },
  textField: {
    margin: theme.spacing(1),
    width: '50ch',
  },
  avatar: {
    height: '50px',
    width: '50px',
  },
  button: {
    marginLeft: theme.spacing(2),
  },
  instructions: {
    marginTop: theme.spacing(4),
  },
  text: {
    color: theme.palette.text.primary,
  },
}));

function ApiKeyManagementPage() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const customer = useSelector(selectCustomer);
  const hideKeyAction = hideKey();
  const apiKeyStatus = useSelector((state) => state.apiKey.status);
  const [showApiKey, setShowApiKey] = React.useState(false);
  const apiKey = useSelector(selectApiKey);
  const [showAlert, setShowAlert] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [generated, setGenerated] = React.useState(false);

  useEffect(() => {
    if (apiKeyStatus === 'idle') {
      dispatch(
        fetchApiKey({
          entityId: customer.id,
        }),
      );
    }
  }, [apiKeyStatus, dispatch, customer]);

  const sampleUrl =
    '/data?productId=1384B0D3-0079-41D9-8597-0DCE814074DA&startDate=2019-01-01&endDate=2019-01-15';

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(axios.defaults.baseURL + sampleUrl).then(
      () => {},
      (err) => {
        throw err;
      },
    );
  };

  const handleCopyKeyID = () => {
    navigator.clipboard.writeText(apiKey.apiKey.id).then(
      () => {
        setShowAlert(true);
      },
      (err) => {
        throw err;
      },
    );
  };

  const handleCopyAPIkey = () => {
    navigator.clipboard.writeText(apiKey.apiKey.key).then(
      () => {
        setShowAlert(true);
      },
      (err) => {
        throw err;
      },
    );
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    if (showAlert === true) {
      setTimeout(() => {
        setShowAlert(false);
      }, 2000);
    }
  }, [showAlert]);

  useEffect(() => {
    if (!generated) {
      dispatch(hideKeyAction);
    }
  }, [dispatch, hideKeyAction, generated]);

  const handleGenerateKey = () => {
    dispatch(
      generateApiKey({
        entityId: customer.id,
        entityType: 'customer',
      }),
    );
    setGenerated(true);
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
  };

  const setApiKeyMessage = () => {
    if (apiKey.assignedKey && apiKey.displayKey) {
      return apiKey.apiKey.key;
    }
    if (apiKey.assignedKey && !apiKey.displayKey) {
      return 'Existing API key found';
    }
    return 'No API Key found';
  };

  let createdDateFormatted = '';
  let lastUsedDateFormatted = '';
  if (apiKey.assignedKey) {
    const createdDate = new Date(apiKey.apiKey.createdAt);
    createdDateFormatted = createdDate.toString().substring(0, 15);

    const lastUsedDate = new Date(apiKey.apiKey.lastUsed);
    lastUsedDateFormatted = lastUsedDate.toString().substring(0, 15);
  }

  const handleRegenerateKey = () => {
    setDialogOpen(false);
    dispatch(
      regenerateApiKey({
        oldId: apiKey.apiKey.id,
        entityId: customer.id,
        entityType: 'customer',
      }),
    );
    setGenerated(true);
  };

  const JamLabsCard = () => (
    <Card className={classes.root}>
      <CardHeader
        title="Express REST API"
        subheader="Dynamically query the data and get immediate up-to-date responses."
      />
      <CardContent>
        <TextField
          className={classes.textField}
          type="text"
          value={apiKey.assignedKey ? apiKey.apiKey.id : 'No Key ID found'}
          label="Key ID"
          disabled
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <span>
                  <IconButton
                    onClick={handleCopyKeyID}
                    onMouseDown={handleMouseDown}
                    disabled={!apiKey.assignedKey}
                  >
                    <CopyIcon />
                  </IconButton>
                </span>
              </InputAdornment>
            ),
          }}
        />
        <Grid container alignItems="center" justify="flex-start" spacing={3}>
          <Grid item>
            <TextField
              className={classes.textField}
              type={
                showApiKey ||
                !apiKey.assignedKey ||
                (!apiKey.displayKey && apiKey.assignedKey)
                  ? 'text'
                  : 'password'
              }
              value={setApiKeyMessage()}
              label="API Key"
              disabled={!apiKey.displayKey}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip
                      title={
                        !apiKey.displayKey && apiKey.assignedKey
                          ? 'Unable to reveal existing API keys'
                          : ''
                      }
                      placement="top-end"
                      arrow
                    >
                      <span>
                        <IconButton
                          onClick={() => setShowApiKey(!showApiKey)}
                          onMouseDown={handleMouseDown}
                          disabled={!apiKey.displayKey}
                        >
                          {showApiKey ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </span>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item>
            <Typography component="div" variant="body1">
              <Box>
                <b>Date created:</b> {createdDateFormatted}
              </Box>
              <Box>
                <b>Date API key last used: </b> {lastUsedDateFormatted}
              </Box>
            </Typography>
          </Grid>
          <Grid item>
            {apiKey.displayKey ? (
              <Typography>
                Warning - Navigating away from this page will permenetly hide
                the API key. Ensure it is copied and safely stored before
                leaving this page.
              </Typography>
            ) : null}
          </Grid>
          <Grid item xs={11}>
            <Button
              variant="contained"
              color="primary"
              onClick={
                apiKey.assignedKey ? handleDialogOpen : handleGenerateKey
              }
            >
              {apiKey.assignedKey ? 'Revoke & Regenerate Key' : 'Generate Key'}
            </Button>
            <Dialog
              open={dialogOpen}
              onClose={handleDialogClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">API Key Warning</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Regenerating the API key will invalidate the existing API Key.
                  Are you sure you want to regenerate the API key?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleDialogClose}
                  variant="contained"
                  autoFocus
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleRegenerateKey}
                  variant="contained"
                  color="primary"
                >
                  Regenerate
                </Button>
              </DialogActions>
            </Dialog>
            {apiKey.assignedKey ? (
              <Tooltip
                title={
                  apiKey.displayKey ? '' : 'Unable to copy existing API keys'
                }
                placement="top-start"
                arrow
              >
                <span>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="default"
                    disabled={!apiKey.displayKey}
                    onClick={handleCopyAPIkey}
                  >
                    Copy API Key
                  </Button>
                </span>
              </Tooltip>
            ) : null}
            {showAlert ? (
              <Alert severity="success" style={{ marginTop: 20 }}>
                Successfully copied to clipboard!
              </Alert>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <Box m="4">
              <TextField
                fullWidth
                disabled
                value={sampleUrl}
                label="Sample Query String"
                InputProps={{
                  endAdornment: (
                    <IconButton position="end" onClick={handleCopyToClipboard}>
                      <CopyIcon />
                    </IconButton>
                  ),
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );

  const SftpCard = () => {
    const sftpPublicKey = useSelector(selectSftpPublicKey);
    const sftpHostName = useSelector(selectSftpHostName);
    const sftpUserName = useSelector(selectSftpUserName);
    const assignedPublicKey = useSelector(selectAssignedPublicKey);
    const [showCopiedAlert, setShowCopiedAlert] = React.useState(false);
    const [showSavedAlert, setShowSavedAlert] = React.useState(false);
    const [showFailedAlert, setShowFailedAlert] = React.useState(false);
    const [showAlreadySavedAlert, setShowAlreadySavedAlert] = React.useState(
      false,
    );
    const [newKeyDialogOpen, setNewKeyDialogOpen] = React.useState(false);
    const [editSftpHostName, setEditSftpHostName] = React.useState(false);
    const [editSftpUserName, setEditSftpUserName] = React.useState(false);
    const [sftpParamsDraft, setSftpParamsDraft] = React.useState({
      hostName: '',
      userName: '',
    });

    const handleSaveHostAndUsername = async () => {
      if (editSftpHostName) {
        const route = `${shopfrontBaseUrl}/sftp/save-host-name`;
        const response = await axios
          .post(route, {
            customerId: customer.id,
            hostName: sftpParamsDraft.hostName,
          })
          .catch(() => {
            setShowFailedAlert(true);
          });
        if (response && response.status === 500) {
          setShowFailedAlert(true);
        }
      }
      if (editSftpUserName) {
        const route = `${shopfrontBaseUrl}/sftp/save-user-name`;
        const response = await axios
          .post(route, {
            customerId: customer.id,
            userName: sftpParamsDraft.userName,
          })
          .catch(() => {
            setShowFailedAlert(true);
          });
        if (response && response.status === 500) {
          setShowFailedAlert(true);
        }
      }
      if ((editSftpUserName || editSftpHostName) && !showFailedAlert)
        setShowSavedAlert(true);
      else setShowAlreadySavedAlert(true);
    };

    const handleGeneratePublicKey = () => {
      if (newKeyDialogOpen) setNewKeyDialogOpen(false);
      dispatch(generateSftpPublicKey(customer.id));
    };

    const handleCopyPublicKey = () => {
      navigator.clipboard.writeText(sftpPublicKey).then(
        () => {
          setShowCopiedAlert(true);
        },
        (err) => {
          throw err;
        },
      );
    };

    const handleClose = () => {
      setShowCopiedAlert(false);
      setShowSavedAlert(false);
      setShowFailedAlert(false);
    };

    const handleNewKeyDialogClose = () => {
      setNewKeyDialogOpen(false);
    };

    const openPublicKeyDialog = () => {
      setNewKeyDialogOpen(true);
    };

    return (
      <Card className={classes.root}>
        <CardHeader
          title="SFTP - Buyer Hosted"
          subheader="Have data deliveries pushed to your SFTP server."
        />
        <CardContent>
          <Typography>
            Provide a Host Name and Username to connect to your server with.
          </Typography>
          <TextField
            className={classes.textField}
            value={editSftpHostName ? sftpParamsDraft.hostName : sftpHostName}
            label="Host Name"
            onChange={(e) => {
              setEditSftpHostName(true);
              setSftpParamsDraft({
                ...sftpParamsDraft,
                hostName: e.target.value,
              });
            }}
          />
          <TextField
            className={classes.textField}
            value={editSftpUserName ? sftpParamsDraft.userName : sftpUserName}
            label="Username"
            onChange={(e) => {
              setEditSftpUserName(true);
              setSftpParamsDraft({
                ...sftpParamsDraft,
                userName: e.target.value,
              });
            }}
          />
          <Grid container justify="flex-start">
            <Typography className={classes.instructions}>
              Register the public key below to the user you provided above to
              allow for key based authentication.
            </Typography>
            <Grid item xs={12}>
              <TextField
                fullWidth
                className={classes.textField}
                value={sftpPublicKey}
                label="Public Key"
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <span>
                        <IconButton
                          onClick={handleCopyPublicKey}
                          onMouseDown={handleMouseDown}
                          disabled={!assignedPublicKey}
                        >
                          <CopyIcon />
                        </IconButton>
                      </span>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Snackbar
          open={showCopiedAlert}
          autoHideDuration={5000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            Your public key was successfully copied!
          </Alert>
        </Snackbar>
        <Snackbar
          open={showSavedAlert}
          autoHideDuration={5000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            Your Host Name and Username were successfully saved!
          </Alert>
        </Snackbar>
        <Snackbar
          open={showFailedAlert}
          autoHideDuration={5000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
            An error occured. Please try again or contact support.
          </Alert>
        </Snackbar>
        <Snackbar
          open={showAlreadySavedAlert}
          autoHideDuration={5000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            The Host Name and Username values were saved previously.
          </Alert>
        </Snackbar>
        <CardActions disableSpacing>
          <Grid container justify="flex-end" alignItems="center">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveHostAndUsername}
            >
              Save Host and Username
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={
                assignedPublicKey
                  ? openPublicKeyDialog
                  : handleGeneratePublicKey
              }
            >
              Generate Public Key
            </Button>
            <Dialog open={newKeyDialogOpen} onClose={handleNewKeyDialogClose}>
              <DialogTitle id="alert-dialog-title">
                SFTP Public Key Warning
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Generating a new key will disable your existing key. Are you
                  sure you want to generate a new key?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleNewKeyDialogClose}
                  variant="contained"
                  autoFocus
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleGeneratePublicKey}
                  variant="contained"
                  color="primary"
                >
                  Generate
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </CardActions>
      </Card>
    );
  };

  const AwsS3ShareCard = () => {
    const [awsId, setAwsId] = React.useState(
      customer && customer.awsUserId ? customer.awsUserId : '',
    );
    const [openSuccessBanner, setOpenSuccessBanner] = React.useState(false);
    const [openErrorBanner, setOpenErrorBanner] = React.useState(false);

    // eslint-disable-next-line no-shadow
    const saveAWSId = (awsId) => {
      const params = {
        customerId: customer.id,
        awsAccountId: awsId.toString(),
      };
      dispatch(saveAwsId(params));
    };

    const bannerStatus = useSelector((state) => state.customer.awsIdstatus);

    useEffect(() => {
      if (bannerStatus === 'succeeded') {
        setOpenSuccessBanner(true);
        dispatch(changeAwsIdStatus());
      } else if (bannerStatus === 'failed') {
        setOpenErrorBanner(true);
      }
    }, [bannerStatus]);

    const handleClose = () => {
      setOpenSuccessBanner(false);
      setOpenErrorBanner(false);
    };

    return (
      <Card className={classes.root}>
        <CardHeader
          avatar={
            <img className={classes.avatar} src={awsLogo} alt={awsLogo} />
          }
          title="AWS S3 Data Lake"
          subheader="Gain access to the bucket that your data is stored in via S3 Access Points."
        />
        <CardContent>
          <TextField
            value={awsId}
            onChange={(e) => setAwsId(e.target.value)}
            label="AWS Account ID"
          />
        </CardContent>
        <Snackbar
          open={openSuccessBanner}
          autoHideDuration={5000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            Your AWS Account ID was saved successfully!
          </Alert>
        </Snackbar>
        <Snackbar
          open={openErrorBanner}
          autoHideDuration={5000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
            An error occurred while saving your AWS Account ID. Please try again
            later!
          </Alert>
        </Snackbar>
        <CardActions disableSpacing>
          <Grid container item justify="flex-end">
            <Button
              variant="contained"
              color="primary"
              onClick={() => saveAWSId(awsId)}
            >
              Save
            </Button>
          </Grid>
        </CardActions>
      </Card>
    );
  };

  return (
    <>
      <Typography className={classes.text} variant="h3">
        Data Delivery Options
        <HelpOutlineIcon fontSize="large" />
      </Typography>
      <Typography className={classes.text} variant="body2">
        Specify how you want data to be available or accessed.
      </Typography>
      <Typography className={classes.text} variant="h6">
        Rest API
      </Typography>
      <JamLabsCard />
      <Typography className={classes.text} variant="h6">
        Secure File Transfer Protocol
      </Typography>
      <SftpCard />
      <Typography className={classes.text} variant="h6">
        S3 Share
      </Typography>
      <AwsS3ShareCard />
    </>
  );
}

export default ApiKeyManagementPage;
