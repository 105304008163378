/* eslint-disable default-case, no-param-reassign */

import { shopfrontBaseUrl } from 'config';
import update from 'immutability-helper';
import moment from 'moment';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import axios from 'client/client';

const errorResponse = (state, action) => {
  state.status = 'failed';
  state.error = action.error.message;
};

const libraryItemsAdapter = createEntityAdapter({
  selectId: (libraryItem) => libraryItem.id,
  sortComparer: null,
});

const initialState = libraryItemsAdapter.getInitialState({
  status: 'idle',
  error: null,
  selectedTags: {},
  filter: {
    from: moment().add(-2, 'M').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
    query: '',
    sortParam: 0,
  },
});

export const fetchLibraryItems = createAsyncThunk(
  'libraryItems/fetchLibraryItems',
  async (customerId) => {
    const resp = await axios.get(`${shopfrontBaseUrl}/library-items`, {
      params: { customerId },
    });
    return { libraryItems: resp.data };
  },
);

export const updateLibraryItem = createAsyncThunk(
  'libraryItems/updateLibraryItem',
  async (libraryItem) => {
    await axios.put(`${shopfrontBaseUrl}/library-items`, {
      ...libraryItem,
    });
    return libraryItem;
  },
);

export const deleteLibraryItem = createAsyncThunk(
  'libraryItems/deleteLibraryItem',
  async (id) => {
    await axios.delete(`${shopfrontBaseUrl}/library-items`, {
      params: {
        id,
      },
    });
    return id;
  },
);

const libraryItemsSlice = createSlice({
  name: 'libraryItems',
  initialState,
  reducers: {
    filterEdited(state, action) {
      const prop = Object.keys(action.payload)[0];
      const value = action.payload[prop];
      state.filter[prop] = value;
    },
    toggleTag(state, { payload: { attribute, tag } }) {
      if (state.selectedTags[attribute] && state.selectedTags[attribute][tag]) {
        delete state.selectedTags[attribute][tag];

        const residualTags = state.selectedTags[attribute];
        if (
          Object.keys(residualTags).length === 0 &&
          residualTags.constructor === Object
        ) {
          delete state.selectedTags[attribute];
        }
      } else {
        state.selectedTags[attribute] = update(
          state.selectedTags[attribute] || {},
          {
            [tag]: {
              $set: true,
            },
          },
        );
      }
    },
  },
  extraReducers: {
    [fetchLibraryItems.pending]: (state) => {
      state.status = 'loading';
    },
    [updateLibraryItem.pending]: (state) => {
      state.status = 'loading';
    },
    [deleteLibraryItem.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchLibraryItems.rejected]: (state, action) =>
      errorResponse(state, action),
    [updateLibraryItem.rejected]: (state, action) =>
      errorResponse(state, action),
    [deleteLibraryItem.rejected]: (state, action) =>
      errorResponse(state, action),
    [fetchLibraryItems.fulfilled]: (state, action) => {
      if (action.payload) {
        const { libraryItems } = action.payload;
        libraryItemsAdapter.setAll(state, libraryItems);
        state.status = 'succeeded';
      } else {
        state.error = 'Error retrieving library items: received null value';
        state.status = 'failed';
      }
    },
    [updateLibraryItem.fulfilled]: (state, action) => {
      const libraryItem = action.payload;
      libraryItemsAdapter.updateOne(state, {
        id: libraryItem.id,
        changes: libraryItem,
      });
      state.status = 'succeeded';
    },
    [deleteLibraryItem.fulfilled]: (state, action) => {
      const id = action.payload;
      libraryItemsAdapter.removeOne(state, id);
      state.status = 'succeeded';
    },
  },
});

export const { filterEdited, toggleTag } = libraryItemsSlice.actions;

export const selectSelectedTags = (state) => state.libraryItems.selectedTags;

export const {
  selectAll: selectAllLibraryItems,
  selectById: selectLibraryItemById,
  selectIds: selectLibraryItemIds,
} = libraryItemsAdapter.getSelectors((state) => state.libraryItems);

export default libraryItemsSlice.reducer;
