/* eslint-disable default-case, no-param-reassign */

import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import moment from 'moment';
import { shopfrontBaseUrl } from 'config';
import axios from '../../../client/client';

const errorResponse = (state, action) => {
  state.status = 'failed';
  state.error = action.error.message;
};

const PRODUCT_DRAFT_STATUS = 'Draft';

// Push products that are newer to the top (newer = less complete)
const isDraftNew = (product) => {
  let weight = 0;
  if (product.status === PRODUCT_DRAFT_STATUS) weight -= 5;
  return weight;
};

const productsAdapter = createEntityAdapter({
  selectId: (product) => product.id,
  sortComparer: (a, b) =>
    a.name.localeCompare(b.name) + (isDraftNew(a) - isDraftNew(b)),
});

const initialState = productsAdapter.getInitialState({
  status: 'idle',
  error: null,
  filter: {
    from: moment().add(-2, 'M').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
    status: {
      draft: true,
      published: true,
    },
    query: '',
    sortParam: 0,
  },
});

export const fetchProducts = createAsyncThunk(
  'products/fetchProducts',
  async ({ shopId }) => {
    const resp = await axios.get(`${shopfrontBaseUrl}/products`, {
      params: { shopId },
    });
    return { products: resp.data };
  },
);

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    filterEdited(state, action) {
      const prop = Object.keys(action.payload)[0];
      const value = action.payload[prop];
      state.filter[prop] = value;
    },
  },
  extraReducers: {
    [fetchProducts.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchProducts.rejected]: (state, action) => errorResponse(state, action),
    [fetchProducts.fulfilled]: (state, action) => {
      if (action.payload) {
        const { products } = action.payload;
        productsAdapter.setAll(state, products);
        state.status = 'succeeded';
      } else {
        state.error = 'Error retrieving attributes: received null value';
        state.status = 'failed';
      }
    },
  },
});

export const {
  selectAll: selectAllProducts,
  selectById: selectProductById,
  selectIds: selectProductIds,
} = productsAdapter.getSelectors((state) => state.products);

export const { filterEdited } = productsSlice.actions;

export default productsSlice.reducer;
