/* eslint-disable default-case, no-param-reassign */

import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import moment from 'moment';
import { shopfrontBaseUrl, stripeKey } from 'config';
import axios from 'client/client';

const errorResponse = (state, action) => {
  state.status = 'failed';
  state.error = action.error.message;
};

const subscriptionsAdapter = createEntityAdapter({
  selectId: (subscription) => subscription.id,
  sortComparer: null,
});

const initialState = subscriptionsAdapter.getInitialState({
  status: 'idle',
  error: null,
  ids: [],
  selectedTags: {},
  filter: {
    from: moment().add(-2, 'M').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
    query: '',
    sortParam: 0,
  },
});

export const fetchSubscriptions = createAsyncThunk(
  'subscriptions/fetchSubscriptions',
  async (customerId) => {
    const resp = await axios.get(`${shopfrontBaseUrl}/subscriptions`, {
      params: { customerId },
    });
    return { subscriptions: resp.data };
  },
);

export const unsubscribe = createAsyncThunk(
  'subscriptions/unsubscribe',
  async (id) => {
    const resp = await axios.post(
      `${shopfrontBaseUrl}/subscriptions/unsubscribe`,
      { id, key: stripeKey },
    );
    return { id, expiresAt: resp.expiresAt };
  },
);

const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    filterEdited(state, action) {
      const prop = Object.keys(action.payload)[0];
      const value = action.payload[prop];
      state.filter[prop] = value;
    },
  },
  extraReducers: {
    [fetchSubscriptions.pending]: (state) => {
      state.status = 'loading';
    },
    [unsubscribe.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchSubscriptions.rejected]: (state, action) =>
      errorResponse(state, action),
    [unsubscribe.rejected]: (state, action) => errorResponse(state, action),
    [fetchSubscriptions.fulfilled]: (state, action) => {
      if (action.payload) {
        const { subscriptions } = action.payload;
        subscriptionsAdapter.setAll(state, subscriptions);
        state.status = 'succeeded';
      } else {
        state.error = 'Error retrieving subscriptions: received null value';
        state.status = 'failed';
      }
    },
    [unsubscribe.fulfilled]: (state, action) => {
      const { id } = action.payload;
      const expiresAt = Date.now();

      subscriptionsAdapter.updateOne(state, {
        id,
        changes: { expiresAt },
      });
      state.status = 'succeeded';
    },
  },
});

export const {
  selectAll: selectAllSubscriptions,
  selectById: selectSubscriptionById,
  selectIds: selectSubscriptionIds,
} = subscriptionsAdapter.getSelectors((state) => state.subscriptions);

export const { filterEdited } = subscriptionsSlice.actions;

export default subscriptionsSlice.reducer;
