export const shopfrontBaseUrl =
  process.env.REACT_APP_SHOPFRONT_BASE_URL || 'http://localhost:3001/shopfront';
export const manageBaseUrl =
  process.env.REACT_APP_MANAGE_BASE_URL || 'http://localhost:3001/manage';
// export const jamlabsUAID = process.env.REACT_APP_JAMLABS_UAID;
export const jamlabsGID = process.env.REACT_APP_JAMLABS_MEASUREMENT_ID;
export const stripeKey = process.env.REACT_APP_STRIPE_SECRET_KEY;
export const loadStripeKey = process.env.REACT_APP_LOAD_STRIPE_SECRET_KEY;
export const SAMPLE_DATA_MAX_SIZE = 10000000;
export const SAMPLE_DATA_DISPLAY_COUNT = 99;
