/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'client/client';
import { shopfrontBaseUrl } from 'config';

const errorResponse = (state, action) => {
  state.status = 'failed';
  state.error = action.error.message;
};

const initialState = {
  status: 'idle',
  sftpHostName: '',
  sftpUserName: '',
  sftpPublicKey: '',
  assignedPublicKey: false,
  error: '',
};

export const fetchSftpHostName = createAsyncThunk(
  'sftp/fetchSftpHostName',
  async (customerId) => {
    const route = `${shopfrontBaseUrl}/sftp/get-host-name`;
    const { data, status } = await axios.get(route, {
      params: { customerId },
    });
    return { data, status };
  },
);

export const fetchSftpUserName = createAsyncThunk(
  'sftp/fetchSftpUserName',
  async (customerId) => {
    const route = `${shopfrontBaseUrl}/sftp/get-user-name`;
    const { data, status } = await axios.get(route, {
      params: { customerId },
    });
    return { data, status };
  },
);

export const fetchSftpPublicKey = createAsyncThunk(
  'sftp/fetchPublicKey',
  async (customerId) => {
    const route = `${shopfrontBaseUrl}/sftp/get-public-key`;
    const { data, status } = await axios.get(route, {
      params: { customerId },
    });
    return { data, status };
  },
);

export const saveSftpHostName = createAsyncThunk(
  'sftp/saveSftpHostName',
  async ({ customerId, hostName }) => {
    const route = `${shopfrontBaseUrl}/sftp/save-host-name`;
    const { data } = await axios.post(route, {
      customerId,
      hostName,
    });
    return data;
  },
);

export const saveSftpUserName = createAsyncThunk(
  'sftp/saveSftpUserName',
  async ({ customerId, userName }) => {
    const route = `${shopfrontBaseUrl}/sftp/save-user-name`;
    const { data } = await axios.post(route, {
      customerId,
      userName,
    });
    return data;
  },
);

export const generateSftpPublicKey = createAsyncThunk(
  'sftp/generateSftpPublicKey',
  async (customerId) => {
    const route = `${shopfrontBaseUrl}/sftp/generate-rsa-key-pair`;
    const { data } = await axios.post(route, {
      customerId,
    });
    return data;
  },
);

const sftpSlice = createSlice({
  name: 'sftpKey',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchSftpHostName.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchSftpUserName.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchSftpPublicKey.pending]: (state) => {
      state.status = 'loading';
    },
    [saveSftpHostName.pending]: (state) => {
      state.status = 'loading';
    },
    [saveSftpUserName.pending]: (state) => {
      state.status = 'loading';
    },
    [generateSftpPublicKey.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchSftpHostName.rejected]: (state, action) => {
      errorResponse(state, action);
    },
    [fetchSftpUserName.rejected]: (state, action) => {
      errorResponse(state, action);
    },
    [fetchSftpPublicKey.rejected]: (state, action) => {
      errorResponse(state, action);
    },
    [saveSftpHostName.rejected]: (state, action) => {
      errorResponse(state, action);
    },
    [saveSftpUserName.rejected]: (state, action) => {
      errorResponse(state, action);
    },
    [generateSftpPublicKey.rejected]: (state, action) => {
      errorResponse(state, action);
    },
    [fetchSftpHostName.fulfilled]: (state, action) => {
      if (action.payload.data) {
        state.status = 'succeeded';
        state.sftpHostName = action.payload.data;
        state.assignedHostName = true;
      } else if (action.payload.status !== 400) {
        state.status = 'succeeded';
      } else {
        state.status = 'failed';
        state.error = 'Error retrieving SFTP host name';
      }
    },
    [fetchSftpUserName.fulfilled]: (state, action) => {
      if (action.payload.data) {
        state.status = 'succeeded';
        state.sftpUserName = action.payload.data;
      } else if (action.payload.status !== 400) {
        state.status = 'succeeded';
      } else {
        state.status = 'failed';
        state.error = 'Error retrieving SFTP username';
      }
    },
    [fetchSftpPublicKey.fulfilled]: (state, action) => {
      if (action.payload) {
        state.sftpPublicKey = action.payload.data;
        if (state.sftpPublicKey !== '') {
          state.assignedPublicKey = true;
        }
        state.status = 'succeeded';
      } else if (action.payload.status !== 400) {
        state.status = 'succeeded';
      } else {
        state.status = 'failed';
        state.error = 'Error retrieving the SFTP public key';
      }
    },
    [saveSftpHostName.fulfilled]: (state, action) => {
      if (action.payload) {
        state.sftpHostName = action.payload;
        state.status = 'succeeded';
      } else {
        state.status = 'failed';
        state.error = 'Error saving the SFTP host name';
      }
    },
    [saveSftpUserName.fulfilled]: (state, action) => {
      if (action.payload) {
        state.sftpUserName = action.payload;
        state.status = 'succeeded';
      } else {
        state.status = 'failed';
        state.error = 'Error saving the SFTP user name';
      }
    },
    [generateSftpPublicKey.fulfilled]: (state, action) => {
      if (action.payload) {
        state.sftpPublicKey = action.payload;
        if (state.sftpPublicKey !== '') {
          state.assignedPublicKey = true;
        }
        state.status = 'succeeded';
      } else {
        state.status = 'failed';
        state.error = 'Error generating SFTP public key';
      }
    },
  },
});

export const selectSftpHostName = (state) => state.sftp.sftpHostName;
export const selectSftpUserName = (state) => state.sftp.sftpUserName;
export const selectSftpPublicKey = (state) => state.sftp.sftpPublicKey;
export const selectAssignedPublicKey = (state) => state.sftp.assignedPublicKey;
export const selectStatus = (state) => state.sftp.status;

export default sftpSlice.reducer;
