/* eslint-disable default-case, no-param-reassign */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { shopfrontBaseUrl } from 'config';
import axios from 'client/client';

const initialState = {
  status: 'idle',
  error: null,
  grantAccess: null,
  accessPointName: null,
  accessPointARN: null,
  accessPointPrefix: null,
};

export const grantAccessToBucket = createAsyncThunk(
  'S3-data-sharing/s3-share',
  async (params) => {
    const resp = await axios.post(
      `${shopfrontBaseUrl}/S3-data-sharing/s3-share`,
      params,
      { timeout: 30000 },
    );
    return resp.data;
  },
);

export const getAccessPoint = createAsyncThunk(
  'S3-data-sharing/access-points',
  async (params) => {
    const resp = await axios.get(
      `${shopfrontBaseUrl}/S3-data-sharing/access-points`,
      { params },
    );
    return resp.data;
  },
);

const s3ShareSlice = createSlice({
  name: 's3Share',
  initialState,
  reducers: {
    resetS3ShareSlice: () => initialState,
  },
  extraReducers: {
    [grantAccessToBucket.pending]: (state) => {
      state.status = 'loading';
    },
    [grantAccessToBucket.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [grantAccessToBucket.fulfilled]: (state, action) => {
      if (action.payload) {
        state.accessPointName = action.payload.Name;
        state.accessPointARN = action.payload.accessPointARN;
        state.accessPointPrefix = action.payload.prefix;
        state.grantAccess = true;
        state.status = 'succeeded';
      } else {
        state.error = 'Error granting access to s3 bucket';
        state.status = 'failed';
      }
    },
    [getAccessPoint.pending]: (state) => {
      state.status = 'loading';
    },
    [getAccessPoint.rejected]: (state, action) => {
      state.status = 'failed';
      state.grantAccess = 'failed';
      state.error = action.error.message;
    },
    [getAccessPoint.fulfilled]: (state, action) => {
      if (action.payload) {
        state.status = 'succeeded';
        const obj = action.payload;
        if (!(Object.keys(obj).length === 0 && obj.constructor === Object)) {
          state.accessPointName = action.payload.apName;
          state.accessPointARN = action.payload.arn;
          state.accessPointPrefix = action.payload.prefix;
          state.grantAccess = 'succeeded';
        }
      } else {
        state.error = 'Error retrieving access points';
        state.status = 'failed';
        state.grantAccess = 'failed';
      }
    },
  },
});

export const { resetS3ShareSlice } = s3ShareSlice.actions;

export default s3ShareSlice.reducer;
