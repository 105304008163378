/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { shopfrontBaseUrl } from 'config';
import axios from 'client/client';

const errorResponse = (state, action) => {
  state.status = 'failed';
  state.error = action.error.message;
};

const persistedTheme = localStorage.getItem('mode');

const initialState = {
  status: 'idle',
  primaryLightColor: '#b3b3b3',
  secondaryLightColor: '#b3b3b3',
  lightFontColor: '#000000',
  primaryDarkColor: '#b3b3b3',
  secondaryDarkColor: '#b3b3b3',
  darkFontColor: '#ffffff',
  darkThemeEnabled: persistedTheme ? JSON.parse(persistedTheme) : false,
  name: '',
  error: null,
};

export const fetchThemeByShop = createAsyncThunk(
  'theming/fetchThemeByShop',
  async (shopId) => {
    const resp = await axios.get(`${shopfrontBaseUrl}/shop-theme`, {
      params: { shopId },
    });
    return resp.data;
  },
);

export const fetchShopIcon = createAsyncThunk(
  'theming/fetchShopIcon',
  async (shopId) => {
    const resp = await axios.get(
      `${shopfrontBaseUrl}/shop-theme/get-icon-image-url`,
      {
        params: {
          iconFileName: `${shopId}-icon.png`,
        },
      },
    );
    return resp.data;
  },
);

export const fetchShopAppbarImage = createAsyncThunk(
  'theming/fetchShopAppbarImage',
  async (shopId) => {
    const resp = await axios.get(
      `${shopfrontBaseUrl}/shop-theme/get-appbar-image-url`,
      {
        params: {
          appbarFileName: `${shopId}-appbar.png`,
        },
      },
    );
    return resp.data;
  },
);

const themeSlice = createSlice({
  name: 'theming',
  initialState,
  reducers: {
    themeToggle(state, action) {
      state.darkThemeEnabled = action.payload;
      localStorage.setItem('mode', JSON.stringify(state.darkThemeEnabled));
    },
  },
  extraReducers: {
    [fetchThemeByShop.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchShopIcon.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchShopAppbarImage.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchThemeByShop.rejected]: (state, action) =>
      errorResponse(state, action),
    [fetchShopIcon.rejected]: (state, action) => errorResponse(state, action),
    [fetchShopAppbarImage.rejected]: (state, action) =>
      errorResponse(state, action),
    [fetchThemeByShop.fulfilled]: (state, action) => {
      if (action.payload) {
        const {
          primaryLightColor,
          secondaryLightColor,
          lightFontColor,
          primaryDarkColor,
          secondaryDarkColor,
          darkFontColor,
          name,
        } = action.payload;
        state.primaryLightColor = primaryLightColor;
        state.secondaryLightColor = secondaryLightColor;
        state.lightFontColor = lightFontColor;
        state.primaryDarkColor = primaryDarkColor;
        state.secondaryDarkColor = secondaryDarkColor;
        state.darkFontColor = darkFontColor;
        state.name = name;
        state.status = 'succeeded';
      } else {
        state.status = 'failed';
        state.error = 'Error retrieving Settings: received null value';
      }
    },
    [fetchShopIcon.fulfilled]: (state, action) => {
      if (action.payload) {
        state.iconImageURL = action.payload;
        state.status = 'succeeded';
      } else {
        state.status = 'failed';
        state.error = 'Error retrieving Settings: received null value';
      }
    },
    [fetchShopAppbarImage.fulfilled]: (state, action) => {
      if (action.payload) {
        state.appbarImageURL = action.payload;
        state.status = 'succeeded';
      } else {
        state.status = 'failed';
        state.error = 'Error retrieving Settings: received null value';
      }
    },
  },
});

export default themeSlice.reducer;
