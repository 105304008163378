import React, { useEffect } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  IconButton,
  InputAdornment,
  Link as StyledLink,
  TextField,
  Modal,
  Typography,
  Snackbar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Link, Redirect, useHistory } from 'react-router-dom';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';

import { shopfrontBaseUrl } from 'config';
import { useAuth } from 'auth/customer/customerAuth';
import axios from '../../../client/client';
import { selectCustomer, signInCustomer } from './customerSignInSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(0, 2, 2),
    maxWidth: '35rem',
  },
  cardHeader: {
    padding: theme.spacing(2, 2, 0, 2),
    textAlign: 'center',
  },
  form: {
    width: '80%',
  },
  actionButton: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  forgotPassword: {
    textAlign: 'center',
  },
  createAccount: {
    textAlign: 'center',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(2),
  },
  media: {
    height: '60%',
    width: '60%',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
  },
  popup: {
    maxWidth: '35rem',
    width: '80%',
  },
}));

function CustomerSignInPage() {
  const classes = useStyles();
  const [email, setEmail] = React.useState('');
  const [forgottenEmail, setForgottenEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [valid, setValid] = React.useState(true);
  const [showPassword, setShowPassword] = React.useState(false);
  const [forgotPassword, setForgotPassword] = React.useState(false);
  const [openErrorBanner, setErrorBanner] = React.useState(false);
  const [openResetSuccessBanner, setOpenResetSuccessBanner] = React.useState(
    false,
  );
  const [openSuccessBanner, setOpenSuccessBanner] = React.useState(false);
  const logo = useSelector((state) => state.theming.appbarImageURL);
  const dispatch = useDispatch();
  const customer = useSelector(selectCustomer);
  const history = useHistory();
  const location = useLocation();
  const auth = useAuth();

  useEffect(() => {
    if (location?.state?.detail === 'password reset succeeded') {
      setOpenSuccessBanner(true);
    }
  }, [location]);

  if (customer) return <Redirect to="/explore" />;

  const handleLogin = async () => {
    try {
      const response = await auth.signIn(email, password);
      if (response.data.successSignIn) {
        dispatch(signInCustomer(response.data.user));
        history.push('/explore');
      } else setValid(false);
    } catch (err) {
      setValid(false);
    }
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
  };

  const handleKeyDown = (arg) => (e) => {
    if (e.keyCode === 13) {
      if (arg === 'email') {
        const { form } = e.target;
        const index = Array.prototype.indexOf.call(form, e.target);
        form.elements[index + 1].focus();
        e.preventDefault();
      } else {
        handleLogin();
      }
    }
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    setForgotPassword(true);
  };

  const handleClose = () => {
    setOpenResetSuccessBanner(true);
    setForgotPassword(false);
  };

  const handleErrorBannerClose = () => {
    setErrorBanner(false);
  };

  const handleConfirmEmail = async (e) => {
    e.preventDefault();
    const resourcePath = `${shopfrontBaseUrl}/users/request-reset`;
    try {
      await axios.post(
        resourcePath,
        { email: forgottenEmail },
        { withCredentials: false },
      );
      handleClose();
    } catch (err) {
      if (err.response.status === 422) {
        setErrorBanner(true);
      } else {
        handleClose();
      }
    }
  };
  return (
    <>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={forgotPassword}
        onClose={handleClose}
      >
        <Card
          className={(classes.root, classes.popup)}
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <CardHeader className={classes.cardHeader} title="Forgot Password" />
          <CardContent>
            <form className={classes.form} onSubmit={handleConfirmEmail}>
              <TextField
                fullWidth
                required
                label="E-mail Address"
                value={forgottenEmail}
                onChange={(e) => setForgottenEmail(e.target.value)}
              />
            </form>
          </CardContent>
          <CardActions>
            <Grid container justify="space-between" alignItems="flex-end">
              <Button
                className={classes.actionButton}
                onClick={handleConfirmEmail}
                variant="contained"
              >
                Confirm Email
              </Button>
            </Grid>
          </CardActions>
          {openErrorBanner && (
            <Alert onClose={handleErrorBannerClose} severity="error">
              Something went wrong. Contact support or try again later.
            </Alert>
          )}
        </Card>
      </Modal>
      <Grid
        container
        spacing={0}
        direction="column"
        justify="flex-start"
        alignItems="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs={10}>
          <Card className={classes.root}>
            <Grid container justify="center" alignItems="center">
              <CardMedia
                className={classes.media}
                component="img"
                image={logo || null}
                title="Primary logo"
              />
            </Grid>
            <CardContent>
              {!valid ? (
                <Alert variant="outlined" severity="error">
                  {' '}
                  The username or password you entered did not match our
                  records. Please double-check and try again.{' '}
                </Alert>
              ) : (
                ''
              )}
              <Grid container justify="center" alignItems="center">
                <form className={classes.form}>
                  <TextField
                    fullWidth
                    required
                    label="E-mail Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyDown={handleKeyDown('email')}
                  />
                  <TextField
                    fullWidth
                    required
                    type={showPassword ? 'text' : 'password'}
                    label="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={handleKeyDown('password')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={handleMouseDown}
                          >
                            {showPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </form>
              </Grid>
            </CardContent>
            <CardActions>
              <Grid container alignItems="center" justify="center">
                <Button
                  className={classes.actionButton}
                  onClick={handleLogin}
                  variant="contained"
                >
                  Sign In
                </Button>
              </Grid>
            </CardActions>
            <Grid item xs={12} className={classes.forgotPassword}>
              <Typography>
                <StyledLink href="./" onClick={handleForgotPassword}>
                  Forgot your password?
                </StyledLink>
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.createAccount}>
              <Typography>
                Don&apos;t have an account? <br />
                <StyledLink component={Link} to="/users/sign-up">
                  Create account
                </StyledLink>
              </Typography>
            </Grid>
            <Snackbar
              open={openSuccessBanner}
              autoHideDuration={5000}
              onClose={() => setOpenSuccessBanner(false)}
            >
              <Alert
                onClose={() => setOpenSuccessBanner(false)}
                severity="success"
              >
                Your new password was saved successfully!
              </Alert>
            </Snackbar>
            <Snackbar
              open={openResetSuccessBanner}
              autoHideDuration={5000}
              onClose={() => setOpenResetSuccessBanner(false)}
            >
              <Alert
                onClose={() => setOpenResetSuccessBanner(false)}
                severity="success"
              >
                Password reset link has been sent.
              </Alert>
            </Snackbar>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default CustomerSignInPage;
