/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { shopfrontBaseUrl } from 'config';
import axios from 'client/client';

const errorResponse = (state, action) => {
  state.status = 'failed';
  state.error = action.error.message;
};

const initialState = {
  status: 'idle',
  error: null,
};

const createPaymentIntent = async (data) => {
  const resp = await axios.post(`${shopfrontBaseUrl}/payments/paymentIntent`, {
    key: data.key,
    amount: data.amount,
    currency: data.currency,
  });
  if (resp.status !== 200) {
    return null;
  }
  return resp.data;
};

export const linkPaymentMethod = async (
  stripeKey,
  paymentMethodId,
  customerId,
) => {
  // This can't be in the express backend code since DOM elements
  // cannot be passed through axios due to circular declarations
  const linkedPayment = await axios.post(
    `${shopfrontBaseUrl}/payments/paymentMethod`,
    {
      key: stripeKey,
      paymentMethodId,
      customerId,
    },
  );

  return linkedPayment.data;
};

export const getPaymentMethod = async (
  stripe,
  elements,
  paymentField,
  stripeKey,
  customer,
) => {
  if (!stripe || !elements) return [false, 'loading Error'];
  const billingDetails = {
    name: paymentField.name,
    email: paymentField.email,
    phone: paymentField.phone,
    address: {
      city: paymentField.city,
      line1: paymentField.address,
      state: paymentField.state,
      postal_code: paymentField.postal_code,
    },
  };

  if (paymentField.unit !== '') {
    billingDetails.address.line2 = paymentField.unit;
  }

  const paymentMethod = await stripe.createPaymentMethod({
    type: 'card',
    card: elements.getElement('card'),
    billing_details: billingDetails,
  });

  if (paymentMethod.error) {
    return paymentMethod;
  }

  const linkResult = await linkPaymentMethod(
    stripeKey,
    paymentMethod.paymentMethod.id,
    customer.stripeId,
  );
  return linkResult;
};

const fetchConnectedId = async (shopId) => {
  const resp = await axios.get(`${shopfrontBaseUrl}/payments`, {
    params: { shopId },
  });
  return resp.data;
};

export const createPayment = createAsyncThunk(
  'payments/payment',
  async (data, { rejectWithValue }) => {
    const connectedId = await fetchConnectedId(data.shopId);

    let paymentIntent;

    if (data.type === 'subscription') {
      const metadata = {
        customerId: data.customer.id,
        shopId: data.shopId,
        licenseId: data.id,
        subscriptionId: data.subscriptionId,
        orderId: data.orderId,
      };
      const subscriptionResponse = await axios.post(
        `${shopfrontBaseUrl}/payments/subscription`,
        {
          customerId: data.customer.stripeId,
          priceId: data.price,
          paymentMethod: data.paymentMethod[0].id,
          key: data.key,
          metadata,
        },
      );
      paymentIntent = subscriptionResponse.data;
    } else {
      paymentIntent = await createPaymentIntent({
        key: data.key,
        amount: data.amount,
        currency: data.currency,
        feeAmount: data.feeAmount,
        connectedId,
      });
    }

    if (paymentIntent === null) {
      return 'Payment could not be set up. Please try again. If the problem persists, contact support for help.';
    }

    const client =
      data.type === 'subscription'
        ? paymentIntent.latest_invoice.payment_intent.client_secret
        : paymentIntent.client_secret;

    const confirmPaymentInt = await data.stripe.confirmCardPayment(client, {
      payment_method: data.paymentMethod[0].id,
    });

    if (confirmPaymentInt.error) {
      return rejectWithValue(confirmPaymentInt.error.message);
    }

    return 'Success';
  },
);

const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {},
  extraReducers: {
    [createPaymentIntent.pending]: (state) => {
      state.status = 'loading';
    },
    [createPayment.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchConnectedId.pending]: (state) => {
      state.status = 'loading';
    },
    [createPaymentIntent.rejected]: (state, action) => {
      errorResponse(state, action);
    },
    [createPayment.rejected]: (state, action) => {
      errorResponse(state, action);
    },
    [fetchConnectedId.rejected]: (state, action) => {
      errorResponse(state, action);
    },
    [createPaymentIntent.fulfilled]: (state, action) => {
      if (action.payload) {
        state.status = 'succeeded';
      } else {
        state.status = 'failed';
        state.error = 'Error creating payment intent: received null value';
      }
    },
    [createPayment.fulfilled]: (state, action) => {
      if (action.payload) {
        state.status = 'succeeded';
      } else {
        state.status = 'failed';
        state.error = 'Error creating payment: received null value';
      }
    },
    [fetchConnectedId.fulfilled]: (state, action) => {
      if (action.payload) {
        state.status = 'succeeded';
      } else {
        state.status = 'failed';
        state.error = 'Error fetching connected id: received null value';
      }
    },
  },
});

export default paymentsSlice.reducer;
