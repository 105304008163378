/* eslint-disable no-param-reassign */
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import moment from 'moment';
import { status, type } from 'constants/order';

// import { v4 as uuidv4 } from 'uuid';
import { shopfrontBaseUrl } from 'config';
import axios from 'client/client';

const errorResponse = (state, action) => {
  state.status = 'failed';
  state.error = action.error.message;
};

const ordersAdapter = createEntityAdapter({
  selectId: (order) => order.id,
  sortComparer: null,
});

export const confirmOrder = createAsyncThunk(
  'orders/confirmOrder',
  async ({ customer, orderId, cartExists, orderTotal, currency }) => {
    const promises = [
      axios.post(`${shopfrontBaseUrl}/orders/confirm`, {
        id: orderId,
        total: orderTotal,
        currency,
      }),
    ];
    if (!cartExists) {
      const resp = axios.post(`${shopfrontBaseUrl}/users/cart`, {
        id: customer.id,
        cart: undefined,
      });
      promises.push(resp);
    }
    await Promise.all(promises);
    await axios.post(`${shopfrontBaseUrl}/orders/release-approved-data`, {
      status: 'approved',
      id: orderId,
    });
    await axios.post(`${shopfrontBaseUrl}/invoices/mark-paid`, {
      orderId,
    });
    return { orderId, orderTotal };
  },
);

export const rejectOrder = createAsyncThunk(
  'orders/rejectOrder',
  async ({ customer, orderId, cartExists, orderTotal, reason }) => {
    const promises = [
      axios.post(`${shopfrontBaseUrl}/orders/confirm`, {
        id: orderId,
        total: orderTotal,
      }),
    ];
    if (!cartExists) {
      const resp = axios.post(`${shopfrontBaseUrl}/users/cart`, {
        id: customer.id,
        cart: undefined,
      });
      promises.push(resp);
    }
    await Promise.all(promises);
    await axios.post(`${shopfrontBaseUrl}/orders/update-approval`, {
      status: 'rejected',
      id: orderId,
      reason,
    });
    return { orderId, orderTotal, reason };
  },
);

export const fetchOrdersByCustomer = createAsyncThunk(
  'orders/fetchOrdersByCustomer',
  async (customerId) => {
    const resp = await axios.get(`${shopfrontBaseUrl}/orders`, {
      params: { customerId },
    });
    return { orders: resp.data };
  },
);

const initialState = ordersAdapter.getInitialState({
  status: 'idle',
  filter: {
    type: type.all,
    status: status.completed,
    from: new Date('2019-01-01').toISOString().split('T')[0],
    to: new Date('2021-03-18').toISOString().split('T')[0],
    query: '',
    orderId: '',
    clientId: '',
    total: '',
  },
});

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    filterEdited(state, action) {
      const prop = Object.keys(action.payload)[0];
      const value = action.payload[prop];
      state.filter[prop] = value;
    },
    addOrder(state, action) {
      const products = action.payload;
      const ordersToAdd = products.map(() => ({
        id: parseInt(Math.random() * 1000000, 10),
        status: status.completed,
        type: type.historical,
        placed: moment().format('MMM D, YYYY'),
        client: 'Sample Customer',
        start: moment().format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
      }));

      state.entities = state.entities.concat(ordersToAdd);
    },
    syncOrderToCart(state, action) {
      const {
        orderId,
        cart,
        productIdToAdd,
        productIdToRemove,
      } = action.payload;
      const productIds = productIdToAdd
        ? [...cart.map((p) => p.id), productIdToAdd]
        : cart.map((p) => p.id).filter((pId) => pId !== productIdToRemove);
      ordersAdapter.updateOne(state, {
        id: orderId,
        changes: { productIds },
      });
    },
  },
  extraReducers: {
    [fetchOrdersByCustomer.pending]: (state) => {
      state.status = 'fetchLoading';
    },
    [confirmOrder.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchOrdersByCustomer.rejected]: (state, action) =>
      errorResponse(state, action),
    [confirmOrder.rejected]: (state, action) => errorResponse(state, action),
    [rejectOrder.rejected]: (state, action) => errorResponse(state, action),
    [fetchOrdersByCustomer.fulfilled]: (state, action) => {
      if (action.payload) {
        const { orders } = action.payload;
        ordersAdapter.setAll(state, orders);
        state.status = 'succeeded';
      } else {
        state.status = 'failed';
        state.error = 'Error retrieving licenses: received null value';
      }
    },
    [confirmOrder.fulfilled]: (state, action) => {
      const { orderId } = action.payload;
      const { orderTotal } = action.payload;
      ordersAdapter.updateOne(state, {
        id: orderId,
        changes: { status: 'approved', total: orderTotal },
      });
      state.status = 'succeeded';
    },
    [rejectOrder.fulfilled]: (state, action) => {
      const { orderId } = action.payload;
      const { orderTotal } = action.payload;
      const { reason } = action.payload;
      ordersAdapter.updateOne(state, {
        id: orderId,
        changes: { status: 'rejected', total: orderTotal, reason },
      });
      state.status = 'succeeded';
    },
  },
});

export const {
  selectAll: selectAllOrders,
  selectById: selectOrderById,
  selectIds: selectOrderIds,
} = ordersAdapter.getSelectors((state) => state.orders);

export const { filterEdited, addOrder, syncOrderToCart } = ordersSlice.actions;

export const selectOrderFilter = (state) => state.orders.filter;

export default ordersSlice.reducer;
