import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { LinearProgress, Grid, Typography, Snackbar } from '@material-ui/core';
import { Pagination, Alert } from '@material-ui/lab';
import { selectShopId } from 'pages/shopfront/CustomerSignInPage/customerSignInSlice';
import {
  fetchSubscriptions,
  selectAllSubscriptions,
} from '../LibraryItemDetailPage/subscriptionsSlice';
import {
  fetchLicenses,
  selectAllLicenses,
} from '../FetchStoreInfo/licensesSlice';
import { Subscription } from '../../../features/subscription/SubscriptionManagement';
import { selectCustomer } from '../CustomerSignInPage/customerSignInSlice';

const useStyles = makeStyles((theme) => ({
  loading: {
    width: '50%',
    margin: theme.spacing(10, 0, 10),
  },
  title: {
    padding: theme.spacing(2, 0),
    color: theme.palette.text.primary,
  },
}));

function SubscriptionsPage() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const customer = useSelector(selectCustomer);
  const shopId = useSelector(selectShopId);
  const subscriptionsStatus = useSelector(
    (state) => state.subscriptions.status,
  );
  const licenseStatus = useSelector((state) => state.licenses.status);
  const licenses = useSelector(selectAllLicenses);
  const currency = useSelector((state) => state.licenses.currency);
  const subscriptions = useSelector(selectAllSubscriptions).filter(
    (s) => s.customerId === customer.id,
  );
  const subscriptionsError = useSelector((state) => state.subscriptions.error);

  const [page, setPage] = React.useState(1);
  const [rowsPerPage] = React.useState(3);
  const [snackbar, setSnackbar] = React.useState(false);
  const pages = Math.ceil(subscriptions.length / rowsPerPage);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    if (subscriptionsStatus === 'idle')
      dispatch(fetchSubscriptions(customer.id));
    if (licenseStatus === 'idle') dispatch(fetchLicenses({ shopId }));
    if (subscriptionsStatus === 'failed') {
      setSnackbar(true);
    }
  }, [subscriptionsStatus, licenseStatus, shopId, customer, dispatch]);

  const handleSnackbarClose = () => {
    setSnackbar(false);
  };

  const SnackBarAlert = () => (
    <Snackbar
      open={snackbar}
      autoHideDuration={5000}
      onClose={handleSnackbarClose}
    >
      <Alert onClose={handleSnackbarClose} severity="error">
        {subscriptionsError}
      </Alert>
    </Snackbar>
  );

  const SubscriptionsList = () => {
    if (subscriptionsStatus === 'loading') {
      return (
        <Grid container direction="column" justify="center" alignItems="center">
          <LinearProgress className={classes.loading} />
        </Grid>
      );
    }
    if (subscriptionsStatus === 'succeeded') {
      return subscriptions
        .slice((page - 1) * rowsPerPage, page * rowsPerPage)
        .map((subscription) => (
          <Subscription
            key={subscription.id}
            license={licenses.find((lic) => lic.id === subscription.licenseId)}
            subscription={subscription}
            currency={currency}
          />
        ));
    }
    return null;
  };

  return (
    <>
      <SnackBarAlert />
      <Typography className={classes.title} variant="h3">
        Subscriptions
      </Typography>
      <SubscriptionsList />
      {licenses.length > 0 && (
        <Pagination
          count={pages}
          page={page}
          onChange={handlePageChange}
          showFirstButton
          showLastButton
        />
      )}
    </>
  );
}

export default SubscriptionsPage;
