import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';

import {
  LinearProgress,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  Button,
  Tab,
  Tabs,
  Snackbar,
} from '@material-ui/core';
import { Pagination, Alert } from '@material-ui/lab';
import { Search as SearchIcon } from '@material-ui/icons';
import { Link, Redirect } from 'react-router-dom';
import { selectProductById } from 'pages/shopfront/FetchStoreInfo/productsSlice';
import { selectShopId } from 'pages/shopfront/CustomerSignInPage/customerSignInSlice';
import ReactGA from 'react-ga4';
import { selectCustomer } from '../CustomerSignInPage/customerSignInSlice';
import {
  fetchSubscriptions,
  selectAllSubscriptions,
} from './subscriptionsSlice';
import {
  fetchLicenses,
  selectAllLicenses,
  selectFilter,
  filterEdited,
  fetchCurrency,
} from '../FetchStoreInfo/licensesSlice';
import { Subscription } from '../../../features/subscription/Subscription';
import { selectAllFileDeliveries } from './fileDeliveriesSlice';
import { selectLibraryItemById } from '../LibraryPage/libraryItemsSlice';
import { resetS3ShareSlice } from '../FetchStoreInfo/s3ShareSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  text: {
    color: theme.palette.text.primary,
  },
  actionButton: {
    margin: theme.spacing(2, 1),
  },
  backButton: {
    margin: theme.spacing(2, 0),
  },
  formControl: {
    flexGrow: 1,
  },
  searchBar: {
    margin: theme.spacing(0, 0, 1),
    padding: theme.spacing(0, 1, 0, 0),
  },
}));

function LibraryItemDetailPage() {
  const libraryItemId = new URLSearchParams(window.location.search).get('id');

  const [tab, setTab] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage] = React.useState(3);
  const [snackbar, setSnackbar] = React.useState(false);

  const dispatch = useDispatch();
  const classes = useStyles();
  const customer = useSelector(selectCustomer);
  const filter = useSelector(selectFilter);
  let shopId = useSelector(selectShopId);
  const currency = useSelector((state) => state.licenses.currency);
  const customerShopId = useSelector((state) => state.customer.shopId);
  if (!shopId) {
    shopId = customerShopId;
  }
  const subscriptionsStatus = useSelector(
    (state) => state.subscriptions.status,
  );
  const subscriptionsError = useSelector((state) => state.subscriptions.error);

  const licenseStatus = useSelector((state) => state.licenses.status);

  useEffect(() => {
    if (!customer || !shopId) return;
    if (subscriptionsStatus === 'idle')
      dispatch(fetchSubscriptions(customer.id));
    if (subscriptionsStatus === 'idle')
      dispatch(fetchSubscriptions(customer.id));
    if (licenseStatus === 'idle') dispatch(fetchLicenses({ shopId }));
    if (subscriptionsStatus === 'failed') {
      setSnackbar(true);
    }
    dispatch(fetchCurrency({ shopId }));
  }, [licenseStatus, subscriptionsStatus, dispatch, shopId, customer]);

  useEffect(() => () => dispatch(resetS3ShareSlice()));

  const subscriptions = useSelector(selectAllSubscriptions).filter(
    (s) => s.libraryItemId === libraryItemId,
  );
  const fileDeliveries = useSelector(selectAllFileDeliveries).filter(
    (fd) => fd.libraryItemId === libraryItemId,
  );

  const licenseIds = (tab === 0 || tab === 1
    ? subscriptions.map((s) => s.licenseId)
    : []
  ).concat(
    (tab === 0 || tab === 2) && fileDeliveries.map((fd) => fd.licenseId),
  );

  const GAevent = (category, action, label) => {
    ReactGA.event({ category, action, label });
  };

  const licenses = useSelector(selectAllLicenses)
    .filter((license) => licenseIds.includes(license.id))
    .filter((license) =>
      filter.query
        ? license.name.toUpperCase().includes(filter.query.toUpperCase())
        : true,
    )
    .sort((a, b) => {
      switch (filter.sortParam) {
        case 0:
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        case 1:
          if (a.name < b.name) return 1;
          if (a.name > b.name) return -1;
          return 0;
        case 2:
          if (a.price < b.price) return -1;
          if (a.price > b.price) return 1;
          return 0;
        case 3:
          if (a.price < b.price) return 1;
          if (a.price > b.price) return -1;
          return 0;
        default:
          return 0;
      }
    });

  const associatedLibraryItem = useSelector((state) =>
    selectLibraryItemById(state, libraryItemId),
  );
  const associatedProduct = useSelector((state) =>
    selectProductById(state, associatedLibraryItem?.productId),
  );

  if (!shopId) return <Redirect to="/users/sign-in" />;

  const pages = Math.ceil(licenses.length / rowsPerPage);

  const setFilter = (payload) => {
    dispatch(filterEdited(payload));
  };
  const handleTabChange = (e, value) => {
    setTab(value);
    setPage(1);
  };
  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleSnackbarClose = () => {
    setSnackbar(false);
  };

  const SnackBarAlert = () => (
    <Snackbar
      open={snackbar}
      autoHideDuration={5000}
      onClose={handleSnackbarClose}
    >
      <Alert onClose={handleSnackbarClose} severity="error">
        {subscriptionsError}
      </Alert>
    </Snackbar>
  );

  const SortBySelect = () => (
    <FormControl fullWidth>
      <InputLabel id="sort-by-select-label">Sort By:</InputLabel>
      <Select
        labelId="sort-by-select-label"
        test-attribute="sort-by-select"
        value={filter.sortParam}
        onChange={(e) => {
          setFilter({ sortParam: e.target.value });

          GAevent(
            'engagement',
            `library_sort_by_${filter.sortParam}`,
            'Sort library by name',
          );
        }}
      >
        <MenuItem value={0}>License Name (A-Z)</MenuItem>
        <MenuItem value={1}>License Name (Z-A)</MenuItem>
        <MenuItem value={2}>Price (Lowest-Highest)</MenuItem>
        <MenuItem value={3}>Price (Highest-Lowest)</MenuItem>
      </Select>
    </FormControl>
  );

  const SubscriptionsList = () => {
    if (subscriptionsStatus === 'loading') {
      return (
        <Grid container direction="column" justify="center" alignItems="center">
          <LinearProgress className={classes.loading} />
        </Grid>
      );
    }
    if (subscriptionsStatus === 'succeeded') {
      return licenses
        .slice((page - 1) * rowsPerPage, page * rowsPerPage)
        .map((license) => (
          <Subscription
            key={license.id}
            license={license}
            subscription={subscriptions.find(
              (subscription) => subscription.licenseId === license.id,
            )}
            currency={currency}
          />
        ));
    }
    return null;
  };
  return (
    <>
      <SnackBarAlert />
      <Grid container>
        <Grid item xs>
          {associatedProduct && (
            <>
              <Typography className={classes.text} variant="h3">
                {associatedProduct.name}
              </Typography>
              <Typography className={classes.text} variant="body1">
                {associatedProduct.description}
              </Typography>
            </>
          )}
          <Grid item container>
            <Grid item xs>
              <TextField
                id="search"
                label="Search"
                fullWidth
                className={classes.searchBar}
                value={filter.query}
                onChange={(e) => setFilter({ query: e.target.value })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <SortBySelect />
            </Grid>
          </Grid>
          <Grid container justify="space-between" alignItems="flex-end">
            <Typography className={classes.text} variant="caption">
              {licenses.length} result(s).
            </Typography>
          </Grid>
          <Tabs indicatorColor="primary" value={tab} onChange={handleTabChange}>
            <Tab className={classes.text} label="All" />
            <Tab className={classes.text} label="Subscriptions" />
            <Tab className={classes.text} label="File Deliveries" />
          </Tabs>
          <SubscriptionsList />
        </Grid>
      </Grid>
      {licenses.length > 0 && (
        <Pagination
          count={pages}
          page={page}
          onChange={handleChange}
          showFirstButton
          showLastButton
        />
      )}
      <Button
        className={classes.backButton}
        component={Link}
        to="./library"
        variant="contained"
      >
        Back to Library
      </Button>
    </>
  );
}

export default LibraryItemDetailPage;
