/* eslint-disable default-case, no-param-reassign */

import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { shopfrontBaseUrl } from 'config';
import axios from 'client/client';

const errorResponse = (state, action) => {
  state.status = 'failed';
  state.error = action.error.message;
};

const fileDeliveriesAdapter = createEntityAdapter({
  selectId: (fileDelivery) => fileDelivery.id,
  sortComparer: null,
});

export const fetchFileDeliveries = createAsyncThunk(
  'fileDeliveries/fetchFileDeliveries',
  async (customerId) => {
    const resp = await axios.get(`${shopfrontBaseUrl}/file-deliveries`, {
      params: { customerId },
    });
    return { fileDeliveries: resp.data };
  },
);

const fileDeliveriesSlice = createSlice({
  name: 'fileDeliveries',
  extraReducers: {
    [fetchFileDeliveries.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchFileDeliveries.rejected]: (state, action) =>
      errorResponse(state, action),
    [fetchFileDeliveries.fulfilled]: (state, action) => {
      if (action.payload) {
        const { fileDeliveries } = action.payload;
        fileDeliveriesAdapter.setAll(state, fileDeliveries);
        state.status = 'succeeded';
      } else {
        state.error = 'Error retrieving fileDeliveries: received null value';
        state.status = 'failed';
      }
    },
  },
});

export const {
  selectAll: selectAllFileDeliveries,
  selectById: selectFileDeliveryById,
  selectIds: selectFileDeliveryIds,
} = fileDeliveriesAdapter.getSelectors((state) => state.fileDeliveries);

export default fileDeliveriesSlice.reducer;
