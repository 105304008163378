/* eslint-disable default-case, no-param-reassign */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { shopfrontBaseUrl } from 'config';
import axios from '../../../client/client';

const errorResponse = (state, action) => {
  state.status = 'failed';
  state.error = action.error.message;
};

const initialState = {
  status: 'idle',
  signedIn: false,
  shopId: '',
  cart: '',
  awsIdstatus: 'idle',
};

export const fetchCustomer = createAsyncThunk(
  'manage/fetchCustomer',
  async () => {
    const resp = await axios.get(`${shopfrontBaseUrl}/users`);
    return resp.data;
  },
);

export const fetchShop = createAsyncThunk(
  'manage/fetchShop',
  async (shopId) => {
    const resp = await axios.get(`${shopfrontBaseUrl}/shops`, {
      params: { shopId },
    });
    return resp.data;
  },
);

export const saveAwsId = createAsyncThunk(
  'users/aws-userId',
  async (params) => {
    const resp = await axios.post(
      `${shopfrontBaseUrl}/users/aws-userId`,
      params,
    );
    return resp.data;
  },
);

const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    signInCustomer(state, action) {
      state.status = 'succeeded';
      state.signedIn = true;
      state.shopId = action.payload.shopId;
      state.customer = { ...action.payload };
    },
    signOutCusomter(state) {
      state.status = 'succeeded';
      state.signedIn = false;
      state.shopId = '';
      state.customer = undefined;
    },
    clearCustomerCart(state) {
      state.cart = undefined;
      state.customer.cart = undefined;
    },
    updateCustomerCart(state, action) {
      state.cart = action.payload;
      state.customer.cart = action.payload;
    },
    changeAwsIdStatus(state) {
      state.awsIdstatus = 'idle';
    },
  },
  extraReducers: {
    [fetchCustomer.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchShop.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchCustomer.rejected]: (state, action) => errorResponse(state, action),
    [fetchShop.rejected]: (state, action) => errorResponse(state, action),
    [fetchShop.fulfilled]: (state, action) => {
      if (action.payload) {
        state.status = 'succeeded';
        state.shopInfo = action.payload;
      } else {
        state.status = 'failed';
        state.error = 'Error retrieving shop: received null value';
      }
    },
    [fetchCustomer.fulfilled]: (state, action) => {
      if (action.payload) {
        state.status = 'succeeded';
        state.signedIn = true;
        state.customer = { ...action.payload };
        state.shopId = action.payload.shopId;
        state.cart = action.payload.cart;
      } else {
        state.status = 'failed';
        state.signedIn = false;
        state.error = 'Error retrieving user: received null value';
      }
    },
    [saveAwsId.pending]: (state) => {
      state.awsIdstatus = 'pending';
    },
    [saveAwsId.rejected]: (state, action) => {
      state.awsIdstatus = 'failed';
      state.error = action.error.message;
    },
    [saveAwsId.fulfilled]: (state, action) => {
      if (action.payload) {
        state.customer.awsUserId = action.payload;
        state.awsIdstatus = 'succeeded';
      } else {
        state.error = 'Error saving AWS ID';
        state.awsIdstatus = 'failed';
      }
    },
  },
});

export const selectCustomer = (state) => state.customer.customer;

export const selectShopId = (state) => state.customer.shopId;

export const {
  signInCustomer,
  signOutCusomter,
  clearCustomerCart,
  updateCustomerCart,
  changeAwsIdStatus,
} = customerSlice.actions;

export default customerSlice.reducer;
