/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import update from 'immutability-helper';

const initialState = {
  selectedTags: {},
};

const exploreSlice = createSlice({
  name: 'explore',
  initialState,
  reducers: {
    toggleTag(state, { payload: { attribute, tag } }) {
      if (state.selectedTags[attribute] && state.selectedTags[attribute][tag]) {
        delete state.selectedTags[attribute][tag];

        const residualTags = state.selectedTags[attribute];
        if (
          Object.keys(residualTags).length === 0 &&
          residualTags.constructor === Object
        ) {
          delete state.selectedTags[attribute];
        }
      } else {
        state.selectedTags[attribute] = update(
          state.selectedTags[attribute] || {},
          {
            [tag]: {
              $set: true,
            },
          },
        );
      }
    },
  },
});

export const { toggleTag } = exploreSlice.actions;

export const selectSelectedTags = (state) => state.explore.selectedTags;

export default exploreSlice.reducer;
