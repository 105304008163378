import React, { useEffect } from 'react';
import { useHistory, Redirect, Link } from 'react-router-dom';
import {
  Grid,
  Button,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
  CircularProgress,
  LinearProgress,
  InputAdornment,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  IconButton,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Edit as EditIcon, Search as SearchIcon } from '@material-ui/icons';

import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchOrdersByCustomer,
  selectAllOrders,
  syncOrderToCart,
} from 'pages/OrdersPage/ordersSlice';
import { fetchOrderItems } from 'pages/OrdersPage/orderItemsSlice';
import { selectShopId } from 'pages/shopfront/CustomerSignInPage/customerSignInSlice';
import ReactGA from 'react-ga4';
import { Product } from '../../../features/product/customerProduct';
import {
  selectAllProducts,
  fetchProducts,
} from '../FetchStoreInfo/productsSlice';

import { selectSelectedTags, toggleTag } from './exploreSlice';

import {
  selectCart,
  addProductToCart,
  populateCart,
  makeNewCart,
  addItemToCart,
  selectCartOrderItems,
  fetchCartOrderItems,
  syncDraftToBackend,
} from '../CartPage/cartSlice';

import {
  fetchCustomer,
  selectCustomer,
  updateCustomerCart,
} from '../CustomerSignInPage/customerSignInSlice';

import {
  selectAllAttributes,
  fetchAttributes,
} from '../FetchStoreInfo/attributesSlice';

import { fetchCurrency, fetchLicenses } from '../FetchStoreInfo/licensesSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    '& .MuiFormControlLabel-label': {
      color: theme.palette.text.primary,
    },
  },
  tagLegend: {},
  checkbox: {
    padding: theme.spacing(0.5, 1),
  },
  loading: {
    width: '50%',
    margin: theme.spacing(10, 0, 10),
  },
  search: {
    margin: theme.spacing(0, 0, 1),
    padding: theme.spacing(0, 1, 0, 0),
  },
  card: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2, 2, 2),
  },
  alertAction: {
    display: 'block',
  },
  li: {
    display: 'flex',
    alignItems: 'center',
  },
  alert: {
    margin: theme.spacing(0, 0, 1, 0),
  },
  grid: {
    color: theme.palette.text.primary,
  },
}));

function ExplorePage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const cart = useSelector(selectCart);
  const selectedTags = useSelector(selectSelectedTags);
  const history = useHistory();
  const attributes = useSelector(selectAllAttributes);

  const [addDisabled, setAddDisabled] = React.useState(false);
  const [searchField, setSearchField] = React.useState('');
  const [sortParam, setSortParam] = React.useState(0);
  const [dialog, setDialog] = React.useState(false);
  const [dialogOrder, setDialogOrder] = React.useState({});
  const [snackbar, setSnackbar] = React.useState(false);
  const [showRejectedOrdersAlert, setShowRejectedOrdersAlert] = React.useState(
    false,
  );

  const customer = useSelector(selectCustomer);
  const shopId = useSelector(selectShopId);
  const currency = useSelector((state) => state.licenses.currency);

  const isPublished = (product) => product.status === 'Published';
  const isSelected = (product) => {
    if (Object.keys(selectedTags).length === 0) {
      return true;
    }
    if (!product || !product.tags || product.tags.length === 0) {
      return false;
    }
    const test = (tag) => (selectedTags[tag.attribute] || {})[tag.tag];

    return product.tags.filter(test).length > 0;
  };

  const attributeStatus = useSelector((state) => state.attributes.status);
  const attributeError = useSelector((state) => state.attributes.error);

  const productsStatus = useSelector((state) => state.products.status);
  const productsError = useSelector((state) => state.products.error);

  const licenseStatus = useSelector((state) => state.licenses.status);
  const orderStatus = useSelector((state) => state.orders.status);
  const orderItemStatus = useSelector((state) => state.orderItems.status);
  const cartStatus = useSelector((state) => state.cart.status);
  const cartOrderItemsStatus = useSelector(
    (state) => state.cart.orderItemsStatus,
  );

  const orderItems = useSelector(selectCartOrderItems).filter(
    (curItem) => !curItem.deleted,
  );

  useEffect(() => {
    if (!shopId) return;
    dispatch(fetchCurrency({ shopId }));
    if (productsStatus === 'idle') dispatch(fetchProducts({ shopId }));
    if (attributeStatus === 'idle') dispatch(fetchAttributes({ shopId }));
    if (licenseStatus === 'idle') dispatch(fetchLicenses({ shopId }));
    if (!customer) return;
    if (orderStatus === 'idle') dispatch(fetchOrdersByCustomer(customer.id));
    if (orderItemStatus === 'idle') dispatch(fetchOrderItems(customer.id));
    if (cartOrderItemsStatus === 'idle')
      dispatch(fetchCartOrderItems({ cartId: customer.cart }));
  }, [
    productsStatus,
    licenseStatus,
    attributeStatus,
    orderStatus,
    orderItemStatus,
    cartStatus,
    dispatch,
    shopId,
    customer,
    cartOrderItemsStatus,
  ]);
  useEffect(
    () => () => {
      dispatch(syncDraftToBackend());
    },
    [dispatch],
  );

  const orders = useSelector(selectAllOrders);
  const rejectedOrders = orders.filter((order) => order.status === 'rejected');
  const currentCart = orders.find((order) => order.status === 'cart');
  const allProducts = useSelector(selectAllProducts);

  useEffect(() => {
    if (rejectedOrders.length > 0) {
      setShowRejectedOrdersAlert(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!customer) return;
    const cartOrder = orders.find((order) => order.id === customer.cart);
    if (
      cartStatus === 'idle' &&
      productsStatus === 'succeeded' &&
      orderStatus === 'succeeded' &&
      customer &&
      cartOrder
    ) {
      dispatch(
        populateCart({
          customer,
          products: allProducts,
          order: cartOrder,
        }),
      );
    }
    if (productsStatus === 'failed') {
      setSnackbar(true);
    }
  }, [
    cartStatus,
    productsStatus,
    orderStatus,
    customer,
    orders,
    allProducts,
    dispatch,
  ]);

  const GAevent = (category, action, label) => {
    ReactGA.event({ category, action, label });
  };

  const products = allProducts
    .filter(isPublished)
    .filter(isSelected)
    .filter((product) => {
      // query product name, description, or tags
      const tagData = product.tags.map((tag) => {
        const parentAttribute = attributes.find(
          (attribute) => attribute.id === tag.attribute,
        );
        return parentAttribute
          ? parentAttribute.tags.find((x) => x.key === tag.tag).label
          : '';
      });
      return searchField.length > 0
        ? product.name.toLowerCase().includes(searchField.toLowerCase()) ||
            product.description
              .toLowerCase()
              .includes(searchField.toLowerCase()) ||
            tagData.findIndex((x) =>
              x.toLowerCase().includes(searchField.toLowerCase()),
            ) !== -1
        : true;
    })
    .sort((a, b) => {
      if (sortParam === 0) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }
      if (sortParam === 1) {
        if (a.name < b.name) return 1;
        if (a.name > b.name) return -1;
        return 0;
      }
      return 0;
    });

  const handleAddToCart = async (product) => {
    setAddDisabled(true);
    let makeNew = false;
    if (orders.findIndex((order) => order.id === customer.cart) === -1)
      makeNew = true;
    let cartOrderId = customer.cart;
    if (makeNew) cartOrderId = (await dispatch(makeNewCart(customer))).payload;
    await dispatch(addProductToCart({ cartOrderId, product }));
    if (makeNew) {
      dispatch(fetchCustomer());
      dispatch(fetchOrdersByCustomer(customer.id));
    } else {
      dispatch(
        syncOrderToCart({
          orderId: cartOrderId,
          productIdToAdd: product.id,
          cart,
        }),
      );
    }
    setAddDisabled(false);

    GAevent('engagement', 'add_to_cart', {
      currency: 'CAD', // default
      items: [
        {
          item_id: product.id,
          item_name: product.name,
        },
      ],
    });

    return cartOrderId;
  };

  const addLicenseToCart = (
    licenseId,
    licenseDelivery,
    licensePrice,
    cartOrderIdOverride = null,
  ) => {
    const exists =
      orderItems.findIndex(
        (curOrderItem) => curOrderItem.licenseId === licenseId,
      ) !== -1;
    if (!exists) {
      const customerCopy = cartOrderIdOverride
        ? { ...customer, cart: cartOrderIdOverride }
        : { ...customer };
      dispatch(
        addItemToCart({
          customer: customerCopy,
          licenseId,
          licenseDelivery,
          licensePrice,
        }),
      );
    }
  };

  if (!shopId) return <Redirect to="/users/sign-in" />;

  const handleToggleTag = (attribute, tag) => () => {
    dispatch(toggleTag({ attribute: attribute.id, tag: tag.key }));
  };

  const handleViewCart = () => {
    GAevent('engagement', 'view_cart', {
      currency: 'CAD', // default
      items: cart.map((product) => ({
        item_id: product.id,
        item_name: product.name,
      })),
      value: 1,
    });

    history.push('/cart');
  };

  const substituteCart = () => {
    dispatch(updateCustomerCart(dialogOrder.id));
    dispatch(
      populateCart({
        customer: { ...customer, cart: dialogOrder.id },
        order: dialogOrder,
        products: allProducts,
      }),
    );
    setDialog(false);
    setDialogOrder({});
  };

  const backToCurrentCart = () => {
    dispatch(updateCustomerCart(currentCart.id));
    dispatch(
      populateCart({
        customer: { ...customer, cart: currentCart.id },
        order: currentCart,
        products: allProducts,
      }),
    );
  };

  const handleOpenDialog = (order) => {
    setDialog(true);
    setDialogOrder(order);
  };

  const handleCloseDialog = () => {
    setDialog(false);
    setDialogOrder({});
  };

  const handleSnackbarClose = () => {
    setSnackbar(false);
  };

  const SnackBarAlert = () => (
    <Snackbar
      open={snackbar}
      autoHideDuration={5000}
      onClose={handleSnackbarClose}
    >
      <Alert onClose={handleSnackbarClose} severity="error">
        {productsError}
      </Alert>
    </Snackbar>
  );

  const ProductList = () => {
    if (productsStatus === 'loading') {
      return (
        <Grid container direction="column" justify="center" alignItems="center">
          <LinearProgress className={classes.loading} />
        </Grid>
      );
    }
    if (productsStatus === 'succeeded') {
      return (
        <div>
          {products.map((product) => (
            <Product
              addDisabled={addDisabled}
              key={product.id}
              entity={product}
              added={cart.findIndex((p) => p.id === product.id) !== -1}
              onAddToCart={() => handleAddToCart(product)}
              addLicenseToCart={addLicenseToCart}
              currency={currency}
            />
          ))}
        </div>
      );
    }
    return null;
  };

  const AttributeCheckBoxes = ({ attribute }) => (
    <Box my={2} test-attribute={`${attribute.id}-box`}>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel className={classes.tagLegend} component="legend">
          {attribute.name}
        </FormLabel>
        {attribute.tags.map((tag) => {
          const checked =
            selectedTags[attribute.id] && selectedTags[attribute.id][tag.key];
          return (
            <FormGroup key={tag.key}>
              <FormControlLabel
                className={classes.root}
                control={
                  <Checkbox
                    className={classes.checkbox}
                    name={`${attribute.id}_${tag.key}`}
                    test-attribute={`${attribute.id}_${tag.key}-checkbox`}
                    checked={checked}
                    onChange={handleToggleTag(attribute, tag)}
                  />
                }
                label={tag.label}
              />
            </FormGroup>
          );
        })}
      </FormControl>
    </Box>
  );

  const AttributesList = () => {
    let content;
    if (attributeStatus === 'idle') {
      content = (
        <Grid container direction="column" justify="center" alignItems="center">
          <CircularProgress />
        </Grid>
      );
    } else if (attributeStatus === 'succeeded') {
      content = (
        <div>
          {attributes.map((attribute) => (
            <AttributeCheckBoxes key={attribute.id} attribute={attribute} />
          ))}
        </div>
      );
    } else {
      content = <p>{attributeError}</p>;
    }
    return content;
  };

  const SortBySelect = () => (
    <FormControl fullWidth>
      <InputLabel id="sort-by-select-label">Sort By:</InputLabel>
      <Select
        labelId="sort-by-select-label"
        test-attribute="sort-by-select"
        value={sortParam}
        onChange={(e) => {
          setSortParam(e.target.value);

          GAevent(
            'engagement',
            `products_sort_by_${sortParam}`,
            'Sort products by name',
          );
        }}
      >
        <MenuItem value={0}>Product Name (A-Z)</MenuItem>
        <MenuItem value={1}>Product Name (Z-A)</MenuItem>
      </Select>
    </FormControl>
  );
  const RejectedOrdersAlert = () => {
    if (showRejectedOrdersAlert) {
      return (
        <Alert
          classes={{
            action: classes.alertAction,
          }}
          className={classes.alert}
          severity="info"
          action={
            <>
              <Button component={Link} to="/order-history">
                Review Orders
              </Button>
              <Button onClick={() => setShowRejectedOrdersAlert(false)}>
                Dismiss
              </Button>
            </>
          }
        >
          <AlertTitle>
            {rejectedOrders.length > 1
              ? 'You Have Rejected Orders:'
              : 'You Have a Rejected Order:'}
          </AlertTitle>
          {rejectedOrders.map((order) => (
            <li className={classes.li} key={order.id}>
              Order #{order.id.substring(0, 5)}
              {order.id === customer.cart ? (
                <Chip label="Editing" size="small" />
              ) : (
                <IconButton
                  size="small"
                  onClick={() => handleOpenDialog(order)}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              )}
            </li>
          ))}
        </Alert>
      );
    }
    return null;
  };

  const UnfinishedCartAlert = () => (
    <Alert
      classes={{
        action: classes.alertAction,
      }}
      className={classes.alert}
      severity="warning"
      action={<Button onClick={backToCurrentCart}>Back to Current Cart</Button>}
    >
      You Have an Unfinished Cart
    </Alert>
  );

  return (
    <Grid container>
      <SnackBarAlert />
      <Grid item xs={3}>
        <AttributesList />
      </Grid>
      <Grid item xs>
        <Grid item container>
          <Grid item xs>
            <TextField
              label="Search"
              value={searchField}
              test-attribute="search"
              fullWidth
              className={classes.search}
              onChange={(e) => setSearchField(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <SortBySelect />
          </Grid>
        </Grid>
        {currentCart && customer && currentCart.id !== customer.cart && (
          <UnfinishedCartAlert />
        )}
        {rejectedOrders.length > 0 && <RejectedOrdersAlert />}
        <Dialog open={dialog} onClose={handleCloseDialog}>
          <DialogTitle>Confirm Edit Order</DialogTitle>
          <DialogContent>
            Clicking Confirm will save the current cart as a draft and the cart
            will be populated with items from the order you are trying to edit.
            <p>NOTE: If your cart is empty, ignore this message.</p>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleCloseDialog}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={substituteCart}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Grid container item justify="space-between">
          <Grid
            className={classes.grid}
            item
            test-attribute="products-matching-criteria-text"
          >
            {products.length} of {allProducts.filter(isPublished).length} total
            products for selected criteria
          </Grid>
          <Grid item>
            <Button
              color="primary"
              className={classes.shopPrimary}
              data-testid="ProceedToCartBtn"
              test-attribute="proceed-to-cart-button"
              variant="contained"
              onClick={() => handleViewCart()}
            >
              Proceed to Cart
            </Button>
          </Grid>
        </Grid>
        <ProductList />
      </Grid>
    </Grid>
  );
}
export default ExplorePage;
