/* eslint-disable no-param-reassign */
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { shopfrontBaseUrl } from 'config';
import axios from 'client/client';

const errorResponse = (state, action) => {
  state.status = 'failed';
  state.error = action.error.message;
};

const orderItemsAdapter = createEntityAdapter({
  selectId: (orderItem) => orderItem.id,
  sortComparer: null,
});

const orderItemDraft = {
  orderId: '',
  licenseId: '',
  customerId: '',
  from: '',
  to: '',
  delivery: [],
};

export const createOrderItem = createAsyncThunk(
  'orderItems/createOrderItems',
  async ({
    customer,
    licenseId,
    licenseDelivery,
    licensePrice,
    id,
    filters,
  }) => {
    const orderItem = { ...orderItemDraft };
    orderItem.id = id || uuidv4().toUpperCase(); // function input may or may not have id; if no id is sent in, generate one
    orderItem.orderId = customer.cart;
    orderItem.customerId = customer.id;
    orderItem.licenseId = licenseId;
    orderItem.delivery = licenseDelivery;
    orderItem.price = licensePrice;
    if (filters) orderItem.filters = filters;
    const resp = await axios.post(`${shopfrontBaseUrl}/order-items`, orderItem);
    return resp.data;
  },
);

export const fetchOrderItems = createAsyncThunk(
  'orderItems/fetchOrderItems',
  async (customerId) => {
    const resp = await axios.get(`${shopfrontBaseUrl}/order-items/customer`, {
      params: { customerId },
    });
    return { orderItems: resp.data };
  },
);

export const deleteOrderItem = createAsyncThunk(
  'licenses/deleteOrderItem',
  async (id) => {
    if (!id) return null;
    await axios.delete(`${shopfrontBaseUrl}/order-items`, {
      params: {
        id,
      },
    });
    return id;
  },
);

export const changeDelivery = createAsyncThunk(
  'licenses/changeDelivery',
  async ({ deliveryMethod, id }) => {
    if (!id) return null;
    const resp = await axios.post(
      `${shopfrontBaseUrl}/order-items/change-delivery`,
      {
        deliveryMethod,
        id,
      },
    );
    return resp.data;
  },
);

export const updateOrderItem = createAsyncThunk(
  'orderItems/updateOrderItem',
  async (newOrderItem) => {
    const newOrderItemNoFlags = { ...newOrderItem };
    if (Object.prototype.hasOwnProperty.call(newOrderItemNoFlags, 'edited'))
      delete newOrderItemNoFlags.edited;
    if (Object.prototype.hasOwnProperty.call(newOrderItemNoFlags, 'created'))
      delete newOrderItemNoFlags.created;
    if (Object.prototype.hasOwnProperty.call(newOrderItemNoFlags, 'deleted'))
      delete newOrderItemNoFlags.deleted;
    await axios.patch(`${shopfrontBaseUrl}/order-items/`, {
      newOrderItem: newOrderItemNoFlags,
    });
    return newOrderItemNoFlags;
  },
);

const initialState = orderItemsAdapter.getInitialState({
  status: 'idle',
  error: null,
});

const orderItemsSlice = createSlice({
  name: 'orderItems',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchOrderItems.pending]: (state) => {
      state.status = 'fetchLoading';
    },
    [createOrderItem.pending]: (state) => {
      state.status = 'loading';
    },
    [deleteOrderItem.pending]: (state) => {
      state.status = 'loading';
    },
    [changeDelivery.pending]: (state) => {
      state.status = 'loading';
    },
    [updateOrderItem.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchOrderItems.rejected]: (state, action) => errorResponse(state, action),
    [createOrderItem.rejected]: (state, action) => errorResponse(state, action),
    [deleteOrderItem.rejected]: (state, action) => errorResponse(state, action),
    [changeDelivery.rejected]: (state, action) => errorResponse(state, action),
    [updateOrderItem.rejected]: (state, action) => errorResponse(state, action),
    [fetchOrderItems.fulfilled]: (state, action) => {
      if (action.payload) {
        const { orderItems } = action.payload;
        orderItemsAdapter.setAll(state, orderItems);
        state.status = 'succeeded';
      } else {
        state.status = 'failed';
        state.error = 'Error retrieving orderItems: received null value';
      }
    },
    [createOrderItem.fulfilled]: (state, action) => {
      const orderItem = action.payload;
      orderItemsAdapter.upsertOne(state, orderItem);
      state.status = 'succeeded';
    },
    [deleteOrderItem.fulfilled]: (state, action) => {
      const id = action.payload;
      orderItemsAdapter.removeOne(state, id);
      state.status = 'succeeded';
    },
    [changeDelivery.fulfilled]: (state, action) => {
      orderItemsAdapter.updateOne(state, {
        id: action.payload.id,
        changes: { ...action.payload },
      });
      state.status = 'succeeded';
    },
    [updateOrderItem.fulfilled]: (state, action) => {
      orderItemsAdapter.updateOne(state, {
        id: action.payload.id,
        changes: { ...action.payload },
      });
      state.status = 'succeeded';
    },
  },
});

export const {
  selectAll: selectAllOrderItems,
  selectById: selectOrderItemById,
  selectIds: selectOrderItemIds,
} = orderItemsAdapter.getSelectors((state) => state.orderItems);

export default orderItemsSlice.reducer;
