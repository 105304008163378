export const type = {
  historical: 0,
  subscription: 1,
  all: 2,
};

export const status = {
  pending: 'submitted',
  completed: 'completed',
  error: 'error',
};
