/* eslint-disable default-case, no-param-reassign */

import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { shopfrontBaseUrl } from 'config';
import axios from '../../../client/client';

const errorResponse = (state, action) => {
  state.status = 'failed';
  state.error = action.error.message;
};

const layoutFooterAdapter = createEntityAdapter({
  selectId: (footerCol) => footerCol.id,
  sortComparer: (a, b) => a.order - b.order,
});

const initialState = layoutFooterAdapter.getInitialState({
  status: 'idle',
  error: null,
  highestOrder: 0,
});

export const fetchLayoutFooter = createAsyncThunk(
  'layout/footer/fetchLayoutFooter',
  async ({ shopId }) => {
    const { data } = await axios.get(`${shopfrontBaseUrl}/layout/footer`, {
      params: { shopId },
    });
    return data;
  },
);

const findHighestOrder = (allFooterInfo) => {
  const orders = allFooterInfo.map((curHeader) => curHeader.order);
  return Math.max(...orders);
};

const layoutFooterSlice = createSlice({
  name: 'layout/footer',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchLayoutFooter.pending]: (state) => {
      state.status = 'loading';
    },

    [fetchLayoutFooter.rejected]: (state, action) =>
      errorResponse(state, action),
    [fetchLayoutFooter.fulfilled]: (state, action) => {
      if (action.payload) {
        const footerInfo = action.payload.sort((a, b) => a.order - b.order);
        state.status = 'succeeded';
        state.highestOrder =
          footerInfo.length > 0 ? findHighestOrder(footerInfo) : -1;
        layoutFooterAdapter.setAll(state, footerInfo);
      } else {
        state.status = 'failed';
        state.error = 'Error retrieving layout/footer: received null value';
      }
    },
  },
});

export const {
  selectAll: selectAllLayoutFooter,
  selectById: selectLayoutFooterById,
  selectIds: selectLayoutFooterIds,
} = layoutFooterAdapter.getSelectors((state) => state.layoutFooter);

export default layoutFooterSlice.reducer;
