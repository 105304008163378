import React from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Link as StyledLink,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { shopfrontBaseUrl } from 'config';
import { v4 as uuidv4 } from 'uuid';

import axios from '../../../client/client';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(0, 2, 2),
    maxWidth: '35rem',
  },
  cardHeader: {
    padding: theme.spacing(2, 2, 0, 2),
  },
  form: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  actionButton: {
    margin: theme.spacing(1),
  },
}));

function SignUpPage() {
  const classes = useStyles();
  const [companyName, setCompanyName] = React.useState('');
  const [name, setName] = React.useState('');
  const [surname, setSurname] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [shopId, setShopId] = React.useState('');
  // const [password, setPassword] = React.useState("");
  // const [showPassword, setShowPassword] = React.useState(false);

  const handleSignUp = async () => {
    const resourcePath = `${shopfrontBaseUrl}/users/sign-up`;
    await axios.post(resourcePath, {
      id: uuidv4().toUpperCase(),
      firstName: name,
      lastName: surname,
      email,
      shopId,
      companyName,
    });
  };

  // const handleMouseDown = (e) => {
  //   e.preventDefault();
  // };

  const handleKeyDown = (arg) => (e) => {
    if (e.keyCode === 13) {
      if (arg === 'email') {
        const { form } = e.target;
        const index = Array.prototype.indexOf.call(form, e.target);
        form.elements[index + 1].focus();
        e.preventDefault();
      } else {
        handleSignUp();
      }
    }
  };

  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        justify="flex-start"
        alignItems="center"
        style={{ minHeight: '100vh' }}
      >
        <Card className={classes.root}>
          <CardHeader className={classes.cardHeader} title="Sign Up" />
          <CardContent>
            <form>
              <Grid container justify="space-between" className={classes.form}>
                <TextField
                  required
                  label="First Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <TextField
                  required
                  label="Last Name"
                  value={surname}
                  onChange={(e) => setSurname(e.target.value)}
                />
                <TextField
                  fullWidth
                  required
                  label="Company Name"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  onKeyDown={handleKeyDown('companyName')}
                />
                <TextField
                  fullWidth
                  required
                  label="E-mail Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onKeyDown={handleKeyDown('email')}
                />
                <TextField
                  fullWidth
                  required
                  label="Shop ID"
                  value={shopId}
                  onChange={(e) => setShopId(e.target.value)}
                  onKeyDown={handleKeyDown('shopId')}
                />
                {/* <TextField
                  fullWidth
                  required
                  type={showPassword ? "text" : "password"}
                  label="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={handleKeyDown("password")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={(e) => setShowPassword(!showPassword)}
                          onMouseDown={handleMouseDown}
                        >
                          {showPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                /> */}
              </Grid>
            </form>
          </CardContent>
          <CardActions>
            <Grid container justify="space-between" alignItems="flex-end">
              <Button
                className={classes.actionButton}
                onClick={handleSignUp}
                variant="contained"
              >
                Sign Up
              </Button>
              <StyledLink component={Link} to="/users/sign-in">
                Already have an account? Sign In
              </StyledLink>
            </Grid>
          </CardActions>
        </Card>
      </Grid>
    </>
  );
}

export default SignUpPage;
