import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Box,
  TextField,
  IconButton,
  Typography,
  Card,
  Chip,
  CardHeader,
  CardContent,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
} from '@material-ui/core';
import { injectIntl, FormattedMessage } from 'react-intl';
import messages from 'app/message';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '@material-ui/lab';
import ComputerIcon from '@material-ui/icons/Computer';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CloudQueueIcon from '@material-ui/icons/CloudQueue';
import { unsubscribe } from '../../pages/shopfront/LibraryItemDetailPage/subscriptionsSlice';
import { FileDeliveryTable } from '../fileDeliveryTable/FileDeliveryTable';
import { selectCustomer } from '../../pages/shopfront/CustomerSignInPage/customerSignInSlice';
import { getAccessPoint } from '../../pages/shopfront/FetchStoreInfo/s3ShareSlice';
import { selectAllDataSets } from '../../pages/shopfront/FetchStoreInfo/dataSetsSlice';
import { S3ShareModal } from './s3ShareModal';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(0, 2, 2),
  },
  edit: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.short,
    }),
  },
  chip: {
    margin: theme.spacing(2, 1),
  },
  card: {
    padding: theme.spacing(2, 2, 0),
  },
  content: {
    padding: theme.spacing(0, 2, 0),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  licenseTitle: {
    margin: theme.spacing(1, 0, 0),
  },
  fileDeliveriesTitle: {
    margin: theme.spacing(2, 0, 0),
  },
  license: {
    margin: theme.spacing(1, 1, 0, 0),
  },
  select: {
    margin: theme.spacing(0, 1, 0, 1),
  },
  form: {
    '& > *': {
      padding: theme.spacing(1, 0),
    },
  },
  warning: {
    color: 'red',
  },
  popover: {
    height: '200px',
  },
}));

export const Subscription = injectIntl(
  ({ license, subscription, currency = 'usd' }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [dialog, setDialog] = React.useState(false);
    const [snackbar, setSnackbar] = React.useState(false);
    const [popUp, setPopUp] = React.useState(false);

    const handleCancel = () => {
      dispatch(unsubscribe(subscription.id));
      setDialog(false);
      setSnackbar(true);
    };

    const handleCancelDialog = () => {
      setDialog(false);
    };

    const handleSnackbarClose = () => {
      setSnackbar(false);
    };

    const handleClick = () => {
      setPopUp(true);
    };

    const handleClosePopUp = () => {
      setPopUp(false);
    };

    const CustomDialog = () => (
      <Dialog open={dialog} onClose={handleCancelDialog}>
        <DialogTitle>
          Are you sure you want to cancel your subscription?
        </DialogTitle>
        <DialogContent>
          Clicking <strong>Unsucscribe</strong> will deactivate your
          subscription to <strong>{license.name} </strong> and remove your
          acccess to the product for the upcoming billing cycle. Your
          subsciption may be renewed later.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDialog}>Cancel</Button>
          <Button variant="contained" color="secondary" onClick={handleCancel}>
            Unsubscribe
          </Button>
        </DialogActions>
      </Dialog>
    );

    const SnackBarAlert = () => (
      <Snackbar
        open={snackbar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity="success">
          Successfully unsubscribed from <strong>{license.name}</strong>!
        </Alert>
      </Snackbar>
    );
    const ViewContent = () => {
      const [s3Modal, setS3Modal] = React.useState(false);
      const [openError, setOpenError] = React.useState(false);
      const customer = useSelector(selectCustomer);
      const datasetObj = useSelector(selectAllDataSets).find(
        (dataset) => dataset.productId === license.productId,
      );

      const handleS3Share = async () => {
        const params = {
          customerId: customer.id,
          datasetId: datasetObj.id,
          licenseId: license.id,
        };
        dispatch(getAccessPoint(params));
      };

      const s3AccessStatus = useSelector((state) => state.s3Share.status);

      useEffect(() => {
        if (s3AccessStatus === 'succeeded') {
          setS3Modal(true);
        } else if (s3AccessStatus === 'failed') {
          setOpenError(true);
        }
      }, [s3AccessStatus]);

      return (
        <>
          <CardHeader
            className={classes.card}
            action={
              <>
                {subscription && !subscription.isActive && (
                  <Chip
                    color="secondary"
                    className={classes.chip}
                    label="Inactive"
                  />
                )}
                <Chip
                  color="default"
                  className={classes.chip}
                  label={
                    license.billingFrequency === 'once'
                      ? 'One-Time'
                      : 'Subscription'
                  }
                />
              </>
            }
            title={license.name}
          />
          <CardContent className={classes.content}>
            <Typography variant="body2" color="textSecondary" component="p">
              {license.description}
            </Typography>
            <Typography className={classes.licenseTitle} variant="h6">
              <FormattedMessage {...messages.LABEL_PRICING} />
            </Typography>
            <div className={classes.flex}>
              <Typography
                className={classes.license}
                component="span"
                variant="body2"
              >
                {license.price.toString()}{' '}
                <FormattedMessage
                  {...messages[`CURRENCY_${currency.toUpperCase()}`]}
                />{' '}
                (
                <FormattedMessage
                  {...messages[`BILLING_FREQUENCY_${license.billingFrequency}`]}
                />
                )
              </Typography>
              {license.delivery.includes('download') && (
                <Typography
                  className={classes.fileDeliveriesTitle}
                  variant="h6"
                >
                  <FormattedMessage {...messages.LABEL_FILE_DELIVERIES} />
                </Typography>
              )}
            </div>
            {license.delivery.includes('download') && (
              <div className={classes.root}>
                <FileDeliveryTable customerId={customer.id} license={license} />
              </div>
            )}
          </CardContent>
          <CardActions className={classes.actions}>
            {subscription &&
              subscription.isActive &&
              license.billingFrequency !== 'once' && (
                <>
                  <Button onClick={() => setDialog(true)}>Unsubscribe</Button>
                  <Button
                    className={classes.edit}
                    variant="contained"
                    color="primary"
                  >
                    Query
                  </Button>
                </>
              )}
            {subscription &&
              !subscription.isActive &&
              license.billingFrequency !== 'once' && (
                <Button
                  className={classes.edit}
                  variant="contained"
                  color="primary"
                >
                  Renew
                </Button>
              )}
            {license.billingFrequency === 'once' &&
              license.delivery.includes('api') && (
                <div>
                  <Button className={classes.button} onClick={handleClick}>
                    <ComputerIcon />
                    API URL
                  </Button>
                  <Dialog
                    open={popUp}
                    onClose={handleClosePopUp}
                    className={classes.popover}
                  >
                    <Box p={3}>
                      <TextField
                        className={classes.textField}
                        disabled
                        value="curl https://5u8m8qp2j8.execute-api.us-east-1.amazonaws.com/dev/data/\?productId\=1384B0D3-0079-41D9-8597-0D\[%E2%80%A6\]074DA\&startDate\=2019-01-01\&endDate\=2019-01-15"
                      ></TextField>
                      <IconButton aria-label="Clipboard">
                        <AssignmentIcon />
                      </IconButton>
                      <Typography variant="body2">
                        Manage your API settings in Delivery Preferences.
                      </Typography>
                    </Box>
                  </Dialog>
                </div>
              )}
            {license.delivery.includes('aws') && (
              <div>
                <Snackbar
                  open={openError}
                  autoHideDuration={5000}
                  onClose={() => setOpenError(false)}
                >
                  <Alert onClose={() => setOpenError(false)} severity="error">
                    An error occurred while trying to retrieve information.
                    Please try again later!
                  </Alert>
                </Snackbar>
                <S3ShareModal
                  s3Modal={s3Modal}
                  setS3Modal={setS3Modal}
                  license={license}
                  datasetId={datasetObj.id}
                />
                <Button className={classes.button} onClick={handleS3Share}>
                  <CloudQueueIcon />
                  S3 SHARE
                </Button>
              </div>
            )}
          </CardActions>
        </>
      );
    };

    return (
      <>
        <SnackBarAlert />
        <Card className={classes.root}>
          <ViewContent />
          {dialog && <CustomDialog />}
        </Card>
      </>
    );
  },
);
