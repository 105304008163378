import React, { useState, useEffect, useContext, createContext } from 'react';
import { shopfrontBaseUrl } from 'config';
import { useDispatch } from 'react-redux';
import {
  fetchCustomer,
  fetchShop,
} from 'pages/shopfront/CustomerSignInPage/customerSignInSlice';
import axios from 'client/client';
import { fetchProducts } from 'pages/shopfront/FetchStoreInfo/productsSlice';
import { fetchAttributes } from 'pages/shopfront/FetchStoreInfo/attributesSlice';
import { fetchLicenses } from 'pages/shopfront/FetchStoreInfo/licensesSlice';
import { fetchOrdersByCustomer } from 'pages/OrdersPage/ordersSlice';
import { fetchOrderItems } from 'pages/OrdersPage/orderItemsSlice';
import { fetchLayoutFooter } from 'pages/shopfront/FetchStoreInfo/layoutFooterSlice';
import { fetchApiKey } from 'pages/shopfront/FetchStoreInfo/apiKeysSlice';
import { fetchGID } from 'pages/shopfront/FetchStoreInfo/analyticsSlice';
import { fetchInvoices } from 'pages/shopfront/PaymentsPage/paymentsSlice';
import { fetchDataSets } from 'pages/shopfront/FetchStoreInfo/dataSetsSlice';
import {
  fetchSftpHostName,
  fetchSftpPublicKey,
  fetchSftpUserName,
} from 'pages/shopfront/FetchStoreInfo/sftpSlice';
import {
  fetchThemeByShop,
  fetchShopIcon,
  fetchShopAppbarImage,
} from 'pages/shopfront/FetchStoreInfo/themeSlice';
const authContext = createContext();

// Reference: https://usehooks.com/useAuth/

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => useContext(authContext);

function useProvideAuth() {
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [fetchStatus, setFetchStatus] = useState('idle');
  const signIn = async (email, password) => {
    const resourcePath = `${shopfrontBaseUrl}/users/sign-in`;
    const response = await axios.post(resourcePath, { email, password });
    if (response.data.successSignIn) {
      setUser(response.data.user);
      setLoading(false);
    }
    return response;
  };

  const signOut = () => {
    setUser(null);
    setLoading(true);
    setFetchStatus('idle');
  };

  const fetchShopIdFromHost = async () => {
    const resourcePath = `${shopfrontBaseUrl}/shop-theme/get-host-shop-id`;
    const { host } = window.location;
    const response = await axios.get(resourcePath, {
      params: {
        host,
      },
    });
    dispatch(fetchThemeByShop(response.data));
    dispatch(fetchShopIcon(response.data));
    dispatch(fetchShopAppbarImage(response.data));
    return response;
  };

  const fetchItems = async () => {
    if (fetchStatus !== 'pending') {
      setFetchStatus('pending');
      // might want to add items like the library-items,
      // subscriptions, and etc to the fetch list
      await Promise.all([
        dispatch(fetchProducts({ shopId: user.shopId })),
        dispatch(fetchShop(user.shopId)),
        dispatch(fetchAttributes({ shopId: user.shopId })),
        dispatch(fetchLicenses({ shopId: user.shopId })),
        dispatch(fetchOrdersByCustomer(user.id)),
        dispatch(fetchOrderItems(user.id)),
        dispatch(fetchLayoutFooter({ shopId: user.shopId })),
        dispatch(fetchApiKey({ entityId: user.id })),
        dispatch(fetchGID(user.shopId)),
        dispatch(fetchInvoices({ customerId: user.id })),
        dispatch(fetchDataSets(user.shopId)),
        dispatch(fetchSftpHostName(user.id)),
        dispatch(fetchSftpUserName(user.id)),
        dispatch(fetchSftpPublicKey(user.id)),
      ]);
      setFetchStatus('succeeded');
    }
  };

  useEffect(() => {
    fetchShopIdFromHost();
    if (user && fetchStatus === 'idle') {
      fetchItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, fetchStatus]);

  useEffect(() => {
    dispatch(fetchCustomer()).then((resp) => {
      if (resp.payload) {
        setUser(resp.payload);
      }
      setLoading(false);
    });
  }, [dispatch]);

  return {
    user,
    fetchStatus,
    loading,
    fetchItems,
    fetchShopIdFromHost,
    signIn,
    signOut,
  };
}
