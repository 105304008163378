import React from 'react';
import {
  Typography,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Grid,
  Box,
  Button,
  Snackbar,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { useSelector, useDispatch } from 'react-redux';

import { Link, useHistory } from 'react-router-dom';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { stripeKey } from 'config';
import { clearCart } from '../CartPage/cartSlice';

import { confirmOrder, rejectOrder } from '../../OrdersPage/ordersSlice';
import {
  clearCustomerCart,
  selectCustomer,
} from '../CustomerSignInPage/customerSignInSlice';
import { createPayment, getPaymentMethod } from './paymentSlice';

const stripeLightCardOptions = {
  style: {
    base: {
      color: '#000000',
      fontFamily: '-apple-system, BlinkMacSystemFont',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
  hidePostalCode: true,
};

const stripeDarkCardOptions = {
  style: {
    base: {
      color: '#fff',
      fontFamily: '-apple-system, BlinkMacSystemFont',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
  hidePostalCode: true,
};

function PaymentForm({
  classes,
  cart,
  currentOrder,
  cartOrderId,
  associatedLicenses,
  currency,
}) {
  const [checkAgreement, setCheckAgreement] = React.useState(false);
  const [showPaymentError, setShowPaymentError] = React.useState(false);
  const [paymentField, setPaymentField] = React.useState({});
  const [errorMessage, setErrorMessage] = React.useState('');
  const [emailErrorMessage, setEmailErrorMessage] = React.useState('');
  const [phoneErrorMessage, setPhoneErrorMessage] = React.useState('');
  const [confirmingOrder, setIsOrderConfirming] = React.useState(false);
  const customer = useSelector(selectCustomer);
  const darkThemeEnabled = useSelector(
    (state) => state.theming.darkThemeEnabled,
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();

  const subscriptionLicenses = associatedLicenses.filter(
    (item) => item.billingFrequency === 'monthly',
  );

  const priceReducer = (accumulator, currentPrice) =>
    parseFloat(accumulator) + parseFloat(currentPrice);

  const handleErrorMessageClose = () => {
    setShowPaymentError(false);
  };

  const handleOneTimePayment = async (event, total, paymentMethod) => {
    if (!stripe || !elements) return [false, 'loading Error'];

    const orderTotal = getPaymentTotal(total);
    const applicationFeePercentage = 0.25;
    const feeAmount = applicationFeePercentage * orderTotal;
    const amount = orderTotal - feeAmount;

    const paymentResult = await dispatch(
      createPayment({
        key: stripeKey,
        amount,
        currency,
        feeAmount,
        shopId: customer.shopId,
        card: elements.getElement('card'),
        paymentMethod,
        stripe,
        type: 'one-time',
      }),
    );

    return paymentResult.type.includes('fulfilled')
      ? [true, paymentResult.payload]
      : [false, paymentResult.payload];
  };

  const handleSubscription = async (event, paymentMethod, orderId) => {
    event.preventDefault();
    setShowPaymentError(false);
    if (!stripe || !elements) return [false, 'loading Error'];

    subscriptionLicenses.forEach((subscription) => {
      const orderTotal = getPaymentTotal(subscription.price);
      const applicationFeePercentage = 0.25;
      const feeAmount = applicationFeePercentage * orderTotal;
      const amount = orderTotal - feeAmount;
      dispatch(
        createPayment({
          key: stripeKey,
          amount,
          currency,
          feeAmount,
          shopId: customer.shopId,
          paymentMethod,
          stripe,
          customer,
          type: 'subscription',
          price: subscription.stripePriceId,
          id: subscription.id,
          orderId,
        }),
      );
    });
    return [true, null];
  };

  // const GAevent = (category, action, label) => {
  //   ReactGA.event({ category, action, label });
  // };

  const getOrderTotal = () => {
    let orderTotal = 0;
    const prices = associatedLicenses.map((item) => item.price);
    if (prices.length > 0) {
      orderTotal = parseFloat(prices.reduce(priceReducer));
    }
    return orderTotal.toFixed(2);
  };

  // Returns the order value in terms of one time payments and subscription payments
  const separateOrderValue = () => {
    let oneTimePayment = 0;
    let subscriptionPayment = 0;
    associatedLicenses.forEach((item) => {
      if (item.billingFrequency === 'once') {
        oneTimePayment += parseFloat(item.price);
      } else {
        subscriptionPayment += parseFloat(item.price);
      }
    });

    return [oneTimePayment.toFixed(2), subscriptionPayment.toFixed(2)];
  };

  // function to convert string to integer and convert dollars to cents
  const getPaymentTotal = (total) => {
    const orderTotal = parseFloat(total, 2);
    return orderTotal * 100;
  };

  // Order Section
  const handleOrderRequest = async (event) => {
    event.preventDefault();
    setPhoneErrorMessage('');
    setEmailErrorMessage('');
    // GAevent('engagement', 'purchase', {
    //   currency: 'CAD',
    //   items: cart.map((product) => ({
    //     item_id: product.id,
    //     item_name: product.name,
    //   })),
    // });

    // Disable confirming button to avoid spam and inform users
    setIsOrderConfirming(true);
    const [oneTimeTotal] = separateOrderValue();

    // Do not let the payment through if card is invalid.
    if (!paymentField.phone.match(/^\d{10}$/)) {
      setPhoneErrorMessage('Phone Number must be in form xxxxxxxxxx');
      setIsOrderConfirming(false);
      return;
    }

    const paymentMethod = await getPaymentMethod(
      stripe,
      elements,
      paymentField,
      stripeKey,
      customer,
    );
    if (paymentMethod.error) {
      const paymentMessage = paymentMethod.error.message;
      setErrorMessage(paymentMessage);
      setEmailErrorMessage(
        paymentMessage.includes('email') ? 'Email is incorrect' : '',
      );
      await dispatch(
        rejectOrder({
          customer,
          orderId: cartOrderId,
          cartExists: true,
          reason: paymentMessage,
        }),
      );
      setIsOrderConfirming(false);
      return;
    }
    if (oneTimeTotal !== '0.00') {
      await handleOneTimePayment(event, oneTimeTotal, paymentMethod);
    }
    await handleSubscription(event, paymentMethod, cartOrderId);
    await dispatch(
      confirmOrder({
        customer,
        orderId: cartOrderId,
        currency,
        orderTotal: getOrderTotal(),
        cartExists: currentOrder.status === 'rejected',
      }),
    );
    dispatch(clearCustomerCart());
    dispatch(clearCart());
    // If failure, put snack bar up and do not push history
    history.push(`/order-successful?id=${cartOrderId}`);
  };

  const handleClose = () => {
    setErrorMessage('');
  };

  return (
    <div>
      <form onSubmit={handleOrderRequest}>
        <div>
          <FormGroup className={classes.paymentInfo}>
            <Box my={2}>
              <Typography className={classes.text} variant="h6">
                Payment Information
              </Typography>
            </Box>
            <TextField
              required
              className={classes.inputField}
              label="Name"
              aria-label="Name"
              defaultValue={paymentField.name}
              onChange={(e) => {
                setPaymentField({ ...paymentField, name: e.target.value });
              }}
            />
            <TextField
              required
              className={classes.inputField}
              label="Email"
              aria-label="Email"
              type="email"
              defaultValue={paymentField.email}
              error={emailErrorMessage !== ''}
              helperText={emailErrorMessage}
              onChange={(e) => {
                setPaymentField({ ...paymentField, email: e.target.value });
              }}
            />
            <TextField
              required
              className={classes.inputField}
              label="Phone"
              aria-label="Phone"
              defaultValue={paymentField.phone}
              error={phoneErrorMessage !== ''}
              helperText={phoneErrorMessage}
              onChange={(e) => {
                setPaymentField({ ...paymentField, phone: e.target.value });
              }}
            />
            <TextField
              required
              className={classes.inputField}
              label="Address"
              aria-label="Address"
              defaultValue={paymentField.address}
              onChange={(e) => {
                setPaymentField({ ...paymentField, address: e.target.value });
              }}
            />
            <TextField
              className={classes.inputField}
              label="Unit"
              aria-label="Unit"
              defaultValue={paymentField.unit}
              onChange={(e) => {
                setPaymentField({ ...paymentField, unit: e.target.value });
              }}
            />
            <TextField
              required
              className={classes.inputField}
              label="City"
              aria-label="City"
              defaultValue={paymentField.city}
              onChange={(e) => {
                setPaymentField({ ...paymentField, city: e.target.value });
              }}
            />
            <TextField
              required
              className={classes.inputField}
              label="State"
              aria-label="State"
              defaultValue={paymentField.state}
              onChange={(e) => {
                setPaymentField({ ...paymentField, state: e.target.value });
              }}
            />
            <TextField
              required
              className={classes.inputField}
              label="Postal Code"
              aria-label="Postal Code"
              defaultValue={paymentField.postal}
              onChange={(e) => {
                setPaymentField({
                  ...paymentField,
                  postal_code: e.target.value,
                });
              }}
            />
            {darkThemeEnabled ? (
              <CardElement options={stripeDarkCardOptions} />
            ) : (
              <CardElement options={stripeLightCardOptions} />
            )}
            <Snackbar
              open={showPaymentError}
              message={errorMessage}
              autoHideDuration={5000}
              onClose={handleErrorMessageClose}
            ></Snackbar>
          </FormGroup>
          <FormGroup row className={classes.agreementForm}>
            <FormControlLabel
              className={classes.root}
              control={
                <Checkbox
                  color="default"
                  name="checkedAgreement"
                  onChange={(e) => setCheckAgreement(e.target.checked)}
                  checked={checkAgreement}
                />
              }
              label="I agree to the above terms of service"
            />
          </FormGroup>
        </div>
        <Grid container item xs justify="flex-end">
          <Typography className={classes.text} variant="h6">
            Order Total: ${getOrderTotal()}
          </Typography>
        </Grid>
        <Grid container item xs justify="flex-end">
          <Typography className={classes.text} variant="body2">
            {cart.length} products
          </Typography>
        </Grid>
        <Grid container item xs justify="flex-end">
          <Typography className={classes.text} variant="body2">
            {' '}
            {associatedLicenses.length} items selected
          </Typography>
        </Grid>
        <Grid container item justify="flex-end">
          <Button component={Link} to="/cart" className={classes.actionButton}>
            Change Order
          </Button>
          <Button
            className={classes.shopPrimary}
            color="secondary"
            variant="contained"
            disabled={!checkAgreement || confirmingOrder}
            type="submit"
          >
            {confirmingOrder ? 'Confirming' : 'Place Order'}
          </Button>
        </Grid>
      </form>
      <Snackbar
        open={errorMessage !== ''}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          Rejection Reason:<br></br>
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default PaymentForm;
