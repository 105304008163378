import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
  DataGrid,
  GridToolbarContainer,
  GridColumnsToolbarButton,
  GridFilterToolbarButton,
} from '@material-ui/data-grid';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GetApp as DownloadIcon } from '@material-ui/icons';
import { shopfrontBaseUrl } from 'config';
import fileDownload from 'js-file-download';
import axios from '../../client/client';
import { selectAllFileDeliveries } from './fileDeliveriesSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0),
  },
  button: {
    justifyContent: 'flex-end',
  },
}));

export const FileDeliveryTable = injectIntl(({ customerId, license }) => {
  const [selectionModel, setSelectionModel] = React.useState([]);
  const classes = useStyles();

  useEffect(() => {
    async function updateFileDeliveries() {
      await axios
        .put(`${shopfrontBaseUrl}/file-deliveries/update-delivery-file-size`, {
          params: {
            customerId,
            licenseId: license.id,
          },
        })
        .catch(() => {});
    }
    updateFileDeliveries();
  }, [customerId, license.id]);

  const fileDeliveries = useSelector(selectAllFileDeliveries).filter(
    (fd) => fd.licenseId === license.id,
  );

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
  };

  const handleDownload = async () => {
    const data = await axios
      .put(`${shopfrontBaseUrl}/library-items/download`, {
        params: {
          customerId,
          licenseId: license.id,
          sequenceNumber: -1,
        },
      })
      .catch(() => {});
    if (data !== undefined) {
      fileDownload(
        JSON.stringify(data.data.downloadResult, null),
        `${license.name}.json`,
      );
    }
  };

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridColumnsToolbarButton />
      <GridFilterToolbarButton />
    </GridToolbarContainer>
  );

  const columns = [
    { field: 'date', headerName: 'Date', width: 200 },
    { field: 'fileName', headerName: 'File name', width: 200 },
    { field: 'description', headerName: 'Description', width: 280 },
    {
      field: 'fileSize',
      headerName: 'File Size',
      width: 200,
    },
  ];

  const fileDeliveryRows = fileDeliveries.map((fd) => {
    const date = new Date(fd.deliveryAttemptedAt || fd.updatedAt);
    const dateString = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;
    return {
      id: fd.id,
      date: dateString,
      fileName: license.name,
      description: license.description,
      fileSize: formatBytes(fd.fileSize || 0),
    };
  });

  return (
    <div>
      <div style={{ height: 300, width: '100%' }}>
        <DataGrid
          rowHeight={35}
          rowsPerPageOptions={[5, 10, 25]}
          rows={fileDeliveryRows}
          columns={columns}
          pageSize={5}
          components={{
            Toolbar: CustomToolbar,
          }}
          checkboxSelection
          onSelectionModelChange={(newSelection) => {
            setSelectionModel(newSelection.selectionModel);
          }}
          selectionModel={selectionModel}
        />
      </div>
      {selectionModel.length > 0 && (
        <div className={classes.root}>
          <Button className={classes.button} onClick={() => handleDownload()}>
            <DownloadIcon /> Download {selectionModel.length} Files
          </Button>
        </div>
      )}
    </div>
  );
});
