import React from 'react';
import {
  Card,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputAdornment,
  Radio,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  HelpOutline as HelpOutlineIcon,
  Search as SearchIcon,
} from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import ReactGA from 'react-ga4';
import OrderList from '../../features/order/OrderList';
import { filterEdited } from './ordersSlice';
import { status, type } from '../../constants/order';
import { selectCustomer } from '../shopfront/CustomerSignInPage/customerSignInSlice';

const useStyles = makeStyles((theme) => ({
  title: {
    padding: theme.spacing(2, 0),
    color: theme.palette.text.primary,
  },
  radio: {
    '&$checked': {},
  },
  checked: {},
  card: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2),
  },
  status: {
    flexGrow: 1,
  },
  form: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  text: {
    color: theme.palette.text.primary,
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function OrdersPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const customerInfo = useSelector(selectCustomer);

  const filter = useSelector((state) => state.orders.filter);
  const [tab, setTab] = React.useState(0);

  const handleTabChange = (event, value) => {
    setTab(value);
  };

  const GAevent = (category, action, label) => {
    ReactGA.event({ category, action, label });
  };

  const setFilter = (payload) => {
    GAevent('engagement', `filter_orders`, {
      type: filter.type,
      status: filter.status,
    });

    dispatch(filterEdited(payload));
  };

  return (
    <>
      <Typography className={classes.title} variant="h3">
        Orders <HelpOutlineIcon fontSize="large" />
      </Typography>
      <Tabs indicatorColor="primary" value={tab} onChange={handleTabChange}>
        <Tab className={classes.text} label="All" {...a11yProps(0)} />
        <Tab className={classes.text} label="Pending" {...a11yProps(1)} />
        <Tab className={classes.text} label="Approved" {...a11yProps(2)} />
        <Tab className={classes.text} label="Completed" {...a11yProps(3)} />
        <Tab className={classes.text} label="Rejected" {...a11yProps(4)} />
      </Tabs>
      {tab === 0 && (
        <Card className={classes.card}>
          <form className={classes.form}>
            <Grid container direction="row">
              <Grid item xs={3}>
                <FormControl className={classes.status} component="fieldset">
                  <FormLabel component="legend">Order Type</FormLabel>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Radio
                          classes={{
                            root: classes.radio,
                            checked: classes.checked,
                          }}
                          checked={filter.type === type.all}
                          onChange={() =>
                            setFilter({
                              type: type.all,
                            })
                          }
                        />
                      }
                      label="All"
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          classes={{
                            root: classes.radio,
                            checked: classes.checked,
                          }}
                          checked={filter.type === type.historical}
                          onChange={(e) =>
                            setFilter({
                              type: e.target.checked
                                ? type.historical
                                : type.subscription,
                            })
                          }
                        />
                      }
                      label="Historical"
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          classes={{
                            root: classes.radio,
                            checked: classes.checked,
                          }}
                          checked={filter.type === type.subscription}
                          onChange={(e) =>
                            setFilter({
                              type: e.target.checked
                                ? type.subscription
                                : type.historical,
                            })
                          }
                          name="sub"
                        />
                      }
                      label="Subscription"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl className={classes.status} component="fieldset">
                  <FormLabel component="legend">Order Status</FormLabel>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Radio
                          classes={{
                            root: classes.radio,
                            checked: classes.checked,
                          }}
                          checked={filter.status === status.pending}
                          onChange={(e) =>
                            setFilter({
                              status: e.target.checked
                                ? status.pending
                                : status.completed,
                            })
                          }
                          name="pending"
                        />
                      }
                      label="Pending"
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          classes={{
                            root: classes.radio,
                            checked: classes.checked,
                          }}
                          checked={filter.status === status.completed}
                          onChange={(e) =>
                            setFilter({
                              status: e.target.checked
                                ? status.completed
                                : status.pending,
                            })
                          }
                          name="completed"
                        />
                      }
                      label="Completed"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="search"
                  label="Search"
                  value={filter.query}
                  onChange={(e) => setFilter({ query: e.target.value })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  className="classes.text"
                  id="dateFrom"
                  label="From"
                  type="date"
                  value={filter.from}
                  onChange={(e) => setFilter({ from: e.target.value })}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="dateTo"
                  label="To"
                  type="date"
                  value={filter.to}
                  onChange={(e) => setFilter({ to: e.target.value })}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="orderId"
                  label="Order #"
                  value={filter.orderId}
                  onChange={(e) => setFilter({ orderId: e.target.value })}
                />
              </Grid>
            </Grid>
          </form>
        </Card>
      )}
      <OrderList tab={tab} customerInfo={customerInfo} />
    </>
  );
}

export default OrdersPage;
