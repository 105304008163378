/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { jamlabsGID, shopfrontBaseUrl } from 'config';

import ReactGA from 'react-ga4';
import axios from '../../../client/client';

const initialState = {
  googleTrackingId: '',
  googleTrackingIdstatus: 'idle',
  googleTrackingIderror: '',
  mixPanelId: 'Aec-124-b45-aa1',
};

export const fetchGID = createAsyncThunk(
  'analytics/fetchGID',
  async (shopId) => {
    const route = `${shopfrontBaseUrl}/users/get-gid`;
    const { data, status } = await axios.get(route, {
      params: { shopId },
    });
    // initialize both jamlabsGID and datasellerGID here instead of App.js
    if (status === 200 && data !== '' && jamlabsGID !== '') {
      // if datasellerGID and jamlabsGID exist
      ReactGA.initialize([
        {
          trackingId: jamlabsGID,
          gaOptions: { label: 'jamlabs ID' },
        },
        {
          trackingId: data,
          gaOptions: { label: 'data seller ID' },
        },
      ]);
    } else if (jamlabsGID !== '') {
      // else if datasellerGID doesn't exist
      ReactGA.initialize(jamlabsGID);
    }
    return data;
  },
);

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchGID.pending]: (state) => {
      state.googleTrackingIdstatus = 'loading';
    },
    [fetchGID.rejected]: (state, action) => {
      state.googleTrackingIdstatus = 'failed';
      state.googleTrackingIderror = action.error.message;
    },
    [fetchGID.fulfilled]: (state, action) => {
      if (action.payload) {
        state.googleTrackingId = action.payload;
        state.googleTrackingIdstatus = 'succeeded';
      } else {
        state.googleTrackingIdstatus = 'failed';
        state.googleTrackingIderror = 'Error retrieving google analytics id';
      }
    },
  },
});

export default analyticsSlice.reducer;
