/* eslint-disable no-nested-ternary */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Card,
  Typography,
  Chip,
  CardHeader,
  CardContent,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { injectIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { unsubscribe } from 'pages/shopfront/LibraryItemDetailPage/subscriptionsSlice';
import { loadStripeKey } from 'config';
import { PaymentDialog } from './PaymentDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(0, 2, 2),
  },
  chip: {
    margin: theme.spacing(2, 1),
  },
  card: {
    padding: theme.spacing(2, 2, 0),
  },
  content: {
    padding: theme.spacing(0, 2, 0),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '80vh',
  },
  inputField: {
    width: '100%',
    marginBottom: '10px',
  },
}));

const stripePromise = loadStripe(loadStripeKey);

export const Subscription = injectIntl(({ license, subscription }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [unsubscribeDialog, setUnsubscribeDialog] = React.useState(false);
  const [paymentDialog, setPaymentDialog] = React.useState(false);

  const formattedDate = (epochUnix) => {
    const date = new Date(epochUnix);
    return date.toString().substring(0, 15);
  };

  const handleUnsubscribeCancel = () => {
    setUnsubscribeDialog(false);
  };

  const handlePaymentCancel = () => {
    setPaymentDialog(false);
  };

  const handleUnsubscribe = (subscriptionId) => {
    dispatch(unsubscribe(subscriptionId));
    setUnsubscribeDialog(false);
  };

  const UnsubscribeDialog = () => (
    <Dialog open={unsubscribeDialog} onClose={handleUnsubscribeCancel}>
      <DialogTitle>
        Are you sure you want to cancel your subscription?
      </DialogTitle>
      <DialogContent>
        Clicking <strong>Confirm</strong> will deactivate your subscription to{' '}
        <strong>{license?.name} </strong> and you will no longer recieve data
        deliveries for this subscription.
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleUnsubscribeCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleUnsubscribe(subscription.id)}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );

  const ViewContent = () => (
    <>
      <CardHeader
        className={classes.card}
        title={'#'.concat(subscription.id.substring(0, 5))}
        action={
          <>
            <Chip
              color="default"
              className={classes.chip}
              label={
                subscription.paid
                  ? subscription.expiresAt > Date.now()
                    ? 'Active'
                    : 'Inactive'
                  : 'Failed'
              }
            />
          </>
        }
      />
      <CardContent className={classes.content}>
        <Typography variant="subtitle1">
          <b>Last Updated:</b> {formattedDate(subscription.updatedAt)}
        </Typography>
        <Typography variant="subtitle1">
          <b>Last Billed:</b> {formattedDate(subscription.billingDate)}
        </Typography>
        <Typography variant="subtitle1">
          <b>Expiry Date:</b> {formattedDate(subscription.expiresAt)}
        </Typography>
        <Typography variant="subtitle1">
          <b>Associated License:</b> {license?.name}
        </Typography>
        <Typography variant="subtitle1">
          <b>License Description:</b> {license?.description}
        </Typography>
      </CardContent>
      <CardActions className={classes.actions}>
        {subscription.paid ? (
          subscription.active ? (
            <Button
              variant="outlined"
              onClick={() => setUnsubscribeDialog(true)}
            >
              Unsubscribe
            </Button>
          ) : subscription.expiresAt > Date.now() ? (
            <Button variant="outlined" disabled>
              Unsubscribed
            </Button>
          ) : (
            <Button variant="outlined" disabled>
              Expired
            </Button>
          )
        ) : (
          <Button variant="outlined" onClick={() => setPaymentDialog(true)}>
            Fix Payment
          </Button>
        )}
      </CardActions>
      <UnsubscribeDialog />
      <Elements stripe={stripePromise}>
        <PaymentDialog
          paymentDialog={paymentDialog}
          handlePaymentCancel={handlePaymentCancel}
          subscription={subscription}
          license={license}
        />
      </Elements>
    </>
  );

  return (
    <>
      <Card className={classes.root}>
        <ViewContent />
        {unsubscribeDialog && <UnsubscribeDialog />}
        {paymentDialog && (
          <Elements stripe={stripePromise}>
            <PaymentDialog
              paymentDialog={paymentDialog}
              handlePaymentCancel={handlePaymentCancel}
              subscription={subscription}
              license={license}
            />
          </Elements>
        )}
      </Card>
    </>
  );
});

/* eslint-enable no-nested-ternary */
