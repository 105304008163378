import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import {
  Chip,
  Grid,
  LinearProgress,
  Popover,
  TextField,
  Snackbar,
} from '@material-ui/core';

import { Autocomplete, Alert } from '@material-ui/lab';

import { Add as AddIcon } from '@material-ui/icons';
import { selectAllAttributes } from '../../pages/shopfront/FetchStoreInfo/attributesSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(1, 0),
    margin: 0,
    '& > *': {
      margin: theme.spacing(0, 1, 0, 0),
      color: 'red',
    },
  },
  chip: {
    margin: theme.spacing(0, 0, 1, 0),
  },
  loading: {
    width: '75%',
    margin: theme.spacing(1, 0, 1),
  },
}));

const Tags = ({ parent, editing, tagData, onAdd, onDelete }) => {
  const classes = useStyles();
  const editAttribute = parent != null;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [snackbar, setSnackbar] = React.useState(false);
  const data = editAttribute
    ? parent.tags.map((tag) => ({
        attribute: parent.id,
        tag: tag.key,
      }))
    : tagData;
  const attributes = useSelector(selectAllAttributes);
  const attributeStatus = useSelector((state) => state.attributes.status);
  const attributeError = useSelector((state) => state.attributes.error);

  useEffect(() => {
    if (attributeStatus === 'failed') {
      setSnackbar(true);
    }
  }, [attributeStatus]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddTag = (e, newTag) => {
    setAnchorEl(null);
    onAdd(newTag);
  };

  const handleSnackbarClose = () => {
    setSnackbar(false);
  };

  const SnackBarAlert = () => (
    <Snackbar
      open={snackbar}
      autoHideDuration={5000}
      onClose={handleSnackbarClose}
    >
      <Alert onClose={handleSnackbarClose} severity="error">
        {attributeError}
      </Alert>
    </Snackbar>
  );

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      handleAddTag(e, e.target.value);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  // TODO: move to ProductPage, big comp
  const makeOptions = (attrs) =>
    attrs
      .map((attribute) =>
        attribute.tags
          .filter((tag) => {
            const dataKeys = Object.keys(data);
            for (let i = 0; i < dataKeys.length; i += 1) {
              if (
                data[dataKeys[i]].attribute === attribute.id &&
                data[dataKeys[i]].tag === tag.key
              )
                return false;
            }

            return true;
          })
          .map((tag) => ({
            label: tag.label,
            key: `${attribute.id}_${tag.key}`,
            tagId: tag.key,
            attributeName: attribute.name,
            attributeId: attribute.id,
          })),
      )
      .flat();

  const options = makeOptions(attributes);

  const findTagLabel = (target) => {
    const matchingAttribute = attributes.find(
      (attribute) => attribute.id === target.attribute,
    );
    const matchingTag = editAttribute
      ? parent.tags.find((tag) => tag.key === target.tag)
      : matchingAttribute.tags.find((tag) => tag.key === target.tag);
    return matchingTag.label;
  };

  const ChipData = () => {
    if (attributeStatus === 'loading') {
      return (
        <Grid container direction="column" justify="center" alignItems="center">
          <LinearProgress className={classes.loading} />
        </Grid>
      );
    }
    if (attributeStatus === 'succeeded') {
      return data.map((d) => (
        <li key={`${d.tag}_${d.attribute}`}>
          {editing ? (
            <Chip
              label={findTagLabel(d)}
              className={classes.chip}
              onDelete={() => onDelete(d.tag)}
            />
          ) : (
            <Chip label={findTagLabel(d)} className={classes.chip} />
          )}
        </li>
      ));
    }
    return null;
  };

  return (
    <ul className={classes.root}>
      <ChipData />
      <li key="$$new_tag$$">
        {editing ? (
          <>
            <SnackBarAlert />
            <Chip
              className={classes.actionChip}
              label={<AddIcon />}
              onClick={handleClick}
            />
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              {editAttribute ? (
                <TextField
                  style={{ width: 200 }}
                  onKeyDown={keyPress}
                  label="Add Tag"
                  variant="outlined"
                />
              ) : (
                <Autocomplete
                  clearOnBlur
                  clearOnEscape
                  options={options.sort(
                    (a, b) => -b.attributeName.localeCompare(a.attributeName),
                  )}
                  groupBy={(option) => option.attributeName}
                  getOptionLabel={(option) => option.label}
                  getOptionSelected={(option) => option.label}
                  style={{ width: 200 }}
                  onChange={handleAddTag}
                  renderInput={(params) => (
                    <TextField {...params} label="Add Tag" variant="outlined" />
                  )}
                />
              )}
            </Popover>
          </>
        ) : null}
      </li>
    </ul>
  );
};

Tags.defaultProps = {
  parent: null,
  editing: false,
  tagData: [],
  onAdd: () => {},
  onDelete: () => {},
};

export default injectIntl(Tags);
