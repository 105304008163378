/* eslint-disable default-case, no-param-reassign */

import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { shopfrontBaseUrl } from 'config';
import axios from '../../../client/client';

const errorResponse = (state, action) => {
  state.status = 'failed';
  state.error = action.error.message;
};

const licensesAdapter = createEntityAdapter({
  selectId: (license) => license.id,
  sortComparer: null,
});

const initialState = licensesAdapter.getInitialState({
  status: 'idle',
  error: null,
  currency: 'usd',
  filter: {
    from: new Date('2021-01-01').toISOString().split('T')[0],
    to: new Date('2021-12-31').toISOString().split('T')[0],
    query: '',
    sortParam: 0,
  },
});

export const fetchLicenses = createAsyncThunk(
  'licenses/fetchLicenses',
  async ({ shopId }) => {
    const resp = await axios.get(`${shopfrontBaseUrl}/licenses`, {
      params: { shopId },
    });
    return { licenses: resp.data };
  },
);

export const fetchCurrency = createAsyncThunk(
  'licenses/fetchCurrency',
  async ({ shopId }) => {
    const resp = await axios.get(`${shopfrontBaseUrl}/licenses/currency`, {
      params: { shopId },
    });
    return resp.data;
  },
);

const licensesSlice = createSlice({
  name: 'licenses',
  initialState,
  reducers: {
    filterEdited(state, action) {
      const prop = Object.keys(action.payload)[0];
      const value = action.payload[prop];
      state.filter[prop] = value;
    },
  },
  extraReducers: {
    [fetchLicenses.pending]: (state) => {
      state.status = 'fetchLoading';
    },
    [fetchCurrency.pending]: () => {},
    [fetchLicenses.rejected]: (state, action) => errorResponse(state, action),
    [fetchCurrency.rejected]: (state, action) => errorResponse(state, action),

    [fetchLicenses.fulfilled]: (state, action) => {
      if (action.payload) {
        const { licenses } = action.payload;
        licensesAdapter.setAll(state, licenses);
        state.status = 'succeeded';
      } else {
        state.status = 'failed';
        state.error = 'Error retrieving licenses: received null value';
      }
    },
    [fetchCurrency.fulfilled]: (state, action) => {
      if (action.payload) {
        const { currency } = action.payload;
        state.currency = currency;
      } else {
        state.currency = 'usd';
      }
    },
  },
});
export const SelectLicenses = (state) => state.licenses.entities;

export const { filterEdited } = licensesSlice.actions;

export const {
  selectAll: selectAllLicenses,
  selectById: selectLicenseById,
  selectIds: selectLicenseIds,
} = licensesAdapter.getSelectors((state) => state.licenses);

export const selectFilter = (state) => state.licenses.filter;

export default licensesSlice.reducer;
