import React from 'react';
import {
  Typography,
  TableContainer,
  Paper,
  Box,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  LinearProgress,
} from '@material-ui/core';
import { HelpOutline as HelpOutlineIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
// import { Alert } from '@material-ui/lab';
import { selectAllOrderItems } from 'pages/OrdersPage/orderItemsSlice';
import { selectAllLicenses } from 'pages/shopfront/FetchStoreInfo/licensesSlice';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripeKey } from 'config';
import { selectAllOrders } from '../../OrdersPage/ordersSlice';
// import Backdrop from '@material-ui/core/Backdrop';
// import Fade from '@material-ui/core/Fade';
// import ReactGA from 'react-ga4';
import { selectCart } from '../CartPage/cartSlice';
import PaymentForm from './paymentForm';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiFormControlLabel-label': {
      color: theme.palette.text.primary,
    },
  },
  tableContainer: {
    marginTop: theme.spacing(2),
  },
  actionButton: {
    margin: theme.spacing(1),
  },
  agreement: {
    width: '100%',
    textAlign: 'left',
  },
  agreementForm: {
    textAlign: 'text-center',
  },
  shopPrimary: {
    margin: theme.spacing(1),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: '0',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
  },
  text: {
    color: theme.palette.text.primary,
  },
  paymentInfo: {
    width: '50%',
  },
  inputField: {
    marginBottom: '10px',
  },
}));

const columns = [
  { field: 'product', headerName: 'Product', width: 220 },
  { field: 'license', headerName: 'License', width: 140 },
  {
    field: 'frequencyOfUpdate',
    headerName: 'Aggregation Frequency',
    width: 240,
  },
  {
    field: 'parameters',
    headerName: 'Delivery Frequency',
    width: 240,
  },

  {
    field: 'unit',
    headerName: 'Unit',
    width: 100,
  },
  {
    field: 'billingFrequency',
    headerName: 'billingFrequency',
    width: 240,
  },
  {
    field: 'cost',
    headerName: 'Cost/Unit',
    width: 100,
  },
];

const stripePromise = loadStripe(loadStripeKey);

function ConfirmOrderPage() {
  const classes = useStyles();
  // const [open, setOpen] = React.useState(false);
  const cart = useSelector(selectCart);
  const currency = useSelector((state) => state.licenses.currency);

  const updateBackendStatus = useSelector(
    (state) => state.cart.updateBackendStatus,
  );
  const cartOrderId = useSelector((state) => state.customer.cart);
  const orders = useSelector(selectAllOrders);
  const currentOrder = orders.find((order) => order.id === cartOrderId);
  const associatedOrderItems = useSelector(selectAllOrderItems).filter(
    (orderItem) => orderItem.orderId === cartOrderId,
  );
  const associatedLicenses = useSelector(selectAllLicenses).filter(
    (license) =>
      associatedOrderItems.findIndex(
        (orderItem) => orderItem.licenseId === license.id,
      ) !== -1,
  );
  const productsObj = cart.reduce(
    (accumulator, product) => ({
      ...accumulator,
      [product.id]: { ...product, licenses: [] },
    }),
    {},
  );
  associatedLicenses.forEach((license) => {
    if (productsObj[license.productId])
      productsObj[license.productId].licenses.push(license);
  });

  return updateBackendStatus === 'loading' ? (
    <LinearProgress />
  ) : (
    <>
      <Typography className={classes.text} variant="h3">
        Confirm Your Order <HelpOutlineIcon fontSize="large" />
      </Typography>
      <Typography className={classes.text} variant="body1">
        Review your delivery preferences, and review the licensing terms.
        Afterwards, place your order and the data set will be made available
        after processing.
      </Typography>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.headerName}>
                  {column.headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(productsObj).map((row) =>
              row.licenses.map((license) => (
                <TableRow key={license.id}>
                  <TableCell align="left">{row.name}</TableCell>
                  <TableCell align="left">{license.name}</TableCell>
                  <TableCell align="left">Daily (filler)</TableCell>
                  <TableCell align="left">One-time (filler)</TableCell>
                  <TableCell align="left">Day of data (filler)</TableCell>
                  <TableCell align="left">{license.billingFrequency}</TableCell>
                  <TableCell align="left">
                    {license.price} {currency.toUpperCase()}
                  </TableCell>
                </TableRow>
              )),
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box my={2}>
        <Typography className={classes.text} variant="h6">
          Package Agreement
        </Typography>
      </Box>
      <TextField
        className={classes.agreement}
        id="standard-multiline-flexible"
        multiline
        rowsMax={16}
        value={Object.values(productsObj).reduce(
          (acc, product) =>
            `${
              acc +
              product.licenses.reduce(
                (accul, license) =>
                  `${accul}\tAgreement Body for ${license.name}:\n \t\t${license.agreementBody}\n`,
                `${product.name}:\n`,
              )
            }\n`,
          '',
        )}
      />
      <Elements stripe={stripePromise}>
        <PaymentForm
          classes={classes}
          cart={cart}
          cartOrderId={cartOrderId}
          currentOrder={currentOrder}
          associatedLicenses={associatedLicenses}
          currency
        />
      </Elements>
    </>
  );
}

export default ConfirmOrderPage;
