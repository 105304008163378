/* eslint-disable no-nested-ternary */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from 'auth/customer/customerAuth';
import { LinearProgress } from '@material-ui/core';

export default function PrivateRoute({ children, ...rest }) {
  const auth = useAuth();
  return (
    <Route
      {...rest}
      // eslint-disable-next-line arrow-body-style
      render={() => {
        return auth.loading ||
          (auth.user && auth.fetchStatus !== 'succeeded') ? (
          <LinearProgress />
        ) : auth.user ? (
          children
        ) : (
          <Redirect to="/users/sign-in" />
        );
      }}
    />
  );
}
