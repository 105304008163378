import React, { useEffect } from 'react';
import { Container, Box, Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, AlertTitle } from '@material-ui/lab';

import { selectAllProducts } from 'pages/shopfront/FetchStoreInfo/productsSlice';

import { selectOrderById } from 'pages/OrdersPage/ordersSlice';
import { selectAllOrderItems } from 'pages/OrdersPage/orderItemsSlice';
import { Link } from 'react-router-dom';
import {
  selectCustomer,
  selectShopId,
} from '../CustomerSignInPage/customerSignInSlice';
import { Product } from '../../../features/product/customerProduct';
import { fetchCurrency } from '../FetchStoreInfo/licensesSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  actionButton: {
    margin: theme.spacing(2, 1),
  },
  orderInfo: {
    margin: theme.spacing(2, 1),
  },
  text: {
    color: theme.palette.text.primary,
  },
}));

function OrderSuccessfulPage() {
  const classes = useStyles();
  const customer = useSelector(selectCustomer);
  const shopId = useSelector(selectShopId);
  const orderId = new URLSearchParams(window.location.search).get('id');
  const orderItems = useSelector(selectAllOrderItems).filter(
    (orderItem) => orderItem.orderId === orderId,
  );

  const order = useSelector((state) => selectOrderById(state, orderId));
  const products = useSelector(selectAllProducts).filter((product) =>
    order.productIds.includes(product.id),
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCurrency({ shopId }));
  });

  const ProductList = () =>
    products.map((entity) => (
      <Product
        key={entity.id}
        cart
        orderItems={orderItems}
        entity={entity}
        currency={order.currency}
      />
    ));
  const formattedDate = (epochUnix) => {
    const date = new Date(epochUnix);
    return date.toString().substring(0, 15);
  };

  return (
    <>
      <Container className={classes.root}>
        <Alert severity="success">
          <AlertTitle>ORDER CONFIRMATION</AlertTitle> <br></br>
          {customer && order ? (
            <Grid>
              <strong> Hi {customer.firstName}, </strong> <br></br> <br></br>
              Thank you for your order! <br></br>
              Your order containing {products.length} product(s) was placed on{' '}
              {formattedDate(order.createdAt)}. <br></br>
              We have emailed you with the details of your order. <br></br>{' '}
              <br></br>
              <strong> You can find your purchase information below. </strong>
            </Grid>
          ) : null}
        </Alert>
        <div className={classes.orderInfo}>
          {orderId ? (
            <Typography className={classes.text} variant="h3">
              Order Confirmation #{orderId.substring(0, 5)}
            </Typography>
          ) : null}

          <Grid container spacing={3}>
            {orderItems && order ? (
              <Grid item xs={8}>
                <ProductList />
              </Grid>
            ) : null}
            <Grid item xs>
              <Typography className={classes.text} variant="h6">
                Order Summary
              </Typography>
              {orderItems && order ? (
                <Grid item xs>
                  {customer ? (
                    <Grid>
                      <Typography className={classes.text} variant="body1">
                        {products.length} products
                      </Typography>
                      <Typography className={classes.text} variant="body1">
                        Client: {customer.firstName} {customer.lastName}
                      </Typography>
                      <Typography className={classes.text} variant="body1">
                        Company: {customer.companyName}
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid>
                      <Typography className={classes.text} variant="body1">
                        {products.length} products
                      </Typography>
                    </Grid>
                  )}

                  <Typography className={classes.text} variant="body1">
                    Placed: {formattedDate(order.createdAt)}
                  </Typography>
                  <Box>
                    {customer ? (
                      <Button
                        className={classes.actionButton}
                        color="primary"
                        variant="contained"
                        component={Link}
                        to="/explore"
                      >
                        Explore Data Sets
                      </Button>
                    ) : null}

                    <Button
                      className={classes.actionButton}
                      color="default"
                      variant="contained"
                      component={Link}
                      to="/order-history"
                    >
                      View Orders
                    </Button>
                  </Box>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </div>
      </Container>
    </>
  );
}

export default OrderSuccessfulPage;
