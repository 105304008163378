import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { DataGrid } from '@material-ui/data-grid';
import { useHistory } from 'react-router-dom';
import { fetchInvoices, selectInvoices } from './paymentsSlice';
import { selectCustomer } from '../CustomerSignInPage/customerSignInSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  table: {
    height: 525,
    width: '100%',
  },
  description: {
    padding: theme.spacing(2),
    color: theme.palette.text.primary,
  },
  text: {
    color: theme.palette.text.primary,
  },
}));

function InvoicesPage() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const customer = useSelector(selectCustomer);
  const invoiceStatus = useSelector((state) => state.invoices.status);
  const invoices = useSelector(selectInvoices);
  const history = useHistory();

  useEffect(() => {
    if (invoiceStatus === 'idle') {
      dispatch(
        fetchInvoices({
          customerId: customer.id,
        }),
      );
    }
  }, [invoiceStatus, dispatch, customer]);

  const columns = [
    {
      field: 'id',
      headerName: 'Order Number',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'dateIssued',
      headerName: 'Date Issued',
      type: 'date',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'price',
      headerName: 'Invoice Total',
      type: 'number',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
  ];

  return (
    <>
      <Typography className={classes.text} variant="h3">
        Invoices
      </Typography>
      <Typography className={classes.description} variant="body2">
        Review all of the invoices you have been issued.
      </Typography>
      {invoices != null ? (
        <div className={classes.table}>
          <DataGrid
            rows={invoices}
            columns={columns}
            pageSize={8}
            onRowClick={(rowData) =>
              history.push(`/invoice?invoiceId=${rowData.row.orderId}`)
            }
          />
        </div>
      ) : (
        <Typography className={classes.description} variant="body2">
          No Invoices were found.
        </Typography>
      )}
    </>
  );
}

export default InvoicesPage;
