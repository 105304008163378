import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Grid,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
  CircularProgress,
  LinearProgress,
  InputAdornment,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Typography,
  Snackbar,
} from '@material-ui/core';
import {
  Search as SearchIcon,
  HelpOutline as HelpOutlineIcon,
} from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { fetchOrdersByCustomer } from 'pages/OrdersPage/ordersSlice';
import { fetchOrderItems } from 'pages/OrdersPage/orderItemsSlice';
import { selectShopId } from 'pages/shopfront/CustomerSignInPage/customerSignInSlice';
import ReactGA from 'react-ga4';
import { LibraryItem } from '../../../features/libraryItem/libraryItem';

import {
  selectAllProducts,
  fetchProducts,
} from '../FetchStoreInfo/productsSlice';

import {
  selectSelectedTags,
  toggleTag,
  fetchLibraryItems,
  selectAllLibraryItems,
} from './libraryItemsSlice';

import { selectCustomer } from '../CustomerSignInPage/customerSignInSlice';

import {
  selectAllAttributes,
  fetchAttributes,
} from '../FetchStoreInfo/attributesSlice';

import { fetchLicenses, fetchCurrency } from '../FetchStoreInfo/licensesSlice';

import { fetchFileDeliveries } from '../LibraryItemDetailPage/fileDeliveriesSlice';
import { fetchSubscriptions } from '../LibraryItemDetailPage/subscriptionsSlice';

const useStyles = makeStyles((theme) => ({
  status: {
    flexGrow: 1,
  },
  title: {
    color: theme.palette.text.primary,
    padding: theme.spacing(2, 0),
  },
  form: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },

  root: {
    flexGrow: 1,
    '& .MuiFormControlLabel-label': {
      color: theme.palette.text.primary,
    },
  },
  checkbox: {
    padding: theme.spacing(0.5, 1),
  },
  loading: {
    width: '50%',
    margin: theme.spacing(10, 0, 10),
  },
  shopPrimary: {
    backgroundColor: '#cf4520',
    color: '#fff',
  },
  search: {
    margin: theme.spacing(0, 0, 1),
    padding: theme.spacing(0, 1, 0, 0),
  },
  card: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2, 2, 2),
  },
  text: {
    color: theme.palette.text.primary,
  },
}));

function LibraryPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedTags = useSelector(selectSelectedTags);
  const attributes = useSelector(selectAllAttributes);
  const [searchField, setSearchField] = React.useState('');
  const [sortParam, setSortParam] = React.useState(0);
  const [fileDelivery, setFileDelivery] = React.useState(true);
  const [restApi, setRestApi] = React.useState(true);
  const [s3Share, sets3Share] = React.useState(true);
  const [sftp, setSftp] = React.useState(true);
  const [errorSnackbar, setErrorSnackbar] = React.useState(false);
  const [errorSnackbarMessage, setErrorSnackbarMessage] = React.useState('');

  const customer = useSelector(selectCustomer);
  const shopId = useSelector(selectShopId);
  const currency = useSelector((state) => state.licenses.currency);

  const isPublished = (product) => product.status === 'Published';
  const isTagSelected = (product) => {
    // if no tags selected
    if (Object.keys(selectedTags).length === 0) {
      return true;
    }
    // if products has no tags
    if (!product || !product.tags || product.tags.length === 0) {
      return false;
    }

    // filter the tags
    const filterTags = (tag) => (selectedTags[tag.attribute] || {})[tag.tag];

    return product.tags.filter(filterTags).length > 0;
  };

  const attributeStatus = useSelector((state) => state.attributes.status);
  const attributeError = useSelector((state) => state.attributes.error);

  const productsStatus = useSelector((state) => state.products.status);
  const productsError = useSelector((state) => state.products.error);

  const licenseStatus = useSelector((state) => state.licenses.status);
  const orderStatus = useSelector((state) => state.orders.status);
  const orderItemStatus = useSelector((state) => state.orderItems.status);
  const libraryItemStatus = useSelector((state) => state.libraryItems.status);
  const fileDeliveryStatus = useSelector(
    (state) => state.fileDeliveries.status,
  );
  const subscriptionStatus = useSelector((state) => state.subscriptions.status);

  // const libraryItemError = useSelector((state) => state.libraryItems.error);

  const GAevent = (category, action, label) => {
    ReactGA.event({ category, action, label });
  };

  useEffect(() => {
    if (!shopId) return;
    dispatch(fetchCurrency({ shopId }));
    if (productsStatus === 'idle') dispatch(fetchProducts({ shopId }));
    if (attributeStatus === 'idle') dispatch(fetchAttributes({ shopId }));
    if (licenseStatus === 'idle') dispatch(fetchLicenses({ shopId }));
    if (!customer) return;
    if (fileDeliveryStatus === 'idle')
      dispatch(fetchFileDeliveries(customer.id));
    if (subscriptionStatus === 'idle')
      dispatch(fetchSubscriptions(customer.id));
    if (libraryItemStatus === 'idle') dispatch(fetchLibraryItems(customer.id));
    if (orderStatus === 'idle') dispatch(fetchOrdersByCustomer(customer.id));
    if (orderItemStatus === 'idle') dispatch(fetchOrderItems(customer.id));
    if (productsStatus === 'failed') {
      setErrorSnackbar(true);
      setErrorSnackbarMessage(productsError);
    }
    if (attributeStatus === 'failed') {
      setErrorSnackbar(true);
      setErrorSnackbarMessage(attributeError);
    }
  }, [
    productsStatus,
    licenseStatus,
    attributeStatus,
    orderStatus,
    orderItemStatus,
    libraryItemStatus,
    subscriptionStatus,
    fileDeliveryStatus,
    dispatch,
    shopId,
    customer,
    productsError,
    attributeError,
  ]);

  const allProducts = useSelector(selectAllProducts);
  const libraryItems = useSelector(selectAllLibraryItems);
  const productIds = libraryItems.map((libraryItem) => libraryItem.productId);

  const products = allProducts
    .filter((product) => productIds.includes(product.id))
    .filter(isPublished)
    .filter(isTagSelected)
    .filter((product) => {
      // query product name, description, or tags
      const tagData = product.tags.map((tag) => {
        const parentAttribute = attributes.find(
          (attribute) => attribute.id === tag.attribute,
        );
        return parentAttribute
          ? parentAttribute.tags.find((x) => x.key === tag.tag).label
          : '';
      });
      return searchField.length > 0
        ? product.name.toLowerCase().includes(searchField.toLowerCase()) ||
            product.description
              .toLowerCase()
              .includes(searchField.toLowerCase()) ||
            tagData.findIndex((x) =>
              x.toLowerCase().includes(searchField.toLowerCase()),
            ) !== -1
        : true;
    })
    .sort((a, b) => {
      if (sortParam === 0) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }
      if (sortParam === 1) {
        if (a.name < b.name) return 1;
        if (a.name > b.name) return -1;
        return 0;
      }
      return 0;
    });

  if (!shopId) return <Redirect to="/users/sign-in" />;

  const handleToggleTag = (attribute, tag) => () => {
    dispatch(toggleTag({ attribute: attribute.id, tag: tag.key }));
  };

  const handleErrorSnackbarClose = () => {
    setErrorSnackbar(false);
  };

  const ErrorSnackbarAlert = () => (
    <Snackbar
      open={errorSnackbar}
      autoHideDuration={5000}
      onClose={handleErrorSnackbarClose}
    >
      <Alert onClose={handleErrorSnackbarClose} severity="error">
        {errorSnackbarMessage}
      </Alert>
    </Snackbar>
  );

  const ProductList = () => {
    if (productsStatus === 'loading') {
      return (
        <Grid container direction="column" justify="center" alignItems="center">
          <LinearProgress className={classes.loading} />
        </Grid>
      );
    }
    if (productsStatus === 'succeeded') {
      return (
        <div>
          {products.map((product) => (
            <LibraryItem
              key={product.id}
              entity={product}
              libraryItem={libraryItems.find(
                (libraryItem) => libraryItem.productId === product.id,
              )}
              restApi={restApi}
              fileDelivery={fileDelivery}
              s3Share={s3Share}
              sftp={sftp}
              customerId={customer.id}
              currency={currency}
            />
          ))}
        </div>
      );
    }
    return null;
  };

  const AttributeCheckBoxes = ({ attribute }) => (
    <Box my={2} test-attribute={`${attribute.id}-box`}>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend">{attribute.name}</FormLabel>
        {attribute.tags.map((tag) => {
          const checked =
            selectedTags[attribute.id] && selectedTags[attribute.id][tag.key];
          return (
            <FormGroup key={tag.key}>
              <FormControlLabel
                className={classes.root}
                control={
                  <Checkbox
                    className={classes.checkbox}
                    name={`${attribute.id}_${tag.key}`}
                    test-attribute={`${attribute.id}_${tag.key}-checkbox`}
                    checked={checked}
                    onChange={handleToggleTag(attribute, tag)}
                  />
                }
                label={tag.label}
              />
            </FormGroup>
          );
        })}
      </FormControl>
    </Box>
  );

  const AttributesList = () => {
    let content;
    if (attributeStatus === 'idle') {
      content = (
        <Grid container direction="column" justify="center" alignItems="center">
          <CircularProgress />
        </Grid>
      );
    } else if (attributeStatus === 'succeeded') {
      content = (
        <div>
          {attributes.map((attribute) => (
            <AttributeCheckBoxes key={attribute.id} attribute={attribute} />
          ))}
        </div>
      );
    } else {
      content = null;
    }
    return content;
  };

  const SortBySelect = () => (
    <FormControl fullWidth>
      <InputLabel id="sort-by-select-label">Sort By:</InputLabel>
      <Select
        test-attribute="sort-by-select"
        labelId="sort-by-select-label"
        value={sortParam}
        onChange={(e) => setSortParam(e.target.value)}
      >
        <MenuItem value={0}>Product Name (A-Z)</MenuItem>
        <MenuItem value={1}>Product Name (Z-A)</MenuItem>
      </Select>
    </FormControl>
  );

  const DeliveryOptionList = () => (
    <Box my={2}>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend">Delivery Options</FormLabel>
        <FormGroup>
          <FormControlLabel
            className={classes.root}
            control={
              <Checkbox
                name="fileDelivery"
                className={classes.checkbox}
                checked={fileDelivery}
                onChange={(e) => {
                  setFileDelivery(e.target.checked);

                  if (e.target.checked)
                    GAevent(
                      'engagement',
                      'library_filter_by_file_delivery',
                      'Library change filter',
                    );
                  else
                    GAevent(
                      'engagement',
                      'library_unfilter_by_file_delivery',
                      'Library change filter',
                    );
                }}
              />
            }
            label="File Delivery"
          />
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            className={classes.root}
            control={
              <Checkbox
                name="restApi"
                className={classes.checkbox}
                checked={restApi}
                onChange={(e) => {
                  setRestApi(e.target.checked);

                  if (e.target.checked)
                    GAevent(
                      'engagement',
                      'library_filter_by_rest_api',
                      'Library change filter',
                    );
                  else
                    GAevent(
                      'engagement',
                      'library_unfilter_by_rest_api',
                      'Library change filter',
                    );
                }}
              />
            }
            label="Rest API"
          />
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            className={classes.root}
            control={
              <Checkbox
                name="s3Share"
                className={classes.checkbox}
                checked={s3Share}
                onChange={(e) => {
                  sets3Share(e.target.checked);

                  if (e.target.checked)
                    GAevent(
                      'engagement',
                      'library_filter_by_S3_share',
                      'Library change filter',
                    );
                  else
                    GAevent(
                      'engagement',
                      'library_unfilter_by_S3_share',
                      'Library change filter',
                    );
                }}
              />
            }
            label="S3 Share"
          />
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            className={classes.root}
            control={
              <Checkbox
                name="sftp"
                className={classes.checkbox}
                checked={sftp}
                onChange={(e) => {
                  setSftp(e.target.checked);

                  if (e.target.checked)
                    GAevent(
                      'engagement',
                      'library_filter_by_SFTP',
                      'Library change filter',
                    );
                  else
                    GAevent(
                      'engagement',
                      'library_unfilter_by_SFTP',
                      'Library change filter',
                    );
                }}
              />
            }
            label="SFTP"
          />
        </FormGroup>
      </FormControl>
    </Box>
  );

  return (
    <>
      <ErrorSnackbarAlert />
      <Grid container>
        <Grid item xs={3}>
          <AttributesList />
          <DeliveryOptionList />
        </Grid>
        <Grid item xs>
          <Typography className={classes.title} variant="h3">
            Your Library <HelpOutlineIcon fontSize="large" />
          </Typography>
          <Typography className={classes.text} variant="body1">
            Review your product library, download data sets, and update
            licences.
          </Typography>
          <Grid item container>
            <Grid item xs>
              <TextField
                label="Search"
                test-attribute="search"
                value={searchField}
                fullWidth
                className={classes.search}
                onChange={(e) => setSearchField(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <SortBySelect />
            </Grid>
          </Grid>
          <Grid container item justify="space-between">
            <Grid
              className={classes.text}
              item
              test-attribute="products-matching-criteria-text"
            >
              {products.length} of {libraryItems.length} total products for
              selected criteria
            </Grid>
          </Grid>
          <ProductList />
        </Grid>
      </Grid>
    </>
  );
}

export default LibraryPage;
