import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Button, Typography } from '@material-ui/core';

import { HelpOutline as HelpOutlineIcon } from '@material-ui/icons';

import { makeStyles } from '@material-ui/core/styles';
import { deleteOrderItem } from 'pages/OrdersPage/orderItemsSlice';
import { syncOrderToCart } from 'pages/OrdersPage/ordersSlice';
import ReactGA from 'react-ga4';
import { Product } from '../../../features/product/customerProduct';
import {
  selectCart,
  removeProductFromCart,
  fetchCartOrderItems,
  selectCartOrderItems,
  addItemToCart,
  removeItemFromCart,
  updateItemInCart,
  syncDraftToBackend,
} from './cartSlice';
import { selectCustomer } from '../CustomerSignInPage/customerSignInSlice';
import { fetchCurrency } from '../FetchStoreInfo/licensesSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  actionButton: {
    margin: theme.spacing(2, 1),
  },
  shopPrimary: () => ({
    backgroundColor: '#cf4520',
    color: '#fff',
    margin: theme.spacing(2, 1),
  }),
  text: {
    color: theme.palette.text.primary,
  },
}));

function CartPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const cartOrderItemsStatus = useSelector(
    (state) => state.cart.orderItemsStatus,
  );
  const products = useSelector(selectCart);
  const customer = useSelector(selectCustomer) || {};
  const unmounted = React.useRef(false);
  useEffect(() => {
    dispatch(fetchCurrency);
    if (cartOrderItemsStatus === 'idle')
      dispatch(fetchCartOrderItems({ cartId: customer.cart }));
    return () => {
      dispatch(syncDraftToBackend());
      unmounted.current = true;
    };
  }, [dispatch, customer.cart, cartOrderItemsStatus]);
  const setUnloadWindow = (windowState) => {
    if (windowState && window.onbeforeunload === null) {
      window.onbeforeunload = () => true;
    } else if (!windowState && window.onbeforeunload !== null) {
      window.onbeforeunload = null;
    }
  };
  useEffect(
    () => () => {
      window.onbeforeunload = null;
    },
    [],
  );
  const orderItems = useSelector(selectCartOrderItems).filter(
    (curItem) => !curItem.deleted,
  );
  const currency = useSelector((state) => state.licenses.currency);
  const history = useHistory();

  const GAevent = (category, action, label) => {
    ReactGA.event({ category, action, label });
  };

  const priceReducer = (accumulator, currentPrice) =>
    parseFloat(accumulator) + parseFloat(currentPrice);

  const ProductList = () =>
    products.map((entity) => (
      <Product
        key={entity.id}
        cart
        pendingCart
        orderItems={orderItems}
        entity={entity}
        addLicenseToCart={addLicenseToCart}
        removeLicenseFromCart={removeLicenseFromCart}
        onRemoveFromCart={() => handleRemoveFromCart(entity.id)}
        onChangeDelivery={handleChangeDelivery}
        updateFilterDraft={updateFilterDraft}
        currency={currency}
      />
    ));

  const onConfirmOrder = () => {
    GAevent('engagement', `add_to_cart`, {
      currency: 'CAD',
      items: products.map((product) => ({
        item_id: product.id,
        item_name: product.name,
      })),
    });

    history.push('/confirm-order');
  };

  const handleRemoveFromCart = (productId) => {
    dispatch(removeProductFromCart({ cartOrderId: customer.cart, productId }));
    dispatch(
      syncOrderToCart({
        orderId: customer.cart,
        productIdToRemove: productId,
        cart: products,
      }),
    );
  };

  const addLicenseToCart = (licenseId, licenseDelivery, licensePrice) => {
    const exists =
      orderItems.findIndex(
        (curOrderItem) => curOrderItem.licenseId === licenseId,
      ) !== -1;
    if (!exists) {
      dispatch(
        addItemToCart({ customer, licenseId, licenseDelivery, licensePrice }),
      );
      setUnloadWindow(true);
    }
  };

  const removeLicenseFromCart = (licenseId, hardRemove) => {
    const orderItemId = orderItems.find(
      (curOrderItem) => curOrderItem.licenseId === licenseId,
    )?.id;
    if (orderItemId) {
      if (hardRemove) dispatch(deleteOrderItem(orderItemId));
      else setUnloadWindow(true);
      dispatch(removeItemFromCart(orderItemId));
    }
  };

  const handleChangeDelivery = (deliveryMethod, orderItem) => {
    dispatch(updateItemInCart({ id: orderItem.id, deliveryMethod }));
    setUnloadWindow(true);
  };

  const handleUpdateFilter = (orderItemId, changes) => {
    const filters =
      orderItems.find((curItem) => curItem.id === orderItemId)?.filters || [];
    const updatedChanges = changes
      .filter(
        (curChange) =>
          (Object.prototype.hasOwnProperty.call(curChange, 'string') &&
            curChange.string !== '') ||
          (Object.prototype.hasOwnProperty.call(curChange, 'from') &&
            curChange.from !== '') ||
          (Object.prototype.hasOwnProperty.call(curChange, 'to') &&
            curChange.to !== ''),
      )
      .map((curChange) => {
        const newChange = { ...curChange };
        if (
          Object.prototype.hasOwnProperty.call(newChange, 'from') &&
          newChange.from === ''
        ) {
          delete newChange.from;
        }
        if (
          Object.prototype.hasOwnProperty.call(newChange, 'to') &&
          newChange.to === ''
        ) {
          delete newChange.to;
        }
        return newChange;
      });
    if (filters.length === 0) {
      dispatch(
        updateItemInCart({
          id: orderItemId,
          filters: updatedChanges,
        }),
      );
    } else {
      const updatedChangesNames = changes.map((curChange) => curChange.name);
      const newFilters = filters
        .filter((curFilter) => !updatedChangesNames.includes(curFilter.name))
        .concat(updatedChanges);
      dispatch(
        updateItemInCart({
          id: orderItemId,
          filters: newFilters,
        }),
      );
    }
  };

  const updateFilterDraft = (filterDraft, updatedFilters) => {
    Object.entries(updatedFilters).forEach(([orderItemId, value]) => {
      const changes = [];
      Object.keys(value).forEach((curFilterName) => {
        changes.push({
          name: curFilterName,
          ...filterDraft[orderItemId][curFilterName],
        });
      });
      handleUpdateFilter(orderItemId, changes);
    });
    if (Object.keys(updatedFilters).length > 0 && unmounted.current) {
      dispatch(syncDraftToBackend());
    }
  };

  const getCartTotal = () => {
    let cartTotal = 0;
    const prices = orderItems.map((item) => item.price);
    if (prices.length > 0) {
      cartTotal = parseFloat(prices.reduce(priceReducer));
    }
    return cartTotal.toFixed(2);
  };

  return (
    <>
      <Typography className={classes.text} variant="h3">
        Your Cart <HelpOutlineIcon fontSize="large" />
      </Typography>
      <Typography className={classes.text} variant="body1">
        Check over your cart, add filter specifications, delivery preferences,
        and place an order.
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <ProductList />
        </Grid>
        <Grid item xs>
          <Typography className={classes.text} variant="h6">
            Cart Total: ${getCartTotal()}
          </Typography>
          <Typography className={classes.text} variant="body2">
            {products.length} products
          </Typography>
          <Typography className={classes.text} variant="body2">
            {orderItems.length} items selected
          </Typography>
          <Box>
            <Button
              className={classes.actionButton}
              color="default"
              variant="contained"
              component={Link}
              to="/explore"
            >
              Add Data Sets
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={onConfirmOrder}
            >
              Checkout
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default CartPage;
